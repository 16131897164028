import { createSlice } from '@reduxjs/toolkit'
import { gameClose, userLogin } from 'redux-store/thunk/auth.thunk'
import { fetchUserInformation } from 'redux-store/thunk/user.thunk'
import { getPayloadFromToken } from 'helpers/common.helpers'

const defaultAuthState = {
  debitWalletWaitQueue: [],
  creditWalletWaitQueue: [],
  creditDemoWalletWaitQueue: [],
  userInfo: null,
  userDetails: null,
  currencyId: null,
  currencyCode: null,
  isLoggedIn: false,
  showName: false,
  demoMode: false,
  demoWallet: { balance: 3000, currency: 'EUR' }
}

const authSlice = createSlice({
  name: 'authSlice',
  initialState: defaultAuthState,
  reducers: {
    appendDebitWalletWaitQueue: (state, action) => {
      return {
        ...state,
        debitWalletWaitQueue: [action.payload, ...state.debitWalletWaitQueue]
      }
    },
    updateDebitWalletWaitQueue: (state, action) => {
      if (state.demoMode) {
        return { ...state }
      }
      let _debitWalletWaitQueue = [...state.debitWalletWaitQueue]
      const _userInfoWallet = _debitWalletWaitQueue[_debitWalletWaitQueue.length - 1] ?? state.userInfo.wallet
      _debitWalletWaitQueue = _debitWalletWaitQueue?.slice(0, _debitWalletWaitQueue.length - 1)
      return {
        ...state,
        debitWalletWaitQueue: _debitWalletWaitQueue,
        userInfo: {
          ...state.userInfo,
          wallet: _userInfoWallet
        }
      }
    },
    appendCreditWalletWaitQueue: (state, action) => {
      return {
        ...state,
        creditWalletWaitQueue: [action.payload, ...state.creditWalletWaitQueue]
      }
    },
    updateCreditWalletWaitQueue: (state, action) => {
      if (state.demoMode) {
        return { ...state }
      }
      let _creditWalletWaitQueue = [...state.creditWalletWaitQueue]
      const _userInfoWallet = _creditWalletWaitQueue[_creditWalletWaitQueue.length - 1] ?? state.userInfo.wallet
      _creditWalletWaitQueue = _creditWalletWaitQueue?.slice(0, _creditWalletWaitQueue.length - 1)
      return {
        ...state,
        creditWalletWaitQueue: _creditWalletWaitQueue,
        userInfo: {
          ...state.userInfo,
          wallet: _userInfoWallet
        }
      }
    },
    appendCreditDemoWalletWaitQueue: (state, action) => {
      return {
        ...state,
        creditDemoWalletWaitQueue: [action.payload, ...state.creditDemoWalletWaitQueue]
      }
    },
    updateCreditDemoWalletWaitQueue: (state, action) => {
      let _creditDemoWalletWaitQueue = [...state.creditDemoWalletWaitQueue]
      const _newDemoWallet = _creditDemoWalletWaitQueue.pop() ?? state.demoWallet
      _creditDemoWalletWaitQueue = _creditDemoWalletWaitQueue?.slice(0, _creditDemoWalletWaitQueue.length - 1)
      return {
        ...state,
        creditDemoWalletWaitQueue: _creditDemoWalletWaitQueue,
        demoWallet: _newDemoWallet
      }
    },
    setShowName: (state, action) => {
      return {
        ...state,
        showName: !state.showName
      }
    },
    setUserInfo: (state, action) => ({
      ...state,
      userInfo: {
        ...state.userInfo,
        wallet: action.payload
      }
    }),
    setDemoMode: (state, action) => {
      return {
        ...state,
        demoMode: action.payload,
        currencyCode: state.demoWallet.currency
      }
    },
    setDemoWalletCurrency: (state, action) => {
      return {
        ...state,
        currencyCode: action.payload,
        demoWallet: { ...state.demoWallet, currency: action.payload }
      }
    },
    updateDemoWalletBalance: (state, action) => {
      return {
        ...state,
        demoWallet: { ...state.demoWallet, balance: action.payload }
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(userLogin.fulfilled, (state, action) => {
        const { user, wallet, accessToken, operator } = action.payload
        const payload = getPayloadFromToken(accessToken)
        const currencyId = payload?.currencyId
        const currencyCode = payload?.currencyCode
        const operatorId = operator?.id
        return {
          ...state,
          currencyId,
          operatorId,
          currencyCode,
          userInfo: { user, wallet },
          isLoggedIn: true
        }
      })
      .addCase(fetchUserInformation.fulfilled, (state, action) => {
        return {
          ...state,
          userDetails: action.payload
        }
      })
      .addCase(gameClose.fulfilled, (state, action) => {
        return defaultAuthState
      })
  }
})

export const {
  setUserInfo, appendDebitWalletWaitQueue,
  updateDebitWalletWaitQueue, appendCreditWalletWaitQueue,
  updateCreditWalletWaitQueue, setShowName,
  setDemoMode, updateDemoWalletBalance, setDemoWalletCurrency,
  appendCreditDemoWalletWaitQueue, updateCreditDemoWalletWaitQueue
} = authSlice.actions

export default authSlice.reducer
