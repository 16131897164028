
export const LANGUAGES = [
  { label: 'English', languageCode: 'en' },
  { label: 'German', languageCode: 'de' },
  { label: 'Spanish', languageCode: 'es' },
  { label: 'French', languageCode: 'fr' },
  { label: 'Portuguese', languageCode: 'pt' },
  { label: 'Russian', languageCode: 'ru' }
]

/* ==========================================================================
  HTTP Method Types
========================================================================== */
export const METHOD_TYPES = {
  get: 'get',
  post: 'post',
  put: 'put',
  delete: 'delete',
  patch: 'patch'
}

/* ==========================================================================
LocalStorage / Cookie data
========================================================================== */
export const TOKEN = 'bad_hombre_auth_token'
export const LANGUAGE = 'bad_hombre_language'
export const OPERATOR_ID = 'bad_hombre_operator_id'
export const ROLE = 'bad_hombre_operator_role'

/* ==========================================================================
  All the navigation route Paths
========================================================================== */
export const ROUTE_PATHS = {
  homepage: '/',
  crashGame: '/crash-game',
  plinkoGame: '/plinko-game',
  diceGame: '/dice-game',
  mineGame: '/mine-game',
  hiloGame: '/hilo-game',
  cryptoGame: '/crypto-game',
  sessionExpired: '/session-expired',
  error: '/error'
}

/* ==========================================================================
Loader types
========================================================================== */
export const LOADER = {
  BUTTON: 'button',
  CONTENT: 'content',
  TABLE_CELL: 'tableCell'
}

/* ==========================================================================
  Loader Types
========================================================================== */
export const LOADER_HANDLER_TYPES = {
  page: 'pageLoader',
  submit: 'submitButtonLoader',
  table: 'tableLoader'
}

/* ==========================================================================
  Game Ids
========================================================================== */
export const DEFAULT_GAME_ID = {
  CRASH: 1,
  PLINKO: 2,
  DICE: 3,
  MINE: 4,
  HILO: 5,
  CRYPTO: 6,
  ROLLER: 7
}

export const GAME_NAME_ID_MAPPING = {
  '/crash-game': DEFAULT_GAME_ID.CRASH
}

/* ==========================================================================
  Game Result
========================================================================== */
export const BET_RESULT = {
  WON: 'won',
  LOST: 'lost',
  CANCELLED: 'cancelled',
  PENDING: 'pending'
}

/* ==========================================================================
  Currency Symbols
========================================================================== */
export const CURRENCY_SYMBOL = {
  USD: '$',
  EUR: '€',
  GBP: '£',
  INR: '₹',
  AED: 'إ.د',
  VND: '₫',
  IDR: 'Rp',
  PHP: '₱',
  JPY: '¥',
  KRW: '₩',
  RUB: '₽',
  MYR: 'RM',
  THB: '฿',
  BRL: 'R$',
  CNY: '¥',
  CAD: '$',
  MXN: '$',
  NGN: '₦',
  TRY: '₺',
  NZD: '$',
  AUD: '$',
  ARS: '$',
  ILS: '₪',
  UAH: '₴',
  PLN: 'zł'
}

/* ==========================================================================
  Miscellaneous Constants
========================================================================== */
export const DEFAULT_GAME_SOUND = false
export const DEFAULT_GAME_MUSIC = false

/* ==========================================================================
  HiLo Card Deck
========================================================================== */
export const HI_LO_CARD_DECK = {
  1: [1, 'spade', 'Ace of spade', 'A-1'],
  2: [2, 'spade', '2 of spade', '2-1'],
  3: [3, 'spade', '3 of spade', '3-1'],
  4: [4, 'spade', '4 of spade', '4-1'],
  5: [5, 'spade', '5 of spade', '5-1'],
  6: [6, 'spade', '6 of spade', '6-1'],
  7: [7, 'spade', '7 of spade', '7-1'],
  8: [8, 'spade', '8 of spade', '8-1'],
  9: [9, 'spade', '9 of spade', '9-1'],
  10: [10, 'spade', '10 of spade', '10-1'],
  11: [11, 'spade', 'Jack of spade', 'J-1'],
  12: [12, 'spade', 'Queen of spade', 'Q-1'],
  13: [13, 'spade', 'King of spade', 'K-1'],
  14: [1, 'club', 'Ace of club', 'A-3'],
  15: [2, 'club', '2 of club', '2-3'],
  16: [3, 'club', '3 of club', '3-3'],
  17: [4, 'club', '4 of club', '4-3'],
  18: [5, 'club', '5 of club', '5-3'],
  19: [6, 'club', '6 of club', '6-3'],
  20: [7, 'club', '7 of club', '7-3'],
  21: [8, 'club', '8 of club', '8-3'],
  22: [9, 'club', '9 of club', '9-3'],
  23: [10, 'club', '10 of club', '10-3'],
  24: [11, 'club', 'Jack of club', 'J-3'],
  25: [12, 'club', 'Queen of club', 'Q-3'],
  26: [13, 'club', 'King of club', 'K-3'],
  27: [1, 'heart', 'Ace of heart', 'A-4'],
  28: [2, 'heart', '2 of heart', '2-4'],
  29: [3, 'heart', '3 of heart', '3-4'],
  30: [4, 'heart', '4 of heart', '4-4'],
  31: [5, 'heart', '5 of heart', '5-4'],
  32: [6, 'heart', '6 of heart', '6-4'],
  33: [7, 'heart', '7 of heart', '7-4'],
  34: [8, 'heart', '8 of heart', '8-4'],
  35: [9, 'heart', '9 of heart', '9-4'],
  36: [10, 'heart', '10 of heart', '10-4'],
  37: [11, 'heart', 'Jack of heart', 'J-4'],
  38: [12, 'heart', 'Queen of heart', 'Q-4'],
  39: [13, 'heart', 'King of heart', 'K-4'],
  40: [1, 'diamond', 'Ace of diamond', 'A-2'],
  41: [2, 'diamond', '2 of diamond', '2-2'],
  42: [3, 'diamond', '3 of diamond', '3-2'],
  43: [4, 'diamond', '4 of diamond', '4-2'],
  44: [5, 'diamond', '5 of diamond', '5-2'],
  45: [6, 'diamond', '6 of diamond', '6-2'],
  46: [7, 'diamond', '7 of diamond', '7-2'],
  47: [8, 'diamond', '8 of diamond', '8-2'],
  48: [9, 'diamond', '9 of diamond', '9-2'],
  49: [10, 'diamond', '10 of diamond', '10-2'],
  50: [11, 'diamond', 'Jack of diamond', 'J-2'],
  51: [12, 'diamond', 'Queen of diamond', 'Q-2'],
  52: [13, 'diamond', 'King of diamond', 'K-2']
}

/* ==========================================================================
  HILO Operation Type
========================================================================== */
export const HI_LO_GAME_BET_TYPE = {
  SAME_OR_ABOVE: 1,
  SAME: 2,
  SAME_OR_BELOW: 3,
  ABOVE: 4,
  BELOW: 5
}

export const DEFAULT_PAGE_CALLS = 10
export const S3_BUCKET_ENDPOINT = 'https://gs-custom-games-storage.s3.amazonaws.com'

export const S3_IMAGES_URL = Object.freeze({
  IMAGE_URL: 'https://metaversity-dev-storage.s3.amazonaws.com'
})

export const PROFIT_TYPE = {
  TAKE_PROFIT_AMOUNT: 'takeProfitAmount',
  PROFIT: 'profit'
}

export const STOP_LOSS_TYPE = {
  STOP_LOSS_AMOUNT: 'stopLossAmount',
  STOP_LOSS: 'stopLoss'
}

/* ==========================================================================
  Betting modes constants
========================================================================== */
export const BET_MODES = Object.freeze({
  MANUAL: 'manual',
  AUTO: 'auto',
  STRATEGY: 'strategy'
})

export const FEE_MODES = Object.freeze({
  PNL: 'pnl',
  FLAT: 'flat'
})

export const CHART_TYPES = Object.freeze({
  MOUNTAIN: 'mountain',
  CANDLE: 'candle'
})

export const PROFIT_TYPES = Object.freeze({
  PNL: 'PNL',
  ROI: 'ROI'
})

// ==============================================================
// Bet Transaction Types
// ==============================================================
export const transactionType = {
  PUBLIC_BETS: 'publicBets',
  LEADERBOARD: 'leaderboard',
  ACTIVE_BETS: 'activeBets',
  CLOSED_BETS: 'closedBets'
}

export const CRYPTO_SM_PRICE = ['3', '6', '9', '10', '14', '17', '24', '28', '30']

// =======================================================================================
export const perArr = [-100, -50, -10, -5, -1, -0.5, -0.10, -0.05, -0.01, 0, 0.01, 0.05, 0.10, 0.48, 0.95, 4.75, 9.50, 47.50, 95, 475, 950]
// =======================================================================================

//   TGC: 'tgc',
//   LB: 'lb'

export const THEMES_CONFIG = [
  {
    id: 1,
    name: 'Default',
    slug: 'default', // PIXI
    themeId: 1,
    themeClassName: 'default' // CSS
  },
  {
    id: 2,
    name: 'TG CASINO',
    slug: 'tgc', // PIXI
    themeId: 2,
    themeClassName: 'tgc', // empty className for green theme
  },
  {
    id: 3,
    name: 'Lucky Block',
    slug: 'lb',
    themeId: 3,
    themeClassName: 'dark-theme'
  },
  {
    id: 4,
    name: 'WSM',
    slug: 'wsm',
    themeId: 4,
    themeClassName: 'light-theme'
  }
]
