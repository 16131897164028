import React, { useEffect, useMemo } from 'react'
import * as yup from 'yup'
import Button from 'components/ui-kit/Button/index'
import { AnimationIcon, CrossIcon, MusicIcon, RightArrowIcon, SoundIcon, TogglerIcon } from 'assets/icons/index'
import SwitchButton from 'components/ui-kit/SwitchButton/index'
import Image from 'components/ui-kit/Image/index'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUserInformation } from 'redux-store/thunk/user.thunk'
import { getPrecision, numberWithCommas } from 'helpers/common.helpers'
import useGameSettingsUpdateSocketNameSpace from 'socket-resources/hooks/useGameSettingsUpdateSocketNameSpace'
import { getAllCurrency, getGameSetting } from 'redux-store/thunk/gameSettings.thunk'
import { setGameMusic, setGameSound, updateGameSetting } from 'redux-store/redux/slices/gameSettings.slice'
import { userLogin } from 'redux-store/thunk/auth.thunk'
import { useLocation, useHistory } from 'react-router-dom'
import { openErrorToaster } from 'helpers/toaster.helpers'
import buttonClickSound from './audios/button-click.wav'
import gameMusicSound from './audios/game-music.mp3'
import { Howl } from 'howler'
import './Header.scss'
import { setDemoMode, setDemoWalletCurrency } from 'redux-store/redux/slices/auth.slice'
import { DEFAULT_GAME_ID, GAME_NAME_ID_MAPPING } from 'constants/index'

const NewHeader = () => {
  const showHeader = false // no need to show inside iframe
  const dispatch = useDispatch()
  const { search } = useLocation()
  const history = useHistory()
  const operatorId = new URLSearchParams(search).get('operator')
  const currencyCode = new URLSearchParams(search).get('currency')
  const playerToken = new URLSearchParams(search).get('token')
  const demoMode = new URLSearchParams(search).get('demo')
  const gameId = new URLSearchParams(search).get('gameId')
  const coinCode = new URLSearchParams(search).get('coin')

  const { userInfo, userDetails, isLoggedIn, showName } = useSelector((state) => state.auth)
  const { gameMusic, gameSound, systemGameData } = useSelector((state) => state.gameSetting)
  const listenGameSettingsUpdate = useGameSettingsUpdateSocketNameSpace()

  const displayName = useMemo(() => {
    return userDetails?.firstName || userDetails?.lastName || 'Player'
  }, [userDetails])

  const handleLoginUser = () => {
    let updatedGameId = gameId
    if ([DEFAULT_GAME_ID.CRYPTO, DEFAULT_GAME_ID.ROLLER].includes(+updatedGameId)) {
      if (coinCode) {
        updatedGameId = DEFAULT_GAME_ID.CRYPTO
      } else {
        updatedGameId = DEFAULT_GAME_ID.ROLLER
      }
    }

    const params = {
      payload: {
        operatorId,
        operatorUserToken: playerToken,
        currencyCode,
        gameId: updatedGameId || GAME_NAME_ID_MAPPING?.[window.location.pathname]
      },
      history
    }
    dispatch(userLogin(params))
  }

  useEffect(() => {
    if (playerToken && currencyCode) {
      handleLoginUser()
    }
    dispatch(setDemoMode(!!JSON.parse(demoMode)))
    dispatch(setDemoWalletCurrency(currencyCode))
  }, [playerToken, currencyCode, demoMode])

  useEffect(() => {
    window.addEventListener('beforeunload', () => {
      // signOut()
    })
    const clearGameSettingsUpdate = listenGameSettingsUpdate(handleGameSettingsUpdate)

    return () => {
      clearGameSettingsUpdate()
    }
  }, [])

  const buttonClickAudio = useMemo(() => {
    const audio = new Howl({
      src: [buttonClickSound]
    })
    return audio
  }, [])

  const playClickSound = () => {
    if (gameSound) {
      buttonClickAudio?.play()
    }
  }

  const systemGameDataSchemas = yup.array().of(yup.object().shape({
    id: yup.string().required(),
    gameId: yup.number().required(),
    maxBet: yup.object().shape({
      GC: yup.string(),
      SC: yup.string()
    }),
    minBet: yup.object().shape({
      GC: yup.string(),
      SC: yup.string()
    }),
    maxProfit: yup.object().shape({
      GC: yup.string(),
      SC: yup.string()
    }),
    houseEdge: yup.number().required(),
    minOdds: yup.number().required(),
    maxOdds: yup.number().required(),
    minAutoRate: yup.number().required(),
    createdAt: yup.string().required(),
    updatedAt: yup.string().required()
  })).min(1)

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getAllCurrency())
      // dispatch(getGameSetting())
      dispatch(fetchUserInformation())
    }
  }, [isLoggedIn])

  const handleGameSettingsUpdate = async ({ data }) => {
    if (data) {
      const isValid = await systemGameDataSchemas.isValid(data)
      if (isValid) {
        dispatch(updateGameSetting(data))
      }
    }
  }

  const handleGameMusic = useMemo(() => {
    const audio = new Howl({
      src: [gameMusicSound],
      loop: true
    })
    return audio
  }, [])

  useEffect(() => {
    if (gameMusic) {
      handleGameMusic?.play()
    } else {
      handleGameMusic?.pause()
    }
  }, [gameMusic])

  const handleOpen = () => {
    playClickSound()
  }

  const closeDropdown = () => {
    const dropdownButton = document.getElementById('dropdownMenuButton1')
    if (dropdownButton) {
      dropdownButton.click() // Simulate a click on the dropdown button to close it
    }
  }
  const openExternalLink = (url = '') => {
    // window.parent.location = url
  }
  const handleSwitch = () => {
    openErrorToaster({ message: 'Switch Currency in DDD Lobby!' })
  }

  return (
    <>
      {showHeader && (
        <header className=''>
          <nav className='navbar navbar-expand navbar-dark'>
            <div className='container-fluid'>
              <span onClick={() => openExternalLink()} className='navbar-brand p-0 me-0 me-md-4'>
                <Image src='/assets/images/logo/logo.png' alt='logo' />
              </span>
              <div className='justify-content-end flex-grow-1' id='navbarScroll'>
                <ul className='navbar-nav flex-row my-2 my-lg-0 navbar-nav-scroll gap-2'>
                  <li className='nav-item ms-auto'>
                    <Button className='btn-static text-white navbar-wallet-btn d-flex justify-content-end align-items-center py-1 ps-2'>
                      <div className='d-flex'>
                        {userInfo?.wallet?.currency} {numberWithCommas(getPrecision(userInfo?.wallet?.balance))}
                      </div>
                      <SwitchButton
                        className='d-none custom-switch ms-2' onClick={handleSwitch}
                      />
                    </Button>
                  </li>
                  <li className='d-none   nav-item dropdown setting-dropdown'>
                    <span type='button' id='dropdownMenuButton1' data-bs-toggle='dropdown' data-bs-auto-close='outside' aria-expanded='false'>
                      <Button className='btn-outline-secondary' onClick={() => playClickSound()}>
                        <TogglerIcon className='align-baseline' />
                      </Button>
                    </span>
                    <ul className='dropdown-menu dropdown-menu-end mt-3 pb-0' aria-labelledby='dropdownMenuButton1'>
                      <li>
                        <div className='d-flex justify-content-between mb-3'>
                          <div className='d-flex align-items-center gap-3'>
                            <span className='setting-user-icon'>{displayName ? displayName.substring(0, 1) : 'P'}</span>
                            {
                              showName && (
                                <span className='setting-user-name text-white'>{displayName || 'Player'}</span>
                              )
                            }
                          </div>
                          <div>
                            <Button className='btn-outline-primary btn-close-modal p-0 w-26' onClick={closeDropdown}>
                              <CrossIcon />
                            </Button>
                          </div>
                        </div>
                        <ul className='switch-settings text-white'>
                          <li>
                            <div className='dropdown-item d-flex justify-content-between'>
                              <span><AnimationIcon className='me-2' />
                                Animation
                              </span>
                              <div className='form-check form-switch'>
                                <input className='form-check-input ms-1' type='checkbox' role='switch' id='settingCheck1' defaultChecked />
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className='dropdown-item d-flex justify-content-between'>
                              <span><SoundIcon className='me-2' />
                                Sound
                              </span>
                              <div className='form-check form-switch'>
                                <input className='form-check-input ms-1' type='checkbox' role='switch' id='settingCheck2' checked={gameSound} onChange={() => dispatch(setGameSound())} />
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className='dropdown-item d-flex justify-content-between'>
                              <span><MusicIcon className='me-2' />
                                Music
                              </span>
                              <div className='form-check form-switch'>
                                <input className='form-check-input ms-1' type='checkbox' role='switch' id='settingCheck3' checked={gameMusic} onChange={() => dispatch(setGameMusic())} />
                              </div>
                            </div>
                          </li>
                        </ul>
                      </li>
                      <li className='d-md-none'><button className='dropdown-item d-flex align-items-center justify-content-between' type='button' onClick={handleOpen}>Choose Character <RightArrowIcon /></button></li>
                      <li className='d-md-none'><button className='dropdown-item d-flex align-items-center justify-content-between' type='button' onClick={() => { }}>How to play? <RightArrowIcon /></button></li>
                      <li><button className='dropdown-item d-flex align-items-center justify-content-between' type='button' onClick={() => { }}>Bet History <RightArrowIcon /></button></li>
                      <li><button className='dropdown-item d-flex align-items-center justify-content-between' type='button' onClick={() => openExternalLink()}>Lobby! <RightArrowIcon /></button></li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </header>
      )}
    </>
  )
}

export default NewHeader
