import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'constants/index'
import { microServices } from 'network/apis/microservice/index'
import axiosInstanceService from 'network/apis/index'
import successMessages from 'network/messages/successMessages'

const cryptoPrefix = 'game/crypto-game'

export const cryptoBetService = (data) => {
  return axiosInstanceService(METHOD_TYPES.post, `${!data.instrumentId ? `${cryptoPrefix}/roller-coaster/place-bet` : `${cryptoPrefix}/crypto-futures/place-bet`}`, data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: successMessages.betPlacedSuccessfully
  })
}

export const cryptoCashoutService = (data) => {
  return axiosInstanceService(METHOD_TYPES.post, `${!data.instrumentId ? `${cryptoPrefix}/roller-coaster/cash-out-bet` : `${cryptoPrefix}/crypto-futures/cash-out`}`, data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: successMessages.betCashedOut
  })
}

export const getBetPlaceTransactionService = (data) => {
  return axiosInstanceService(METHOD_TYPES.get, `${data.rollercoaster ? `${cryptoPrefix}/roller-coaster/bets` : `${cryptoPrefix}/crypto-futures/bets`}`, {}, {
    server: microServices.SERVICE_URL_1,
    params: data
  })
}

export const getBetTransactionDetailService = (data) => {
  return axiosInstanceService(METHOD_TYPES.get, `${!data.instrumentId ? `${cryptoPrefix}/roller-coaster/bet-detail` : `${cryptoPrefix}/crypto-futures/bet-detail`}`, {}, {
    server: microServices.SERVICE_URL_1,
    params: data
  })
}

export const updateBetService = (data) => {
  return axiosInstanceService(METHOD_TYPES.patch, `${!data.instrumentId ? `${cryptoPrefix}/roller-coaster/bet-update` : `${cryptoPrefix}/crypto-futures/bet-update`}`, data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit
  })
}

export const cryptoCoinsService = () => {
  return axiosInstanceService(METHOD_TYPES.get, `${cryptoPrefix}/crypto-futures/instruments`, {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.page
  }
  )
}
