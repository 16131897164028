import * as PIXI from "pixi.js"
import { app } from "./appPixi.js";
import { gameManager } from "./gameManager.js";
import { progressBar } from "./assetsProgressBar.js";
import { basePath, defaultTheme, path } from "./settings.js";
import { THEMES } from "games/Constants/Themes.js";
import { THEMES_CONFIG } from "constants/index";


export let assetsLoaded = false

export let promiseAssets;

export const themes = {
  _currentTheme: defaultTheme,

  get currentTheme() {
    return this._currentTheme;
  },

  set currentTheme(theme) {
    if(THEMES_CONFIG.find(t => t.slug == theme)){
      this._currentTheme = theme;
    } else{
      this._currentTheme = defaultTheme;
      console.error(`Invalid theme: ${theme}. Loading default theme.`);
    }
  }

  // set currentTheme(theme) {
  //   if (theme === THEMES.TGC || theme === THEMES.LB) {
  //     this._currentTheme = theme;
  //   } else {
  //     this._currentTheme = defaultTheme;
  //     console.error(`Invalid theme: ${theme}. Loading default theme.`);
  //   }
  // }
};

export function startLoadingAssets (setIsPixiAssetsLoaded, themeSlug) {

  // const values = Object.values(THEMES);
  // themes.currentTheme = values[themeIndex];
  themes.currentTheme = themeSlug;

  loadAssets()
    .then(function () {
      onAssetsLoaded(setIsPixiAssetsLoaded)
    })
    .catch(function (e) {
      console.error(e)
    })
}

async function loadAssets () {
  const themePath = `${basePath}themes/${themes.currentTheme}/`

  PIXI.Assets.addBundle('Images', {
    plinkoAnim: themePath + 'plinkoAnim.json',
    shineEffect: path.spriteSheetsPath + 'shineEffect.json',
    sqBG: path.imagePath + 'sqBg.png',
    box: path.imagePath+'box.png',
    pinAsset: themePath+'pinAsset.png',
  })
  await PIXI.Assets.loadBundle('Images', (progress) => {
    progressBar.setProgress(progress)
  })
}

function onAssetsLoaded (setIsPixiAssetsLoaded) {
  assetsLoaded = true
  setIsPixiAssetsLoaded(true)
  // console.log("assetsLoaded")
  gameManager()
}
