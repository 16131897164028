import { startLoadingAssets } from "./assetsLoad.js";
import { isPixiStandAlone } from "./settings.js";
import { createUI, uiCreated } from "./gameUI.js";
import { tickerFunction } from "./gameLogic.js";

// For Standalone PIXI
if (isPixiStandAlone) startLoadingAssets()

export function gameManager () {
  if(uiCreated)
  return;
  createUI();
  tickerFunction();
}
