import { METHOD_TYPES } from 'constants/index'
import axiosInstanceService from 'network/apis/index'
import { microServices } from 'network/apis/microservice/index'

export const placeBetPlinkoGameService = (data) => {
  return axiosInstanceService(METHOD_TYPES.post, 'game/fast-game/plinko/place-bet', data, {
    server: microServices.SERVICE_URL_1
  })
}

export const demoPlaceBetPlinkoGameService = (data) => {
  return axiosInstanceService(METHOD_TYPES.post, 'game/fast-game/plinko/demo-place-bet', data, {
    server: microServices.SERVICE_URL_1
  })
}

export const getMyBetsPlinkoService = (params) => {
  return axiosInstanceService(METHOD_TYPES.get, 'game/fast-game/plinko/my-bets', {}, {
    params,
    server: microServices.SERVICE_URL_1
  })
}

export const postLightningBoardDetailsService = () => {
  return axiosInstanceService(METHOD_TYPES.post, 'game/fast-game/plinko/lightning-board-details', {}, {
    server: microServices.SERVICE_URL_1
  })
}
