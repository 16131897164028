// NOTE: DO NOT UPDATE THIS RESERVED TRANSLATION KEYS AND THEIR VALUES
const reservedTranslationsWithUniqueKeyNames = {
  leadingSpaceNotAllowed: 'No se permite espacio al principio.',
  trailingSpaceNotAllowed: 'No se permite espacio al final.',
  mustbeAtleastCharacters: '{{label}} debe tener al menos {{minNumber}} caracteres',
  cannotExceedCharacters: '{{label}} no puede exceder los {{maxNumber}} caracteres',
  numberMustbeAtleast: '{{label}} debe ser al menos {{minNumber}}',
  numberCannotExceed: '{{label}} no puede exceder los {{maxNumber}}',
  isRequired: '{{label}} es requerido',
  emptySpaceNotContain: 'Este campo no puede contener espacios.',
  404: '404',
  register: 'Registrarse',
  login: 'Iniciar sesión',
  comingSoon: 'Próximamente',
  submit: 'Enviar',
  email: 'Correo Electrónico',
  termsConditions: 'Términos y condiciones',
  password: 'Contraseña',
  eighteenPlus: 'Tengo más de 18 años y acepto los Términos de Servicio',
  signUp: 'Registrarse',
  createNewAccount: 'Crear una nueva cuenta',
  fullName: 'Nombre completo',
  referralCode: 'Código de referencia',
  confirmPassword: 'Confirmar contraseña',
  facebook: 'Facebook',
  google: 'Google',
  haveAnAccount: '¿Ya tienes una cuenta? ',
  pageNotFound: '¡No podemos encontrar la página que estás buscando!',
  error: 'Error',
  somethingWentWrong: '¡Algo salió mal!',
  personalDetails: 'Detalles personales',
  firstName: 'Nombre',
  lastName: 'Apellido',
  dateOfBirth: 'Fecha de nacimiento',
  phoneNumber: 'Número de teléfono',
  change: 'Cambiar',
  saveChanges: 'Guardar cambios',
  currentPassword: 'Contraseña actual',
  newPassword: 'Nueva contraseña',
  myProfile: 'Mi perfil',
  transactions: 'Transacciones',
  deposit: 'Depósito',
  withdraw: 'Retirar',
  referral: 'Referencia',
  changePassword: 'Cambiar contraseña',
  logout: 'Cerrarsesión',
  currency: 'Moneda.',
  view: 'Ver',
  ok: 'Aceptar',
  cancel: 'Cancelar',
  passwordRegexMatch: 'La contraseña debe contener una mayúscula, un número y un carácter especial',
  invalidEmailFormat: 'Formato de correo electrónico no válido',
  userName: 'Nombre de usuario',
  noRecordsFound: 'No se encontraron registros',
  backToLobby: 'Volver al vestíbulo'
}

// NOTE: PLEASE ADD YOUR API SUCCESS MESSAGE TRANSLATIONS HERE ONLY
const apiSuccessMessagesTranslations = {
  loginSuccessfully: 'Inicia sesión exitosamente.',
  logoutSuccessfully: 'Cerrar sesión exitosamente.',
  passwordUpdateSuccessfully: 'Contraseña actualizada exitosamente.',
  updatePlayerStatusSuccessfully: 'Estado del jugador actualizado exitosamente.',
  resetPasswordSuccessfully: 'Restablecimiento de contraseña exitoso.',
  signupSuccessfully: 'Regístrese exitosamente.',
  betPlacedSuccessfully: 'Apuesta realizada con éxito',
  betCancelledSuccessfully: 'Apuesta cancelada con éxito'
}

// NOTE: PLEASE ADD YOUR API ERROR MESSAGE TRANSLATIONS HERE ONLY
const apiErrorMessagesTranslations = {
  LoginFailedError: 'Inicio de sesión fallido',
  InvalidCredentialsError: 'Credenciales inválidas',
  InvalidTokenError: 'Token no válido',
  InvalidSessionError: 'Sesión expirada',
  CreateUserError: 'No se puede crear el usuario',
  DataMismatchError: 'Los datos no coinciden',
  UserNotFoundError: 'Usuario no encontrado',
  DuplicateEmailError: 'El correo electrónico ya existe',
  RequestInputValidationError: 'Por favor, verifique los datos de la solicitud',
  ResponseInputValidationError: 'Error en la validación de la respuesta, consulte el esquema JSON de la respuesta',
  SocketRequestInputValidationError: 'Por favor, verifique los datos de la solicitud para la comunicación por socket',
  SocketResponseValidationError: 'Error en la validación de la respuesta de la comunicación por socket, consulte el esquema JSON de la respuesta',
  InternalServerError: 'Error interno del servidor',
  InvalidSocketArgumentError: 'Proporcione argumentos adecuados: nombre del evento, [objeto de carga] y [callback]',
  InvalidCredentials: 'Las credenciales no coinciden',
  InvalidToken: 'No se pasó el token de acceso o ha caducado',
  InvalidSession: 'La sesión de usuario no es válida, inicie sesión nuevamente',
  InvalidAccess: 'Acceso denegado',
  NonOperationalError: 'Se produjo un error en el servidor',
  UserNotExists: 'El usuario no existe',
  InvalidAction: 'Acción no válida',
  SessionAlreadyStarted: 'La sesión ya ha comenzado',
  SessionNotStarted: 'La sesión no ha comenzado',
  EmailNotVerified: 'Correo electrónico no verificado',
  InvalidGameTypeError: 'No se encontraron configuraciones para el tipo de juego proporcionado',
  InvalidGameRoundError: 'No se encontró ningún juego para los detalles del juego proporcionados',
  NoRoundRunningError: 'No hay una ronda en curso en este momento',
  NoPlacedBetFoundError: 'No se encontraron apuestas realizadas',
  NoWalletFoundError: 'No se encontró la billetera con los datos especificados',
  NotEnoughBalanceError: 'El saldo no es suficiente para realizar el proceso',
  AutoRateIsInvalidError: 'La tasa automática no está dentro del límite válido',
  BetAmountIsNotInLimitError: 'El monto de la apuesta no está dentro de los límites válidos',
  EmailNotFound: 'Correo electrónico no encontrado',
  UserNotAbove18YearsError: 'El usuario no tiene más de 18 años',
  InvalidBlockchainAddress: 'Dirección de blockchain no válida',
  AddressMismatch: 'La dirección proporcionada y la dirección recuperada no coinciden',
  NonceLifetimeExpired: 'Tiempo de vida del nonce caducado',
  SomethingWentWrong: 'Algo salió mal',
  AccountNotActive: 'La cuenta no está activa',
  LoginTokenRequire: 'Se requiere OTP (One-Time Password)',
  UserAlreadyExists: 'El usuario ya existe',
  InvalidVerificationToken: 'Token de verificación no válido',
  UserNotActive: 'El usuario está bloqueado, por favor contacte al administrador',
  FileUploadFailed: 'Fallo en la carga de la imagen',
  EmailAlreadyVerified: 'El correo electrónico ya está verificado',
  InvalidReferralCode: 'Código de referencia no válido',
  InvalidAffiliateCode: 'Código de afiliado no válido',
  RecordNotFound: 'Registro no encontrado',
  WithdrawalRequestAlreadyPending: 'La solicitud de retiro ya está pendiente',
  WalletDoesNotBelongToUser: 'La billetera no pertenece a este usuario',
  BetAlreadyInProgress: 'La apuesta ya está en progreso',
  ServerSeedNotFoundErrorType: 'No se encontró la semilla del servidor, inicie sesión nuevamente',
  InvalidTileErrorType: 'La casilla debe estar en el rango de 1 a 25',
  NoOpenedTileFoundErrorType: 'No se encontró ninguna casilla abierta para esta apuesta',
  FavoriteGameExistsErrorType: 'El juego favorito ya existe',
  FavoriteGameNotFoundErrorType: 'Juego favorito no encontrado',
  TransactionAlreadyPendingErrorType: 'La transacción ya está pendiente',
  UserHasNoActiveBonusErrorType: 'El usuario no tiene ningún bono activo',
  BonusExpiredErrorType: 'El bono ha caducado',
  UserHasAlreadyActivatedBonusWageringErrorType: 'El usuario ya ha activado el requisito de apuesta del bono',
  InvalidRoundHashErrorType: 'Hash de ronda incorrecto',
  InvalidSignatureErrorType: 'Firma incorrecta',
  UserHasNoBonusAmountToClaimErrorType: 'El usuario no tiene monto de bono para reclamar',
  BonusNotExistsErrorType: 'Bono no encontrado',
  BlockedTransactionErrorType: 'Error de bloqueo de transacción'
}

// NOTE: PLEASE ADD YOUR OTHER KEY TRANSLATIONS HERE ONLY
const otherTranslationKeys = {
  betAmount: 'Cantidad apostada',
  betHistory: 'Historial de apuestas',
  provablyFair: 'Juego Justo comprobable',
  minimumBet: 'Apuesta mínima',
  maximumBet: 'Apuesta máxima',
  selectYourBetAmount: 'Selecciona tu monto de apuesta',
  buyInAmount: 'Monto de compra',
  selectYourBuyInAmountFromGivenRange: 'Selecciona tu monto de compra dentro del rango dado'
}

/**
 * NOTE:
 * =================================================================================================
 * Please go through this spreading objects first before adding new translation key. So that the
 * duplicate translations will not be created and help us to maintain the consitency of code.
 * If you didn't find any of translation as per your requirement in reserve or api success & error
 * msg translation object, Please add new translation with unique key name in otherTranslationKeys
 * Object. Unique key names help us to avoid overriding of translations.
 * =================================================================================================
 */
export default {
  ...reservedTranslationsWithUniqueKeyNames,
  ...apiSuccessMessagesTranslations,
  ...apiErrorMessagesTranslations,
  ...otherTranslationKeys
}
