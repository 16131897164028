import * as PIXI from "pixi.js"
import {
  app
} from "./appPixi.js";
import {
  config,
  isPixiStandAlone,
  path as basePath,
  gameDisplaySettings
} from "./settings.js";
import {
  AnimationSprite,
  ObjectPool,
  countCharacters,
  createMultiplierBoxes,
  getDirection,
  splitGradientTexture,
  spriteSettings,
  updateBallRow
} from "./utility.js";
import {
  distance
} from "./gameLogic.js";
import {
  boxColor,
  boxNumbers,
  dispatching,
  multiplierBoxColor
} from "./bridge.js";
import {
  DEFAULT_PLINKO_PINS
} from "../plinkogame.constants.js";
import {
  appAspectRatioX
} from "games/CrashGame/pixi-js-scripts/gameUI.js";
import { themes } from "./assetsLoad.js";

let ballPool;
let pegs = [];
let pegsForShine = [];
let balls = [];
let boxes = [];
let path = [];
let totalBalls = 0;
let uiCreated = false;
let boolBallCreated = false;
let isSetupBoxes = false;
let totalLines = isPixiStandAlone ? 16 : DEFAULT_PLINKO_PINS;
export let bgDestroyed = false;
let bgCreated = false;

let TooltipCreated = false

let pegHolder;

let boxTexture

let toolTipArray;

const gameAssets = {
  background: {
    left: {
      one: new PIXI.Sprite(),
      two: new PIXI.Sprite(),
      three: new PIXI.Sprite(),
      four: new PIXI.Sprite(),
    },
    right: {
      one: new PIXI.Sprite(),
      two: new PIXI.Sprite(),
      three: new PIXI.Sprite(),
      four: new PIXI.Sprite(),
    },
  },
  //Fonts
  fonts: {
    regular: {},
  },

  //Tooltip assets
  toolTipBox: new PIXI.Graphics(),
  toolTipPointer: new PIXI.Graphics(),
  toolTipPointer2: new PIXI.Graphics(),

  section1: {
    graphic: new PIXI.Graphics(),
    profitText: new PIXI.Text(),
    BXCtext: new PIXI.Graphics(),
    amountText: new PIXI.Text()
  },

  section2: {
    graphic: new PIXI.Graphics(),
    chanceText: new PIXI.Text(),
    amountText: new PIXI.Graphics(),
    percenText: new PIXI.Text()
  }

};

let pegsInRows = Array.from({
    length: totalLines
  },
  (_, rowIndex) => rowIndex + config.pinInFirstLine
);
const containers = {
  rootContainer: new PIXI.Container(),
  bgContainer: null,
  pegsContainer: null,
  ballContainer: [],
  lightningContainer: new PIXI.Container(),
  UIcontainer: new PIXI.Container(),
};

function createUI() {

  const gameContainer = document.getElementById('pixi-plinko-game')
  app.renderer.resize(gameContainer.offsetWidth, gameContainer.offsetHeight)

  //console.log("Game container Width= "+gameContainer.offsetWidth+" , Height= "+gameContainer.offsetHeight)
  //console.log("App Width= "+app.screen.width+" , Height= "+app.screen.height)

  prepareContainers();
  createPegsHolder();
  createPegs(config.pegs);
  //createBall();
  setupBoxes();
  createBallsPool()
  createHover()
  uiCreated = true;
  // containers.rootContainer.position.x = app.screen.width*0.04

  //if(gameContainer.offsetWidth<550)
  //containers.rootContainer.position.y = gameContainer.offsetWidth*0.15;
  //else
  //containers.rootContainer.position.y = gameContainer.offsetWidth*-0.1;

  if (gameContainer.offsetWidth < 1000 && gameContainer.offsetWidth > 750) {
    //  containers.rootContainer.position.y = gameContainer.offsetWidth*-0.13;
    //  containers.rootContainer.position.x = gameContainer.offsetWidth*0.14;
    // containers.rootContainer.position.set(20, -70);
    // containers.rootContainer.scale.set(1.4*(app.screen.width/1920))
  }

  //if(containers.rootContainer.height>gameContainer.offsetHeight){
  // containers.rootContainer.scale.set((gameContainer.offsetWidth/1920)*1.4)
  //}

  //console.log("Container Width= "+ containers.rootContainer.width+" , Height= "+ containers.rootContainer.height)
  //console.log(gameContainer.offsetWidth/containers.rootContainer.width)
  //console.log("height="+gameContainer.offsetWidth)

  containers.rootContainer.pivot.x = containers.rootContainer.width / 2;
  containers.rootContainer.pivot.y = containers.rootContainer.height / 2;

  if (app.screen.width - 70 > app.screen.height) {
    containers.rootContainer.scale.set(1.3 * (app.screen.height / 1080))
    containers.rootContainer.position.set(app.screen.width * 0.55, app.screen.height * 0.6);
    //console.log("If")
  } else {
    containers.rootContainer.scale.set(1.7 * (app.screen.width / 1920))
    containers.rootContainer.position.set(app.screen.width * 0.56, app.screen.height * 0.65);
    //console.log("Else")
  }





  const resizeApp = ()=>{

    app.renderer.resize(document.getElementById('pixi-plinko-game').offsetWidth, document.getElementById('pixi-plinko-game').offsetWidth*0.7)
    containers.rootContainer.scale.set(document.getElementById('pixi-plinko-game').offsetHeight/900)
    containers.rootContainer.position.set(document.getElementById('pixi-plinko-game').offsetWidth*0.56,document.getElementById('pixi-plinko-game').offsetHeight*0.65)

}
  window.addEventListener("resize", resizeApp);


}



function createPegsHolder() {
  pegHolder = new PIXI.Sprite(PIXI.Assets.get('sqBG'));
  pegHolder.x = gameDisplaySettings.x;
  pegHolder.y = gameDisplaySettings.y;
  pegHolder.anchor.set(0.5);
  pegHolder.width = gameDisplaySettings.size;
  pegHolder.height = pegHolder.width;
  pegHolder.sortableChildren = true;

  // pegHolder.tint = 0x0f0f11;
  containers.rootContainer.addChild(pegHolder);
}

function prepareContainers() {
  app.stage.addChild(containers.rootContainer);
  containers.rootContainer.name = "RootContainer";
  containers.ballContainer.name = "BallContainer";


}

function createPegs(pegConfig) {
  let pegNo = 0;
  for (let row = 0; row < totalLines; row++) {
    const numPegs = pegsInRows[row];
    const singleRowPegsContainer = new PIXI.Container();
    let currentPeg = [];
    for (let i = 0; i < numPegs; i++) {
      const peg = {};

      peg.sprite = new PIXI.Sprite(PIXI.Assets.get("pinAsset"));
      peg.sprite.width = config.pegs.pegsWidth + (16 - totalLines) * 3;
      peg.sprite.height = config.pegs.pegsWidth + (16 - totalLines) * 3;
      peg.sprite.anchor.set(0.5);
      peg.sprite.x =
        (i - (numPegs - 1) / 2) *
        ((config.pegs.spacing.width * 16) / totalLines);
      if (row == 0)
        peg.sprite.y =
        row * ((config.pegs.spacing.height * 16) / totalLines) -
        config.topMargin;
      else
        peg.sprite.y =
        (row * (config.pegs.spacing.height - totalLines * 0.45) * 16) /
        totalLines -
        config.topMargin;

      pegNo++;

      peg.states = [];
      peg.shine = new PIXI.AnimatedSprite(
        PIXI.Assets.get("shineEffect").animations.frames
      );
      peg.shine.scale.set(config.colors[themes.currentTheme]?.pinShineScale); // 3.5
      peg.shine.alpha = 0.3 // 0.55
      peg.shine.animationSpeed = config.pegs.shine.animationSpeed; //0.7
      peg.shine.visible = false;
      peg.startShine = () => {
        peg.shine.play();
        peg.shine.visible = true;
      };
      peg.shine.onLoop = () => {
        peg.shine.gotoAndStop(1);
        peg.shine.visible = false;
      };
      peg.shine.anchor.set(0.5, 0.5);
      peg.sprite.addChild(peg.shine);

      singleRowPegsContainer.addChild(peg.sprite);
      pegs.push(peg);
      currentPeg.push(peg);
      peg.sprite.tint = config.colors[themes.currentTheme]?.pinsColor
      peg.shine.tint = config.colors[themes.currentTheme]?.pinsColor
    }
    pegsForShine.push(currentPeg);
    currentPeg = [];
    pegHolder.addChild(singleRowPegsContainer);
  }

}

// let ballScaleForAnimation = 1.9
const globalPoint = new PIXI.Point(0, 0);

function createBallsPool() {
  ballPool = new ObjectPool(() => new AnimationSprite(pegHolder, pegs[0].sprite.y), 50)
}

function animatedBallCreate(testPathAnim, lastBall = false) {
  let animatedBallSprite = ballPool.getObject();
  animatedBallSprite.setVisiblity(true)
  animatedBallSprite.animationSprite.scale.set(
    getDirection(testPathAnim, 0) * config.ball.scaleForAnimation[totalLines - 8],
    config.ball.scaleForAnimation[totalLines - 8]
  );
  animatedBallSprite.animationSprite.drop = animatedBallSprite.moveBall;
  animatedBallSprite.animationSprite.onLoop = () => animatedBallSprite.onLoop(testPathAnim);
  animatedBallSprite.animationSprite.lastBall = lastBall

  balls.push(animatedBallSprite.animationSprite);


  // animatedBallSprite.play();


  // animatedBallSprite.scale.set(
  //   getDirection(0) * testScaleFactor * config.ball.bounceEffect.scaleFactorScale[totalLines - 8],
  //   testScaleFactor * config.ball.bounceEffect.scaleFactorScale[totalLines - 8]
  // );
}

function createBall(lastBall = true) {
  totalBalls++;
  animatedBallCreate(path[totalBalls - 1], lastBall)
}

function setupBoxes() {
  const boxScale = 1.2;

  //let boxHeight = config.box.height - totalLines * config.box.heightScale
  //let boxWidth = config.box.width - totalLines * config.box.widthScale;

  if (!boxTexture) {
    boxTexture = 'box' //`${'green'}Box`
  }

  const gradientSprites = createMultiplierBoxes(PIXI.Assets.get(boxTexture), pegsInRows[totalLines - 1] - 1)
  for (let box = 0; box < gradientSprites.length; box++) {
    const boxContainer = new PIXI.Container()
    pegHolder.addChild(boxContainer)
    boxContainer.zIndex = 1;

    let sprite = gradientSprites[box]
    // sprite.width = boxWidth
    // sprite.height = boxHeight

    // const maskGraphics = new PIXI.Graphics()
    // maskGraphics.beginFill(0xffffff)
    // maskGraphics.drawRoundedRect(-sprite.width / 2, -sprite.height / 2, sprite.width, sprite.height, config.box.cornerEdgeRadius)
    // maskGraphics.endFill()

    //sprite.mask = maskGraphics
    boxContainer.addChild(sprite);

    sprite.anchor.set(0.5)
    boxContainer.x = (box - (pegsInRows[totalLines - 1] - 2) / 2) * (config.pegs.spacing.width * 16 / totalLines);
    // if (totalLines < 9){
    //   sprite.width = sprite.width + 140 - (totalLines * 11)
    // }
    // else if (totalLines < 12){
    //   sprite.width = sprite.width + 160 - (totalLines * 12.5)
    // }
    // else{
    //   sprite.width = sprite.width + 130 - (totalLines * 9)
    // }


    if (totalLines == 8) {
      sprite.width = sprite.width + 50 - (totalLines * 11)
    } else if (totalLines == 9) {
      sprite.width = sprite.width + 60 - (totalLines * 13)
    } else if (totalLines == 10) {
      sprite.width = sprite.width + 55 - (totalLines * 12.5)
    } else if (totalLines == 11) {
      sprite.width = sprite.width + 55 - (totalLines * 12.5)
    } else if (totalLines == 12) {
      sprite.width = sprite.width + 45 - (totalLines * 11.5)
    } else if (totalLines == 13) {
      sprite.width = sprite.width + 48 - (totalLines * 11.5)
    } else if (totalLines == 14) {
      sprite.width = sprite.width + 40 - (totalLines * 10.5)
    } else if (totalLines == 15) {
      sprite.width = sprite.width + 32 - (totalLines * 9.5)
    } else if (totalLines == 16) {
      sprite.width = sprite.width + 35 - (totalLines * 9.5)
    }
    sprite.height = sprite.width * 0.5

    boxContainer.y = boxContainer.startPos = config.topMargin;
    boxes.push({
      sprite: boxContainer
    });
    // sprite.boxColor = box.white
    sprite.boxState = sprite.doNothing

    boxContainer.doNothing = () => {};
    boxContainer.states = [];

    boxContainer.BoxBounceEffect = (delta) => {
      if (boxContainer.y > boxContainer.startPos) {
        boxContainer.y -= delta * config.box.boxSpeed
      } else {
        boxContainer.y = boxContainer.startPos
        boxContainer.boxState = boxContainer.doNothing
      }
    };

    boxContainer.boxBounceEffectState = boxContainer.BoxBounceEffect;

    boxContainer.states.push(boxContainer.boxBounceEffectState);

    let text = new PIXI.Text(
      "0.0x",
      new PIXI.TextStyle({
        fill: ["#ffffff"],
        fontSize: 35,
        fontWeight: "bold",
      })
    );
    text.anchor.set(0.5);
    // boxContainer.addChild(text);
    boxContainer.addChild(text);
    isSetupBoxes = true;


    sprite.interactive = true;
    //  app.stage.hitArea = app.screen;
    sprite.cursor = 'pointer';
    sprite.on('pointerover', onHover)
      .on('pointerout', disableHover)
      .on('pointermove', onPointerMove);
  }
}

let toolTipWidth = 0
let toolTipHeight = 0
let graphicWidth = 0
let graphicHeight = 0

if (window.matchMedia("(orientation: portrait)").matches) {
  // Mobile
  toolTipWidth = 750 * app.screen.width / 1920
  toolTipHeight = 600 * app.screen.width / 1920
  graphicWidth = toolTipWidth * 0.9
  graphicHeight = toolTipHeight * 0.25
} else {
  // Desktop
  toolTipWidth = 800 * app.screen.width / 1920
  toolTipHeight = 200 * app.screen.width / 1920
  graphicWidth = toolTipWidth * 0.43
  graphicHeight = toolTipHeight * 0.5
}

let boxObj = null

function createHover() {
  if (!TooltipCreated) {

    const themeColor = config.colors[themes.currentTheme]
    //containers.UIcontainer =  new PIXI.Container()

    containers.rootContainer.addChild(containers.UIcontainer)

    gameAssets.toolTipPointer.lineStyle(2, themeColor?.light, 1)
    gameAssets.toolTipPointer.beginFill(themeColor?.light, 1);
    gameAssets.toolTipPointer.drawRect(0, 0, 30 * appAspectRatioX, 40 * appAspectRatioX);
    gameAssets.toolTipPointer.endFill();
    containers.UIcontainer.addChild(gameAssets.toolTipPointer)
    gameAssets.toolTipPointer.rotation = 2.35

    gameAssets.toolTipBox.lineStyle(3, themeColor?.light, 1)
    gameAssets.toolTipBox.beginFill(themeColor?.light, 1);
    gameAssets.toolTipBox.drawRoundedRect(0, 0, toolTipWidth, toolTipHeight, 8);
    gameAssets.toolTipBox.endFill();
    containers.UIcontainer.addChild(gameAssets.toolTipBox)

    gameAssets.toolTipPointer2.lineStyle(2, themeColor?.light, 0)
    gameAssets.toolTipPointer2.beginFill(themeColor?.light, 1);
    gameAssets.toolTipPointer2.drawRoundedRect(0,0, 33 * appAspectRatioX, 10 * appAspectRatioX,5);
    gameAssets.toolTipPointer2.endFill();

    containers.UIcontainer.addChild(gameAssets.toolTipPointer2)
   // gameAssets.toolTipPointer2.rotation = 2.35



    gameAssets.section1.graphic.beginFill(themeColor?.dark, 1);
    gameAssets.section1.graphic.drawRoundedRect(0, 0, graphicWidth, graphicHeight, 8);
    gameAssets.section1.graphic.endFill();
    gameAssets.toolTipBox.addChild(gameAssets.section1.graphic)


    gameAssets.section1.profitText = new PIXI.Text(
      "Profit",
      new PIXI.TextStyle({
        fill: ["#ffffff"],
        fontSize: 100,
        // fontWeight: "bold",
      })
    );
    gameAssets.section1.profitText.anchor.set(0.5);
    gameAssets.toolTipBox.addChild(gameAssets.section1.profitText)


    gameAssets.section1.BXCtext = new PIXI.Text(
      "65.00 BXC",
      new PIXI.TextStyle({
        fill: ["#ffffff"],
        fontSize: 100,
        // fontWeight: "bold",
      })
    );
    gameAssets.section1.BXCtext.anchor.set(0.5);
    gameAssets.toolTipBox.addChild(gameAssets.section1.BXCtext)


    gameAssets.section1.amountText = new PIXI.Text(
      "$ 0.00",
      new PIXI.TextStyle({
        fill: ["#FFFFFF"],
        fontSize: 35,
        // fontWeight: "bold",
      })
    );
    gameAssets.section1.amountText.anchor.set(0,0.5);
    gameAssets.toolTipBox.addChild(gameAssets.section1.amountText)

    gameAssets.section2.graphic.beginFill(themeColor?.dark, 1);
    gameAssets.section2.graphic.drawRoundedRect(0, 0, graphicWidth, graphicHeight, 8);
    gameAssets.section2.graphic.endFill();
    gameAssets.toolTipBox.addChild(gameAssets.section2.graphic)

    gameAssets.section2.chanceText = new PIXI.Text(
      "Chance",
      new PIXI.TextStyle({
        fill: ["#ffffff"],
        fontSize: 35,
        // fontWeight: "bold",
      })
    );
    gameAssets.section2.chanceText.anchor.set(0.5);
    gameAssets.toolTipBox.addChild(gameAssets.section2.chanceText)


    gameAssets.section2.amountText = new PIXI.Text(
      "3.124",
      new PIXI.TextStyle({
        fill: ["#FFFFFF"],
        fontSize: 35,
        // fontWeight: "bold",
      })
    );
    gameAssets.section2.amountText.anchor.set(0, 0.5);
    gameAssets.toolTipBox.addChild(gameAssets.section2.amountText)


    gameAssets.section2.percenText = new PIXI.Text(
      "%",
      new PIXI.TextStyle({
        fill: ["#FFFFFF"],
        fontSize: 35,
        // fontWeight: "bold",
      })
    );
    gameAssets.section2.percenText.anchor.set(0.5);
    gameAssets.toolTipBox.addChild(gameAssets.section2.percenText)




    if (window.matchMedia("(orientation: portrait)").matches) {
      // Mobile
      // console.log("mobile")
      gameAssets.toolTipPointer.scale.set(8 * appAspectRatioX)
      gameAssets.toolTipPointer2.scale.set(8 * appAspectRatioX)

      gameAssets.section1.profitText.scale.set(appAspectRatioX * 0.9)
      gameAssets.section1.BXCtext.scale.set(appAspectRatioX * 0.9)
      gameAssets.section1.amountText.scale.set(appAspectRatioX * 2.7)
      gameAssets.section2.chanceText.scale.set(appAspectRatioX * 2.7)
      gameAssets.section2.amountText.scale.set(appAspectRatioX * 2.5)
      gameAssets.section2.percenText.scale.set(appAspectRatioX * 2.5)

      gameAssets.section1.graphic.position.set(gameAssets.section1.graphic.width * 0.05, gameAssets.section1.graphic.height * 0.7)
      gameAssets.section1.profitText.position.set(gameAssets.section1.graphic.x + gameAssets.section1.profitText.width * 0.5, gameAssets.section1.graphic.y - gameAssets.section1.profitText.height * 10.75)
      gameAssets.section1.BXCtext.position.set(gameAssets.section1.graphic.x + gameAssets.section1.graphic.width - gameAssets.section1.BXCtext.width * 0.5, gameAssets.section1.graphic.y - gameAssets.section1.BXCtext.height * 0.75)
      gameAssets.section1.amountText.position.set(gameAssets.section1.graphic.x + gameAssets.section1.amountText.width * 0, gameAssets.section1.graphic.y + gameAssets.section1.BXCtext.height * 1.5)

      gameAssets.section2.graphic.position.set(gameAssets.section2.graphic.width * 0.05, gameAssets.section2.graphic.height * 2.65)
      gameAssets.section2.chanceText.position.set(gameAssets.section2.graphic.x + gameAssets.section2.chanceText.width * 0.5, gameAssets.section2.graphic.y - gameAssets.section2.chanceText.height * 0.75)
      gameAssets.section2.amountText.position.set(gameAssets.section2.graphic.x + gameAssets.section2.amountText.width * 0.6, gameAssets.section2.graphic.y + gameAssets.section2.amountText.height * 1)
      gameAssets.section2.percenText.position.set(gameAssets.section2.graphic.x + gameAssets.section2.graphic.width - gameAssets.section2.percenText.width * 1.1, gameAssets.section2.graphic.y + gameAssets.section2.percenText.height * 1)

      gameAssets.toolTipPointer.position.set(gameAssets.toolTipBox.width * 0.5 + gameAssets.toolTipPointer.width * 0.5, gameAssets.toolTipBox.height)
    } else {
      // Desktop
      // console.log("Desktop")
      gameAssets.toolTipPointer.scale.set(5 * appAspectRatioX)
      gameAssets.toolTipPointer2.scale.set(5 * appAspectRatioX)

      gameAssets.section1.profitText.scale.set(appAspectRatioX * 0.5)
      gameAssets.section1.BXCtext.scale.set(appAspectRatioX * 0.5)
      gameAssets.section1.amountText.scale.set(appAspectRatioX * 1.7)
      gameAssets.section2.chanceText.scale.set(appAspectRatioX * 1.5)
      gameAssets.section2.amountText.scale.set(appAspectRatioX * 1.5)
      gameAssets.section2.percenText.scale.set(appAspectRatioX * 1.7)

      gameAssets.section1.graphic.position.set(gameAssets.section1.graphic.width * 0.1, gameAssets.section1.graphic.height * 0.7)
      gameAssets.section1.profitText.position.set(gameAssets.section1.graphic.x + gameAssets.section1.profitText.width * 0.5, gameAssets.section1.graphic.y - gameAssets.section1.profitText.height * 0.75)
      gameAssets.section1.BXCtext.position.set(gameAssets.section1.graphic.x + gameAssets.section1.graphic.width - gameAssets.section1.BXCtext.width * 0.5, gameAssets.section1.graphic.y - gameAssets.section1.BXCtext.height * 0.75)
      gameAssets.section1.amountText.position.set(gameAssets.section1.graphic.x + gameAssets.section1.amountText.width * 0.6, gameAssets.section1.graphic.y + gameAssets.section1.BXCtext.height * 1)

      gameAssets.section2.graphic.position.set(gameAssets.section2.graphic.width * 1.23, gameAssets.section2.graphic.height * 0.7)
      gameAssets.section2.chanceText.position.set(gameAssets.section2.graphic.x + gameAssets.section2.chanceText.width * 0.5, gameAssets.section2.graphic.y - gameAssets.section2.chanceText.height * 0.75)
      gameAssets.section2.amountText.position.set(gameAssets.section2.graphic.x + gameAssets.section2.amountText.width * 0.6, gameAssets.section2.graphic.y + gameAssets.section2.amountText.height * 1)
      gameAssets.section2.percenText.position.set(gameAssets.section2.graphic.x + gameAssets.section2.graphic.width - gameAssets.section2.percenText.width * 1.1, gameAssets.section2.graphic.y + gameAssets.section2.percenText.height * 1)

      gameAssets.toolTipPointer.position.set(gameAssets.toolTipBox.width * 0.5 + gameAssets.toolTipPointer.width * 0.5, gameAssets.toolTipBox.height)

    }

    TooltipCreated = true
    containers.UIcontainer.visible = false


  }
}

export function setToolTipArray(obj) {
  toolTipArray = obj
}

function onHover(e) {

  boxObj = this
  containers.UIcontainer.visible = true
  let boxIndex = (pegHolder.getChildIndex(this.parent) - pegHolder.children.length) + totalLines + 1

  if (toolTipArray && toolTipArray[boxIndex])
    setToolTipValues(toolTipArray[boxIndex])

  gameAssets.toolTipBox.pivot.x = gameAssets.toolTipBox.width;
  gameAssets.toolTipBox.pivot.y = gameAssets.toolTipBox.height * 1.2;
  let offset = (app.screen.width / 2 - boxObj.getGlobalPosition().x) / 10
  gameAssets.toolTipBox.parent.toLocal(boxObj.getGlobalPosition(), null, gameAssets.toolTipBox.position)
  gameAssets.toolTipBox.position.x = gameAssets.toolTipBox.position.x + gameAssets.toolTipBox.width * 0.5
  gameAssets.toolTipBox.position.y = gameAssets.toolTipBox.position.y - gameAssets.toolTipBox.height * 0.1

  //gameAssets.toolTipPointer.parent.toLocal(boxObj.getGlobalPosition(), null, gameAssets.toolTipPointer.position)

  // gameAssets.toolTipPointer.x += offset

  // console.log(app.screen.width/2 - e.global.x, offset )

   gameAssets.toolTipPointer.parent.toLocal(boxObj.getGlobalPosition(), null, gameAssets.toolTipPointer.position)





  if (window.matchMedia("(orientation: portrait)").matches) {
     gameAssets.toolTipBox.position.y +=  gameAssets.toolTipBox.height * 0.1

    if (gameAssets.toolTipBox.getGlobalPosition().x > app.screen.width) {
      let offset = gameAssets.toolTipBox.getGlobalPosition().x - app.screen.width * 0.9
      gameAssets.toolTipBox.position.x -= offset //boxObj.position.x//  (app.screen.width  )
    } else if (boxIndex==0) {
      gameAssets.toolTipBox.position.x += offset*2
    }

    gameAssets.toolTipPointer.x += gameAssets.toolTipPointer.width*0.5
    gameAssets.toolTipPointer.y -= gameAssets.toolTipPointer.height*1.5

  } else {
    if (gameAssets.toolTipBox.getGlobalPosition().x > app.screen.width) {
      gameAssets.toolTipBox.position.x = (app.screen.width + gameAssets.toolTipBox.width * 0.88)
    } else if (gameAssets.toolTipBox.getGlobalPosition().x < gameAssets.toolTipBox.width * 0.6) {
      gameAssets.toolTipBox.position.x = (gameAssets.toolTipBox.width * 0.92)
    }

    gameAssets.toolTipPointer.x += gameAssets.toolTipPointer.width*0.5
    gameAssets.toolTipPointer.y -= gameAssets.toolTipPointer.height*1.3
  }

  gameAssets.toolTipPointer2.x = gameAssets.toolTipPointer.x - gameAssets.toolTipPointer2.width*1.15
  gameAssets.toolTipPointer2.y = gameAssets.toolTipPointer.y- gameAssets.toolTipPointer2.height*0.5

  // console.log(app.screen.width)
  // console.log(containers.UIcontainer.getGlobalPosition().x + containers.UIcontainer.width*0.25)

  //containers.UIcontainer.x = Math.max(0, Math.min(app.screen.width , containers.UIcontainer.getGlobalPosition().x + containers.UIcontainer.width*0.25))

}

function onPointerMove(e) {

  if (boxObj) {
    //containers.UIcontainer.parent.toLocal(boxObj.getGlobalPosition(), null, containers.UIcontainer.position)
    //containers.UIcontainer.x = containers.UIcontainer.x - containers.UIcontainer.width / 2;
    //containers.UIcontainer.y = containers.UIcontainer.y - containers.UIcontainer.height*1.2;
  }

  // if (containers.UIcontainer.visible) {
  //   containers.UIcontainer.parent.toLocal(e.data.global, null, containers.UIcontainer.position)
  //   containers.UIcontainer.y = containers.UIcontainer.y - containers.UIcontainer.height - 10
  //   containers.UIcontainer.x = containers.UIcontainer.x - containers.UIcontainer.width / 2

  //  // containers.UIcontainer.x = Math.max(0, Math.min(app.screen.width - containers.UIcontainer.width * 0.15, containers.UIcontainer.x))
  //   //containers.UIcontainer.x = Math.min(app.screen.width , containers.UIcontainer.x )


  // }
}

function disableHover() {
  boxObj = null
  containers.UIcontainer.visible = false
}

function setToolTipValues(obj) {
  gameAssets.section1.BXCtext.text = obj.cryptoAmount + " " + obj.cryptoCurrencyCode
  gameAssets.section1.amountText.text = obj.profitAmount
  gameAssets.section2.amountText.text = obj.chance

  gameAssets.section1.profitText.position.set(gameAssets.section1.graphic.x + gameAssets.section1.profitText.width * 0.5, gameAssets.section1.graphic.y - gameAssets.section1.profitText.height * 0.75)
  gameAssets.section1.BXCtext.position.set(gameAssets.section1.graphic.x + gameAssets.section1.graphic.width - gameAssets.section1.BXCtext.width * 0.5, gameAssets.section1.graphic.y - gameAssets.section1.BXCtext.height * 0.75)
  gameAssets.section1.amountText.position.set(gameAssets.section1.graphic.x + gameAssets.section1.graphic.width * 0.1, gameAssets.section1.graphic.y + gameAssets.section1.graphic.height * 0.5)
  gameAssets.section2.chanceText.position.set(gameAssets.section2.graphic.x + gameAssets.section2.chanceText.width * 0.5, gameAssets.section2.graphic.y - gameAssets.section2.chanceText.height * 0.75)
  gameAssets.section2.amountText.position.set(gameAssets.section2.graphic.x + gameAssets.section2.graphic.width * 0.1, gameAssets.section2.graphic.y + gameAssets.section2.graphic.height * 0.5)
  gameAssets.section2.percenText.position.set(gameAssets.section2.graphic.x + gameAssets.section2.graphic.width - gameAssets.section2.percenText.width * 1.1, gameAssets.section2.graphic.y + gameAssets.section2.percenText.height * 1)

}

function changeMultiplierBackground(boxColor) {
  // console.log("change bg")
  // boxTexture = `${boxColor}Box`
  // const boxSprites = createMultiplierBoxes(PIXI.Assets.get(boxTexture), pegsInRows[totalLines - 1] - 1)

  // if (boxes.length !== boxSprites.length) return
  // for (let i = 0; i < boxes.length; i++) {
  //   boxes[i].sprite.children[0].texture = boxSprites[i].texture
  // }
}

function startMovingBall(ballPath) {
  path.push([...ballPath]);
}

function setupPayoutText(payout) {
  let max = 0
  let scale = 0

  for (let i = 0; i < boxes.length; i++) {
    if (payout[i] || payout[i] === 0) {
      const multiplierText = boxes[i].sprite.children[1]
      multiplierText.text = payout[i] + "x";
      // if (payout[i] == 1000) multiplierText.text = payout[i] / 1000 + "kx"
      if (payout.length > 12) {
        multiplierText.style.fontSize = 20
        //   multiplierText.scale.set(0,1)
      } else {
        multiplierText.style.fontSize = 30
        //  multiplierText.scale.set(0,1)
      }

      if (payout[i] == 1000) multiplierText.style.fontSize = 20

      if (max < multiplierText.width) {
        max = multiplierText.width
      }
    } else console.error("Sent payout is not a valid number");
  }
  for (let i = 0; i < boxes.length; i++) {
    const multiplierText = boxes[i].sprite.children[1]
    const length = countCharacters(multiplierText.text)

    if (boxes.length <= 12) {
      scale = 1.5
      //  console.log("If")
    } else {
      scale = 1.3
      //console.log("else")
    }

    multiplierText.scale.set(scale)

  }
  boxes.forEach((box) => {
    // const maskGraphics = box.sprite.children[0]
    const sprite = box.sprite.children[1]

    // maskGraphics.width = max * scale
    // sprite.width = max * scale
  })
}

function changeNumberOfLines(reqLines) {
  isSetupBoxes = false;
  totalLines = reqLines;

  function destroyBgContianer() {
    pegHolder.destroy({
      children: true,
    });

    // if(TooltipCreated){
    //   containers.UIcontainer.destroy(true)
    //   TooltipCreated = false
    // }

  }

  function createpinsContainer() {
    resetUI();
    //createHover()
    containers.rootContainer.addChildAt(containers.UIcontainer, containers.rootContainer.children.length)
  }

  function resetValues() {
    containers.ballContainer = [];
    pegs = [];
    boxes = [];
    pegsForShine = [];
    pegsInRows = Array.from({
        length: totalLines
      },
      (_, rowIndex) => rowIndex + config.pinInFirstLine
    );
  }
  bgDestroyed = true;
  destroyBgContianer();
  resetValues();
  createpinsContainer();
}

function clearAllBalls() {
  totalBalls = 0;
  containers.ballContainer.forEach((ballContainer) => {
    ballContainer.destroyBall();
  });

  path = [];
}

// function scaleFactor() {
//   return (config.maxLines + 16) / (totalLines + 16);
// }

// function gameScaling() {
//   let rhs = 1.4 - Math.floor((totalLines - 8) / 2) * 0.1;
//   rhs = rhs.toFixed(1);
//   containers.rootContainer.scale.set(scaleFactor() * rhs);
//   console.log("scale="+(scaleFactor() * rhs))
// }

function boxBounceEffect(index) {
  const box = boxes[index];

  if (!box) return;
  box.sprite.y += 40; // box.sprite.startPos;
  box.sprite.boxBounceEffectState = box.sprite.BoxBounceEffect;
}

function lightningModeUI(lightData) {
  // normalModeUI();

  // setupScullBoxes(true);

  // lightData.forEach((pin) => {
  //   let row = pin.position[0] - 1;
  //   let column = pin.position[1] - 1;

  //   let targetPin = gameAssets.pins[row][column];
  //   targetPin.tint = boxColor.pink;
  //   targetPin.children[0].text = pin.multiplier;

  //   targetPin.hilighted = true;
  // });

  // Object.keys(gameAssets.background.left).forEach((asset) => {
  //   gameAssets.background.left[asset].targetPosition.x =
  //     gameAssets.background.left[asset].destination.x;
  //   gameAssets.background.left[asset].targetPosition.y =
  //     gameAssets.background.left[asset].destination.y;
  // });
  // Object.keys(gameAssets.background.right).forEach((asset) => {
  //   gameAssets.background.right[asset].targetPosition.x =
  //     gameAssets.background.right[asset].destination.x;
  //   gameAssets.background.right[asset].targetPosition.y =
  //     gameAssets.background.right[asset].destination.y;
  // });
}

function removeLightningMode() {
  // normalModeUI();
  // setupScullBoxes(false);

  // Object.keys(gameAssets.background.left).forEach((asset) => {
  //   gameAssets.background.left[asset].targetPosition.x =
  //     gameAssets.background.left[asset].initialPosition.x;
  //   gameAssets.background.left[asset].targetPosition.y =
  //     gameAssets.background.left[asset].initialPosition.y;
  // });
  // Object.keys(gameAssets.background.right).forEach((asset) => {
  //   gameAssets.background.right[asset].targetPosition.x =
  //     gameAssets.background.right[asset].initialPosition.x;
  //   gameAssets.background.right[asset].targetPosition.y =
  //     gameAssets.background.right[asset].initialPosition.y;
  // });
}

function normalModeUI() {
  pegs.forEach((p) => {
    p.sprite.tint = boxColor.white;
  });
}

function setupScullBoxes(isScull) {
  // if (gameAssets.boxes[6]) setupScull(6)
  // if (gameAssets.boxes[8]) setupScull(8)
  // if (gameAssets.boxes[10]) setupScull(10)
  // function setupScull(boxIndex) {
  //   // texture
  //   gameAssets.boxes[boxIndex].texture = isScull ?
  //     PIXI.Assets.get('lightning').textures.boxBlack :
  //     PIXI.Assets.get('plinko').textures.box
  //   // tint
  //   //gameAssets.boxes[boxIndex].tint = isScull ? boxColor.white : gameAssets.boxes[boxIndex].boxColor
  //   gameAssets.boxes[boxIndex].tint = "0xFFFFFF"
  //   // text
  //   gameAssets.boxes[boxIndex].children[0].visible = isScull ? false : true
  //   //scalling
  //   if (isScull) {
  //     gameAssets.boxes[boxIndex].scale.set(0.77)
  //   } else {
  //     gameAssets.boxes[boxIndex].scale.set(1.2)
  //   }
  // }
}

function resetUI() {
  createPegsHolder();
  createPegs(config.pegs);
  setupBoxes();
  createBallsPool()
}

export {
  containers,
  createUI,
  uiCreated,
  createBall,
  startMovingBall,
  changeNumberOfLines,
  clearAllBalls,
  setupPayoutText,
  isSetupBoxes,
  boxBounceEffect,
  boolBallCreated,
  totalBalls,
  balls,
  pegs,
  boxes,
  removeLightningMode,
  normalModeUI,
  lightningModeUI,
  gameAssets,
  ballPool,
  pegsForShine,
  totalLines,
  changeMultiplierBackground
};

isPixiStandAlone && divButtons();

function divButtons() {
  const createBallDiv = document.createElement("button");
  createBallDiv.innerHTML = "createBall";
  createBallDiv.onclick = () => createBall();
  document.body.appendChild(createBallDiv);

  const boxBounceDiv = document.createElement("button");
  boxBounceDiv.innerHTML = "boxBounce";
  boxBounceDiv.onclick = () =>
    boxBounceEffect(parseInt(prompt("Enter number of boxBounce")));
  document.body.appendChild(boxBounceDiv);

  const linesDiv = document.createElement("button");
  linesDiv.innerHTML = "lines";
  linesDiv.onclick = () =>
    changeNumberOfLines(parseInt(prompt("Enter number of lines")));
  document.body.appendChild(linesDiv);

  const lightningModeDiv = document.createElement("button");
  lightningModeDiv.innerHTML = "lightningMode";
  lightningModeDiv.onclick = () => pixiLightningMode();
  document.body.appendChild(lightningModeDiv);

  const normalModeDiv = document.createElement("button");
  normalModeDiv.innerHTML = "normalMode";
  normalModeDiv.onclick = () => removeLightningMode();
  document.body.appendChild(normalModeDiv);

  const clearAllBallDiv = document.createElement("button");
  clearAllBallDiv.innerHTML = "clearAllBall";
  clearAllBallDiv.onclick = () => clearAllBalls();
  document.body.appendChild(clearAllBallDiv);

  const ballPathOneDiv = document.createElement("button");
  ballPathOneDiv.innerHTML = "ballPathOne";
  ballPathOneDiv.onclick = () => startMovingBall(testPath);
  document.body.appendChild(ballPathOneDiv);

  const ballPathTwoDiv = document.createElement("button");
  ballPathTwoDiv.innerHTML = "ballPathTwo";
  ballPathTwoDiv.onclick = () => startMovingBall(testPath1);
  document.body.appendChild(ballPathTwoDiv);

  const ballPathThreeDiv = document.createElement("button");
  ballPathThreeDiv.innerHTML = "ballPathThree";
  ballPathThreeDiv.onclick = () => startMovingBall(testPath2);
  document.body.appendChild(ballPathThreeDiv);

  const ballPathFourDiv = document.createElement("button");
  ballPathFourDiv.innerHTML = "ballPathFour";
  ballPathFourDiv.onclick = () => startMovingBall(testPath3);
  document.body.appendChild(ballPathFourDiv);
}
