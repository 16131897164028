const errorMessages = {
  internalServerError: 'InternalServerError',
  unAuthorized: 'Your session has expired. Try to refresh again',
  loginFailedError: 'LoginFailedError',
  invalidCredentialsError: 'InvalidCredentialsError',
  invalidTokenError: 'InvalidTokenError',
  invalidSessionError: 'InvalidSessionError',
  invalidAccessError: 'InvalidAccessError',
  createUserError: 'CreateUserError',
  dataMismatchError: 'DataMismatchError',
  userNotFoundError: 'UserNotFoundError',
  duplicateEmailError: 'DuplicateEmailError',
  requestInputValidationError: 'RequestInputValidationError',
  responseInputValidationError: 'ResponseInputValidationError',
  socketRequestInputValidationError: 'SocketRequestInputValidationError',
  socketResponseValidationError: 'SocketResponseValidationError',
  invalidSocketArgumentError: 'InvalidSocketArgumentError',
  invalidCredentials: 'InvalidCredentials',
  invalidToken: 'InvalidToken',
  invalidSession: 'InvalidSession',
  invalidAccess: 'InvalidAccess',
  nonOperationalError: 'NonOperationalError',
  userNotExists: 'UserNotExists',
  invalidAction: 'InvalidAction',
  sessionAlreadyStarted: 'SessionAlreadyStarted',
  sessionNotStarted: 'SessionNotStarted',
  emailNotVerified: 'EmailNotVerified',
  invalidGameTypeError: 'InvalidGameTypeError',
  invalidGameRoundError: 'InvalidGameRoundError',
  noRoundRunningError: 'NoRoundRunningError',
  noPlacedBetFoundError: 'NoPlacedBetFoundError',
  noWalletFoundErrorr: 'NoWalletFoundErrorr',
  notEnoughBalanceError: 'NotEnoughBalanceError',
  autoRateIsInvalidError: 'AutoRateIsInvalidError',
  betAmountIsNotInLimitError: 'BetAmountIsNotInLimitError',
  emailNotFound: 'EmailNotFound',
  userNotAbove18YearsError: 'UserNotAbove18YearsError',
  invalidBlockchainAddress: 'InvalidBlockchainAddress',
  addressMismatch: 'AddressMismatch',
  nonceLifetimeExpired: 'NonceLifetimeExpired',
  somethingWentWrong: 'SomethingWentWrong',
  accountNotActive: 'AccountNotActive',
  loginTokenRequire: 'LoginTokenRequire',
  userAlreadyExists: 'UserAlreadyExists',
  invalidVerificationToken: 'InvalidVerificationToken',
  userNotActive: 'UserNotActive',
  fileUploadFailed: 'FileUploadFailed',
  emailAlreadyVerified: 'EmailAlreadyVerified',
  invalidReferralCode: 'InvalidReferralCode',
  invalidAffiliateCode: 'InvalidAffiliateCode',
  recordNotFound: 'RecordNotFound',
  withdrawalRequestAlreadyPending: 'WithdrawalRequestAlreadyPending',
  walletDoesNotBelongToUser: 'WalletDoesNotBelongToUser',
  betAlreadyInProgress: 'BetAlreadyInProgress',
  serverSeedNotFoundErrorType: 'ServerSeedNotFoundErrorType',
  invalidTileErrorType: 'InvalidTileErrorType',
  noOpenedTileFoundErrorType: 'NoOpenedTileFoundErrorType',
  favoriteGameExistsErrorType: 'FavoriteGameExistsErrorType',
  favoriteGameNotFoundErrorType: 'FavoriteGameNotFoundErrorType',
  transactionAlreadyPendingErrorType: 'TransactionAlreadyPendingErrorType',
  userHasNoActiveBonusErrorType: 'UserHasNoActiveBonusErrorType',
  bonusExpiredErrorType: 'BonusExpiredErrorType',
  userHasAlreadyActivatedBonusWageringErrorType: 'UserHasAlreadyActivatedBonusWageringErrorType',
  invalidRoundHashErrorType: 'InvalidRoundHashErrorType',
  invalidSignatureErrorType: 'InvalidSignatureErrorType',
  userHasNoBonusAmountToClaimErrorType: 'UserHasNoBonusAmountToClaimErrorType',
  bonusNotExistsErrorType: 'BonusNotExistsErrorType',
  blockedTransactionErrorType: 'BlockedTransactionErrorType'
}

export default errorMessages
