import React from 'react'
import Footer from 'components/layout/Footer/index'
import NewHeader from 'components/layout/Header/index'

const PublicRoute = ({ Component, isHeader, isFooter, child }) => {
  return (
    <>
      {isHeader ? <NewHeader /> : <></>}
      <Component child={child} />
      {isFooter ? <Footer /> : <></>}
    </>
  )
}

export default PublicRoute
