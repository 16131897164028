// NOTE: DO NOT UPDATE THIS RESERVED TRANSLATION KEYS AND THEIR VALUES
const reservedTranslationsWithUniqueKeyNames = {
  leadingSpaceNotAllowed: 'Espace en tête non autorisé.',
  trailingSpaceNotAllowed: 'Espace en queue non autorisé.',
  mustbeAtleastCharacters: '{{label}} doit avoir au moins {{minNumber}} caractères',
  cannotExceedCharacters: '{{label}} ne peut pas dépasser {{maxNumber}} caractères',
  numberMustbeAtleast: '{{label}} doit être au moins égal à {{minNumber}}',
  numberCannotExceed: '{{label}} ne peut pas dépasser {{maxNumber}}',
  isRequired: '{{label}} est requis',
  emptySpaceNotContain: 'Dieses Feld darf kein Leerzeichen enthalten',
  404: '404',
  register: 'S’inscrire',
  login: 'Connexion',
  comingSoon: 'Prochainement',
  submit: 'Soumettre',
  email: 'Email',
  termsConditions: 'Conditions générales',
  password: 'Mot de passe',
  eighteenPlus: 'J’ai plus de 18 ans et j’accepte les conditions d’utilisation',
  signUp: "S'inscrire",
  createNewAccount: 'Créer un nouveau compte',
  fullName: 'Nom complet',
  referralCode: 'Code de parrainage',
  confirmPassword: 'Confirmez le mot de passe',
  facebook: 'Facebook',
  google: 'Google',
  haveAnAccount: 'Vous avez déjà un compte ? ',
  pageNotFound: 'Nous ne pouvons pas trouver la page que vous recherchez !',
  error: 'Erreur',
  somethingWentWrong: 'Quelque chose s’est mal passé !',
  personalDetails: 'Détails personnels',
  firstName: 'Prénom',
  lastName: 'Nom de famille',
  dateOfBirth: 'Date de naissance',
  phoneNumber: 'Numéro de téléphone',
  change: 'Changer',
  saveChanges: 'Enregistrer les modifications',
  currentPassword: 'Ancien mot de passe',
  newPassword: 'Nouveau mot de passe',
  myProfile: 'Mon profil',
  transactions: 'Transactions',
  deposit: 'Dépôt',
  withdraw: 'Retrait',
  referral: 'Parrainage',
  changePassword: 'Changer de mot de passe',
  logout: 'Déconnexion',
  currency: 'Devise.',
  view: 'Voir',
  ok: 'D’accord',
  cancel: 'Annuler',
  passwordRegexMatch: 'Le mot de passe doit contenir une lettre majuscule, un chiffre et un caractère spécial',
  invalidEmailFormat: 'Format d’e-mail invalide',
  userName: 'Nom d’utilisateur',
  noRecordsFound: 'Aucun enregistrement trouvé',
  backToLobby: 'Retour au lobby'
}

// NOTE: PLEASE ADD YOUR API SUCCESS MESSAGE TRANSLATIONS HERE ONLY
const apiSuccessMessagesTranslations = {
  loginSuccessfully: 'Connectez-vous avec succès.',
  logoutSuccessfully: 'Déconnexion réussie.',
  passwordUpdateSuccessfully: 'Mot de passe mis à jour avec succès.',
  updatePlayerStatusSuccessfully: 'Le statut du joueur a été mis à jour avec succès.',
  resetPasswordSuccessfully: 'Mot de passe réinitialisé avec succès.',
  signupSuccessfully: 'Inscription réussie.',
  betPlacedSuccessfully: 'Pari placé avec succès',
  betCancelledSuccessfully: 'Pari annulé avec succès'
}

// NOTE: PLEASE ADD YOUR API ERROR MESSAGE TRANSLATIONS HERE ONLY
const apiErrorMessagesTranslations = {
  LoginFailedError: 'Échec de la connexion',
  InvalidCredentialsError: 'Informations d’identification invalides',
  InvalidTokenError: ' Token non valide',
  InvalidSessionError: ' Session expirée',
  CreateUserError: 'L’utilisateur ne peut pas être créé',
  DataMismatchError: 'Les données ne correspondent pas',
  UserNotFoundError: 'Utilisateur introuvable',
  DuplicateEmailError: 'L’e-mail existe déjà',
  RequestInputValidationError: 'Veuillez vérifier les données de la demande',
  ResponseInputValidationError: 'La validation de la réponse a échoué, veuillez vous référer au schéma JSON de la réponse',
  SocketRequestInputValidationError: 'Veuillez vérifier les données de la demande pour la communication socket',
  SocketResponseValidationError: 'La validation de la réponse de la communication socket a échoué, veuillez vous référer au schéma JSON de la réponse',
  InternalServerError: 'Erreur interne du serveur',
  InvalidSocketArgumentError: 'Veuillez fournir les arguments appropriés : nom de l’événement, [objet de charge], et [rappel]',
  InvalidCredentials: 'Les informations d’identification ne correspondent pas',
  InvalidToken: 'Soit le jeton d’accès n’a pas été transmis, soit il a expiré',
  InvalidSession: 'La session utilisateur n’est pas valide, veuillez vous reconnecter',
  InvalidAccess: 'Accès refusé',
  NonOperationalError: 'Une erreur s’est produite sur le serveur',
  UserNotExists: 'L’utilisateur n’existe pas',
  InvalidAction: 'Action invalide',
  SessionAlreadyStarted: 'La session a déjà été démarrée',
  SessionNotStarted: 'Session non démarrée',
  EmailNotVerified: "L'e-mail n'est pas vérifié",
  InvalidGameTypeError: 'Aucun paramètre trouvé pour le type de jeu fourni',
  InvalidGameRoundError: 'Aucun jeu trouvé pour les détails de jeu fournis',
  NoRoundRunningError: 'Aucun tour en cours pour le moment',
  NoPlacedBetFoundError: 'Aucun pari placé trouvé',
  NoWalletFoundError: 'Portefeuille non trouvé avec les données spécifiées',
  NotEnoughBalanceError: 'Le solde n’est pas suffisant pour effectuer le processus',
  AutoRateIsInvalidError: "Le taux automatique n'est pas dans la plage valide",
  BetAmountIsNotInLimitError: "Le montant du pari n'est pas dans les limites valides",
  EmailNotFound: 'E-mail introuvable',
  UserNotAbove18YearsError: "L'utilisateur n'a pas plus de 18 ans",
  InvalidBlockchainAddress: 'Adresse de blockchain invalide',
  AddressMismatch: 'Incompatibilité d’adresse donnée et d’adresse récupérée',
  NonceLifetimeExpired: 'Durée de vie du nonce expirée',
  SomethingWentWrong: 'Quelque chose s’est mal passé',
  AccountNotActive: "Le compte n'est pas actif",
  LoginTokenRequire: 'Un OTP (mot de passe à usage unique) est requis',
  UserAlreadyExists: "L'utilisateur existe déjà",
  InvalidVerificationToken: 'Token de vérification invalide',
  UserNotActive: 'L’utilisateur est bloqué, veuillez contacter l’administrateur',
  FileUploadFailed: "Échec de l'envoi d'image",
  EmailAlreadyVerified: "L'e-mail est déjà vérifié",
  InvalidReferralCode: 'Code de parrainage invalide',
  InvalidAffiliateCode: 'Code d’affiliation invalide',
  RecordNotFound: 'Enregistrement non trouvé',
  WithdrawalRequestAlreadyPending: 'La demande de retrait est déjà en attente',
  WalletDoesNotBelongToUser: "Le portefeuille n'appartient pas à cet utilisateur",
  BetAlreadyInProgress: 'Le pari est déjà en cours',
  ServerSeedNotFoundErrorType: 'Graine de serveur introuvable, veuillez vous connecter à nouveau',
  InvalidTileErrorType: 'La tuile doit être comprise entre 1 et 25',
  FavoriteGameExistsErrorType: 'Le jeu favori existe déjà',
  FavoriteGameNotFoundErrorType: 'Jeu favori non trouvé',
  TransactionAlreadyPendingErrorType: 'La transaction est déjà en attente',
  UserHasNoActiveBonusErrorType: "L'utilisateur n'a aucun bonus actif",
  BonusExpiredErrorType: 'Le bonus a expiré',
  UserHasAlreadyActivatedBonusWageringErrorType: "L'utilisateur a déjà activé la mise en jeu du bonus",
  InvalidRoundHashErrorType: 'Mauvais hachage de tour',
  InvalidSignatureErrorType: 'Mauvaise signature',
  UserHasNoBonusAmountToClaimErrorType: "L'utilisateur n'a aucun montant de bonus à réclamer",
  BonusNotExistsErrorType: 'Bonus introuvable',
  BlockedTransactionErrorType: 'Erreur de blocage de transaction'
}

// NOTE: PLEASE ADD YOUR OTHER KEY TRANSLATIONS HERE ONLY
const otherTranslationKeys = {
  betAmount: 'Mise',
  betHistory: 'Historique des paris',
  provablyFair: 'Équité vérifiable',
  minimumBet: 'Mise minimale',
  maximumBet: 'Mise maximale',
  selectYourBetAmount: 'Sélectionnez votre montant de mise',
  buyInAmount: 'Montant du rachat',
  selectYourBuyInAmountFromGivenRange: 'Sélectionnez votre montant de rachat dans la plage donnée'
}

/**
 * NOTE:
 * =================================================================================================
 * Please go through this spreading objects first before adding new translation key. So that the
 * duplicate translations will not be created and help us to maintain the consitency of code.
 * If you didn't find any of translation as per your requirement in reserve or api success & error
 * msg translation object, Please add new translation with unique key name in otherTranslationKeys
 * Object. Unique key names help us to avoid overriding of translations.
 * =================================================================================================
 */
export default {
  ...reservedTranslationsWithUniqueKeyNames,
  ...apiSuccessMessagesTranslations,
  ...apiErrorMessagesTranslations,
  ...otherTranslationKeys
}
