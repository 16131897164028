import React from 'react'

const Footer = (props) => {
  return (
    // <!-- FOOTER SECTION START -->
    <footer className='footer-section'>
      {/* <!-- FOOTER TEXT SECTION START --> */}
      <section className='footer-link-section'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-2 col-lg-12'>
              <div className='footer-brand-logo-box'>
                <a className='brand-logo' href='index.html'>
                  <img src='assets/images/logo/logo.png' className='img-fluid mx-auto d-block logo-img' alt='Logo Image' />
                </a>
              </div>
            </div>
            <div className='col-xl-10 col-lg-12'>
              <ul className='footer-link-box'>
                <li className='list-item'>
                  <a href='#' className='link-text'>About Us</a>
                </li>
                <li className='list-item'>
                  <a href='#' className='link-text'>FAQs</a>
                </li>
                <li className='list-item'>
                  <a href='#' className='link-text'>Site Policy</a>
                </li>
                <li className='list-item'>
                  <a href='#' className='link-text'>Responsible Gambling</a>
                </li>
                <li className='list-item'>
                  <a href='#' className='link-text'>Terms and Conditions</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className='footer-text-section'>
        <div className='container'>
          <p className='text'>2022 Forrajes. All rights reserved</p>

        </div>
      </section>

      {/* <!-- FOOTER TEXT SECTION END --> */}
    </footer>
    // <!-- FOOTER SECTION END -->

  )
}

export default Footer
