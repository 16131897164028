// // Comment these three lines in standalone mode
import { appendMyBetsPlinko, setBetLock } from "games/PlinkoGame/slice-thunk/plinkoGame.slice.js"
import store from "redux-store/store.js"
import { countOnes } from "helpers/common.helpers.js"

import { boxBounceEffect, changeMultiplierBackground, changeNumberOfLines, clearAllBalls, createBall, createUI, lightningModeUI, normalModeUI, removeLightningMode, setToolTipArray, setupPayoutText, startMovingBall } from "./gameUI.js"
import { playBGMSound, playLeftDropSound, playOrangeBoxSound, playRedBoxSound, playRightDropSound, playYellowBoxSound } from "./soundManager.js"
import { updateCreditDemoWalletWaitQueue, updateCreditWalletWaitQueue, updateDebitWalletWaitQueue } from "redux-store/redux/slices/auth.slice.js"
import { isPixiStandAlone } from "./settings.js"
import { DEFAULT_PLINKO_LIGHTNING_MODE_BOARD } from "../plinkogame.constants.js"


const ar = [{
  profitAmount: 0,
  cryptoAmount: 0,
  cryptoCurrencyCode: 'BXC',
  chance: 3.10
},
{
  profitAmount: 1,
  cryptoAmount: 1,
  cryptoCurrencyCode: 'BXC',
  chance: 3.11
},
{
  profitAmount: 2,
  cryptoAmount: 2,
  cryptoCurrencyCode: 'BXC',
  chance: 3.12
},
{
  profitAmount: 3,
  cryptoAmount: 3,
  cryptoCurrencyCode: 'BXC',
  chance: 3.13
}
]

export const pixiCreateBall = (lastBall) => createBall(lastBall)

export const pixiStartMovingBall = (ballPath) => {
  const ballPathArray = ballPath.split('').map(ele => +ele)
  startMovingBall(ballPathArray)
}

export const pixiCancelBall = () => clearAllBalls()

export const pixiChangeNumberOfLines = (lines) => changeNumberOfLines(lines)

export const pixiSetPayouts = (payouts) => setupPayoutText(payouts)

export const pixiSetToolTipArray = (arr = []) => {
  setToolTipArray(arr)
}

export const boxNumbers = {
  Red: 1,
  Orange: 2,
}

export const boxColor = {
  yellow: 0xf6c36b,
  orange: 0xe7635e,
  red: 0xe34a5c,
  white: 0xFFFFFF,
  pink: 0xFF629A,
  alpha: 1
}

export const multiplierBoxColor = Object.freeze({
  GREEN: 'green',
  RED: 'red',
  ORANGE: 'orange'
})

export const pixiChangeMultiplierBackground = (color) => changeMultiplierBackground(color)

export const fastModeBox = (dropDetails) => {
  // Comment these lines in standalone mode
  const boxNumber = countOnes(dropDetails)
  boxBounceEffect(boxNumber)
}


export const dispatching = () => {
  // Comment this line in standalone mode
  if (!isPixiStandAlone) {
    dispatchRoundEnd()
  }
  store.dispatch(setBetLock(false))
}

export const dispatchRoundEnd = () => {
  store.dispatch(updateDebitWalletWaitQueue())
  store.dispatch(updateCreditWalletWaitQueue())
  store.dispatch(updateCreditDemoWalletWaitQueue())
  store.dispatch(appendMyBetsPlinko())
}

const data = [
  {
    position: [1, 1],
    multiplier: "3x"
  },
  {
    position: [4, 5],
    multiplier: "7x"
  },
  {
    position: [6, 7],
    multiplier: "12x"
  }
]

export const pixiLightningMode = (
  ballMultipliers = DEFAULT_PLINKO_LIGHTNING_MODE_BOARD.betMultipliers,
  payouts = DEFAULT_PLINKO_LIGHTNING_MODE_BOARD.payouts
) => {
  // lightningModeUI(ballMultipliers)
  // pixiSetPayouts(payouts)
}

export const pixiNormalMode = (payouts) => {
  removeLightningMode()
  pixiSetPayouts(payouts)
}

// Sounds
export const playBGM = () => {
  // Background Music
  playBGMSound()
}
export const playRightDrop = () => {
  playRightDropSound()

}
export const playLeftDrop = () => {
  playLeftDropSound()

}
export const playYellowBox = () => {
  playYellowBoxSound()

}
export const playOrangeBox = () => {
  playOrangeBoxSound()

}
export const playRedBox = () => {
  playRedBoxSound()
}
