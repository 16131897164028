import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'constants/index'
import axiosInstanceService from 'network/apis/index'
import { microServices } from 'network/apis/microservice/index'

export const getUserInfoService = () => {
  return axiosInstanceService(METHOD_TYPES.get, 'user/user-detail', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.table
  })
}

export const getTransactions = (data, endpoint) => {
  return axiosInstanceService(METHOD_TYPES.get, endpoint, data, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.submit,
    params: data

  })
}

export const generateServerSeedService = () => {
  return axiosInstanceService(METHOD_TYPES.post, 'user/generate-server-seed', {}, {
    server: microServices.SERVICE_URL_1
  })
}
