import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  autoBet, cashOutBet, getBetHistory, getLiveStats, getPreviousRoundState, getTopBets, openTile, placeBet
} from '../mine.service'
import { setRoundResult } from './mineGame.slice'
import { BET_RESULT } from 'constants/index'
import { setNewClientSeed, setServerSeedHash } from 'redux-store/redux/slices/gameSettings.slice'
import { generateClientSeed } from 'helpers/common.helpers'
import { openSuccessToaster } from 'helpers/toaster.helpers'

export const getMineGamePreviousRoundState = createAsyncThunk(
  'mineGame/get-previous-round-state', async (_, thunkApi) => {
    try {
      const res = await getPreviousRoundState()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0]?.description)
    }
  }
)

export const getMyBetsMineGame = createAsyncThunk(
  'mineGame/get-my-bets', async (data, thunkApi) => {
    try {
      const res = await getBetHistory(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0]?.description)
    }
  }
)

export const mineGamePlacedBet = createAsyncThunk(
  'mineGame/place-bet', async ({ payload }, thunkApi) => {
    try {
      const newClientSeed = generateClientSeed()
      thunkApi.dispatch(setNewClientSeed(newClientSeed))
      const res = await placeBet({ ...payload, clientSeed: newClientSeed })
      if (res?.nextServerSeedHash) {
        thunkApi.dispatch(setServerSeedHash(res.nextServerSeedHash))
      }
      openSuccessToaster({ message: 'Bet Placed Successfully' })
      return res.bet
    } catch (error) {
      return thunkApi.rejectWithValue(error[0]?.description)
    }
  }
)

export const mineGameAutoBet = createAsyncThunk(
  'mineGame/auto-bet', async ({ payload, playMineTileAudio, playNormalTileAudio }, thunkApi) => {
    try {
      const newClientSeed = generateClientSeed()
      thunkApi.dispatch(setNewClientSeed(newClientSeed))
      const res = await autoBet({ ...payload, clientSeed: newClientSeed })
      if (res?.nextServerSeedHash) {
        thunkApi.dispatch(setServerSeedHash(res.nextServerSeedHash))
      }
      setTimeout(() => {
        if (res.bet?.result === BET_RESULT.LOST) {
          thunkApi.dispatch(setRoundResult({ isLastWin: false, isLastLoss: true }))
        } else {
          thunkApi.dispatch(setRoundResult({ isLastWin: true, isLastLoss: false }))
        }
      }, 2000)
      res.bet?.result === BET_RESULT.WON ? playNormalTileAudio() : playMineTileAudio()
      return res.bet
    } catch (error) {
      return thunkApi.rejectWithValue(error[0]?.description)
    }
  }
)

export const mineGameOpenTile = createAsyncThunk(
  'mineGame/opened-tile', async (payload, thunkApi) => {
    try {
      const { playMineTileAudio, playNormalTileAudio, tile } = payload
      const { minesVolume } = thunkApi?.getState()?.mineGame
      let res = await openTile({ tile })

      res = {
        ...(res?.bet && res.bet),
        mineTile: res.mineTile
      }

      if (res.mineTile) {
        setTimeout(() => {
          thunkApi.dispatch(setRoundResult({ isLastWin: false, isLastLoss: true }))
        }, 2000)
        playMineTileAudio()
      } else {
        playNormalTileAudio()
      }
      res.tile = payload.tile
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0]?.description)
    }
  }
)

export const mineGameCashOut = createAsyncThunk(
  'mineGame/cash-out', async (_, thunkApi) => {
    try {
      const res = await cashOutBet()
      setTimeout(() => {
        thunkApi.dispatch(setRoundResult({ isLastWin: true, isLastLoss: false }))
      }, 2000)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0]?.description)
    }
  }
)

export const minGameTopBets = createAsyncThunk(
  'mineGame/top-bets', async (_, thunkApi) => {
    try {
      const res = await getTopBets()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0]?.description)
    }
  }
)

export const verifyProvableFairMine = createAsyncThunk('crash-game/check-provable-fair', async (payload, thunkApi) => {
  try {
    const res = await verifyProvableFairMine(payload)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const getMineGameLiveStats = createAsyncThunk(
  'mineGame/get-mine-game-live-stats', async (_, thunkApi) => {
    try {
      const res = await getLiveStats()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0]?.description)
    }
  }
)
