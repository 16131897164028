/* ==========================================================================
  Mine Game Constants
========================================================================== */
export const MIN_MINE_COUNT = 1
export const MAX_MINE_COUNT = 24
export const MIN_TILE_COUNT = 1
export const MAX_TILE_COUNT = 25
export const DEFAULT_MINE_COUNT = 3
export const DEFAULT_MINE_GAME_AUTOBET_ROUNDS = 3
export const DEFAULT_MINE_GAME_AUTOBET_MAX_ROUNDS = 50
