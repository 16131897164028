import * as PIXI from "pixi.js"
import {
  app
} from "./appPixi.js";
import {
  dispatching,
  playLeftDrop,
  playOrangeBox,
  playRedBox,
  playRightDrop,
  playYellowBox,
} from "./bridge.js";
import {
  ballPool,
  balls,
  boxes,
  pegsForShine,
  totalBalls,
  totalLines
} from "./gameUI.js";
import {
  config,
  isPixiStandAlone
} from "./settings.js";
import { number } from "../../../../node_modules/yup/lib/locale.js";
import { themes } from "./assetsLoad.js";



function getAspectVariables() {
  const appWidth = app.screen.width;
  const appHeight = app.screen.height;

  const aspectRatio = appWidth / appHeight;

  const aspectRatioX = appWidth / config.originalWidth;
  const aspectRatioY = appHeight / config.originalHeight;
}

function responsiveSettings(sprite) {
  getAspectVariables()
  const xResponsive = (x) => x * aspectRatioX + sprite.width / 2;
  const yResponsive = (y) => y * aspectRatioY + sprite.height / 2;
  sprite.xResp = (x) => xResponsive(x);
  sprite.yResp = (y) => yResponsive(y);

  sprite.positionResponsive = (x, y) =>
    sprite.position.set(xResponsive(x), yResponsive(y));

  const ar = aspectRatioX > aspectRatioY ? aspectRatioY : aspectRatioX;
  sprite.scaleResponsive = (s) => sprite.scale.set(s * ar);
}

export function spriteSettings(sprite, textureName, containerName) {
  sprite.texture = textureName;
  containerName.addChild(sprite);
  sprite.anchor.set(0.5);

  responsiveSettings(sprite);

  return sprite;
}

export function animatedSpriteSettings(sprite, frames, containerName) {
  sprite = new PIXI.AnimatedSprite(frames);
  sprite.textures = frames;
  containerName.addChild(sprite);
  sprite.anchor.set(0.5);
  responsiveSettings(sprite);
  return sprite;
}

// export const getDirection = (testPath, ball1Body) =>
//   testPath[ball1Body.currentRow] > 0
//     ? config.pegForceRight
//     : config.pegForceLeft;

export const updateBallRow = ({
  ball,
  peg
}) => {
  if (ball.currentRow < peg.rowNumber) ball.currentRow = peg.rowNumber;
};

function createGradTexture() {
  // adjust it if somehow you need better quality for very very big images
  const quality = 256;
  const canvas = document.createElement("canvas");

  canvas.width = quality;
  canvas.height = 1;

  const ctx = canvas.getContext("2d");

  // use canvas2d API to create gradient
  const grd = ctx.createLinearGradient(0, 0, quality, 0);

  //  grd.addColorStop(0, 'rgba(255, 255, 255, 0.0)');
  grd.addColorStop(0.9, "rgb(227,73,92)");
  grd.addColorStop(0.7, "rgb(235,130,97)");
  grd.addColorStop(0.5, "rgb(246,195,106)");
  grd.addColorStop(0.3, "rgb(235,130,97)");
  grd.addColorStop(0.1, "rgb(227,73,92)");

  ctx.fillStyle = grd;
  ctx.fillRect(0, 0, quality, 1);

  return PIXI.Texture.from(canvas);
}

export function splitGradientTexture(numSprites) {
  const sprites = [];
  for (let i = 0; i < numSprites; i++) {
    const texture = createGradTexture();
    const spriteWidth = texture.width / numSprites;
    const spriteHeight = texture.height;

    const sprite = new PIXI.Sprite(texture);
    sprite.texture.frame = new PIXI.RoundedRectangle(
      i * spriteWidth,
      0,
      spriteWidth,
      spriteHeight,
      80,
      100
    );
    sprites.push(sprite);
  }
  return sprites;
}

let xnum=0
export const createMultiplierBoxes = (texture, numOfBoxes, colorDistribution = config.multiplierBoxesTints) => {
  // colorDistribution defines the same color tints in number of boxes defined in the object
  // for e.g [3, 2, 1, 2, 3], starting 3 and end 3 boxes will be of same color and so on..

  const tints = config.colors[themes.currentTheme].boxTint
  const multiplierSpriteBoxes = []
  let tintIndex = 0

  let incNum=0
  let multi = 0

  xnum = 0


    for (let j = 0; j < numOfBoxes; j++) {
      const sprite = new PIXI.Sprite(texture)
      sprite.anchor.set(0.1)
      multiplierSpriteBoxes.push(sprite)
    }


    function accessArrayFromMiddle( limit) {


      const middleIndex = parseInt(tints.length / 2);

      const startIndex = Math.max(middleIndex - limit, 0);
      const endIndex = Math.min(middleIndex + limit, tints.length - 1);

      for (let i = startIndex; i < startIndex+numOfBoxes; i++) {
       if((numOfBoxes%2)==0)
        {
        multiplierSpriteBoxes[xnum].tint = tints[i]
          xnum++
        }
        else{
          if(i>=middleIndex)
           { multiplierSpriteBoxes[xnum].tint = tints[i]}
          else
            {multiplierSpriteBoxes[xnum].tint = tints[i-1]}

          xnum++
            }
      }
  }

    accessArrayFromMiddle(parseInt(numOfBoxes/2));







  return multiplierSpriteBoxes
}

export class ObjectPool {
  constructor(objectFactory, initialSize) {
    this.objectFactory = objectFactory;
    this.pool = [];

    // Create initial objects and add them to the pool
    for (let i = 0; i < initialSize; i++) {
      this.pool.push(this.objectFactory());
    }
  }

  // Get an object from the pool
  getObject() {
    if (this.pool.length > 0) {
      return this.pool.pop();
    } else {
      // If the pool is empty, create a new object
      return this.objectFactory();
    }
  }

  // Return an object to the pool
  releaseObject(object) {
    this.pool.push(object);
  }
}

export function countCharacters(str) {
  let charCount = 0;
  for (let i = 0; i < str.length; i++) {
    if (str[i] !== ".") {
      charCount++;
    }
  }
  return charCount;
}
export const getDirection = (testPath, index) => (testPath[index] < 1 ? -1 : 1);

export class AnimationSprite {
  constructor(pegHolder, spriteY) {
    this.animationSprite = new PIXI.AnimatedSprite(
      PIXI.Assets.get("plinkoAnim").animations.frames
    );
    this.sprite = new PIXI.Sprite(PIXI.Assets.get("betCoin"));
    this.sprite.visible = false;

    this.animationSprite.visible = false;
    this.spriteY = spriteY;
    pegHolder.addChild(this.animationSprite);
    this.animationSprite.anchor.set(0.5);
    this.animationSprite.x = 0;
    this.animationSprite.y = spriteY - 150;
    this.animationSprite.animationSpeed = config.ball.animationSpeed;
    this.bounceRow = 0;
    this.bounceCol = 1;
    // this.animationSprite.play();
  }
  doNothing = (delta) => {}
  ballEnd = (delta) => {
    if (this.sprite.visible && boxes[0].sprite.y + boxes[0].width * 0.5 < this.sprite.y) {
      this.sprite.visible = false;
      this.animationSprite.drop = this.doNothing;
    }
  };
  moveBall = (delta) => {
    if (this.animationSprite.y <= this.spriteY - 10) {
      this.animationSprite.y += delta * 16;
    } else {
      this.animationSprite.y = this.spriteY - 10;
      this.animationSprite.play();
      this.animationSprite.drop = this.ballEnd;
    }
  };
  onLoop = function (testPathAnim) {
    this.animationSprite.animationSpeed = config.ball.animationSpeed;
    let scaleDir = getDirection(testPathAnim, this.bounceRow + 1) ;
    this.animationSprite.scale.set(
      scaleDir * config.ball.scaleForAnimation[totalLines - 8],
      config.ball.scaleForAnimation[totalLines - 8]
    );
    this.sprite.scale.set(
      scaleDir * config.ball.scaleForAnimation[totalLines - 8],
      config.ball.scaleForAnimation[totalLines - 8]
    );

    let distanceDir = getDirection(testPathAnim, this.bounceRow);
    this.animationSprite.x +=
      config.pegs.spacing.width *
      0.5 *
      config.ball.scaleForAnimation[totalLines - 8] *
      distanceDir;
    this.animationSprite.y +=
      config.pegs.spacing.height *
      config.ball.scaleForAnimation[totalLines - 8];
    this.sprite.y = this.animationSprite.y;

    this.bounceCol += testPathAnim[this.bounceRow] < 1 ? 0 : 1;
    this.bounceRow++;
    if (this.bounceRow < pegsForShine.length) {
      pegsForShine[this.bounceRow][this.bounceCol].startShine();
      distanceDir < 0 ? playLeftDrop() : playRightDrop();
    }

    if (this.bounceRow + 1 > totalLines) {
      const index = balls.indexOf(this.animationSprite);
      if (index > -1) balls.splice(index, 1);
      // this.animationSprite.destroy({
      //   children: true,
      // });
      this.bounceCol += testPathAnim[this.bounceRow] < 1 ? -1 : 0;
      boxes[this.bounceCol - 1].sprite.position.y = config.topMargin + 40;
      boxes[this.bounceCol - 1].sprite.boxState =
        boxes[this.bounceCol - 1].sprite.boxBounceEffectState;
      playSoundForBoxDrop(this.bounceCol - 1, totalLines + 1);
      this.releaseSpriteSetup();
      return;
    }
  };

  setVisiblity = (visible) => {
    this.animationSprite.visible = visible;
  };
  releaseSpriteSetup = () => {
    this.animationSprite.visible = false;
    this.sprite.x = this.animationSprite.x;
    this.sprite.anchor.set(0.5);
    this.sprite.visible = true;
    this.animationSprite.stop();
    this.sprite.visible = false;
    this.animationSprite.anchor.set(0.5);
    this.animationSprite.x = 0;
    this.animationSprite.y = this.spriteY - 150;
    this.animationSprite.animationSpeed = config.ball.animationSpeed;
    this.bounceRow = 0;
    this.bounceCol = 1;
    ballPool.releaseObject(this);
    if (this.animationSprite?.lastBall) {
      dispatching();
    }
  };
}

function playSoundForBoxDrop(index, totalBox) {
  if (index <= totalBox * 0.1 || index >= totalBox * 0.9) {
    playRedBox();
  } else if (index <= totalBox * 0.3 || index >= totalBox * 0.7) {
    playOrangeBox();
  } else {
    playYellowBox();
  }
}
