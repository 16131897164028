// NOTE: DO NOT UPDATE THIS RESERVED TRANSLATION KEYS AND THEIR VALUES
const reservedTranslationsWithUniqueKeyNames = {
  leadingSpaceNotAllowed: 'Führende Leerzeichen sind nicht erlaubt.',
  trailingSpaceNotAllowed: 'Abschließende Leerzeichen sind nicht erlaubt.',
  mustbeAtleastCharacters: '{{label}} muss mindestens {{minNumber}} Zeichen lang sein',
  cannotExceedCharacters: '{{label}} darf nicht mehr als {{maxNumber}} Zeichen haben',
  numberMustbeAtleast: '{{label}} muss mindestens den Wert {{minNumber}} haben',
  numberCannotExceed: '{{label}} darf nicht mehr als {{maxNumber}} betragen',
  isRequired: '{{label}} ist erforderlich',
  emptySpaceNotContain: 'Dieses Feld darf kein Leerzeichen enthalten',
  404: '404',
  register: 'Registrieren',
  login: 'Anmelden',
  comingSoon: 'Demnächst verfügbar',
  submit: 'Senden',
  email: 'E-Mail',
  termsConditions: 'Nutzungsbedingungen',
  password: 'Passwort',
  eighteenPlus: 'Ich bin über 18 Jahre alt und akzeptiere die Nutzungsbedingungen',
  signUp: 'Registrieren',
  createNewAccount: 'Neues Konto erstellen',
  fullName: 'Vollständiger Name',
  referralCode: 'Empfehlungscode',
  confirmPassword: 'Passwort bestätigen',
  facebook: ' Facebook',
  google: 'Google',
  haveAnAccount: 'Haben Sie bereits ein Konto?',
  pageNotFound: 'Wir können die von Ihnen gesuchte Seite nicht finden!',
  error: 'Fehler',
  somethingWentWrong: 'Etwas ist schiefgelaufen!',
  personalDetails: 'Persönliche Details',
  firstName: 'Vorname',
  lastName: 'Nachname',
  dateOfBirth: 'Geburtsdatum',
  phoneNumber: 'Telefonnummer',
  change: 'Ändern',
  saveChanges: 'Änderungen speichern',
  currentPassword: 'Aktuelles Passwort',
  newPassword: 'Neues Passwort',
  myProfile: 'Mein Profil',
  transactions: 'Transaktionen',
  deposit: 'Einzahlung',
  withdraw: 'Abheben',
  referral: 'Empfehlung',
  changePassword: 'Passwort ändern',
  logout: 'Abmelden',
  currency: ' Währung.',
  view: 'Ansehen',
  ok: 'OK',
  cancel: 'Abbrechen',
  passwordRegexMatch: 'Das Passwort muss einen Großbuchstaben, eine Zahl und ein Sonderzeichen enthalten',
  invalidEmailFormat: 'Ungültiges E-Mail-Format',
  userName: 'Benutzername',
  noRecordsFound: 'Keine Datensätze gefunden',
  backToLobby: 'Zurück zur Lobby'
}

// NOTE: PLEASE ADD YOUR API SUCCESS MESSAGE TRANSLATIONS HERE ONLY
const apiSuccessMessagesTranslations = {
  loginSuccessfully: 'Anmeldung erfolgreich.',
  logoutSuccessfully: 'Abmelden erfolgreich.',
  passwordUpdateSuccessfully: 'Passwort erfolgreich aktualisiert.',
  updatePlayerStatusSuccessfully: 'Spielerstatus erfolgreich aktualisiert.',
  resetPasswordSuccessfully: 'Passwort erfolgreich zurückgesetzt.',
  signupSuccessfully: 'Anmeldung erfolgreich.',
  betPlacedSuccessfully: 'Wette erfolgreich platziert',
  betCancelledSuccessfully: 'Wette erfolgreich abgebrochen'
}

// NOTE: PLEASE ADD YOUR API ERROR MESSAGE TRANSLATIONS HERE ONLY
const apiErrorMessagesTranslations = {
  LoginFailedError: 'Anmeldung fehlgeschlagen',
  InvalidCredentialsError: 'Ungültige Anmeldeinformationen',
  InvalidTokenError: ' Token ist ungültig',
  InvalidSessionError: ' Sitzung abgelaufen',
  CreateUserError: 'Benutzer kann nicht erstellt werden',
  DataMismatchError: 'Daten stimmen nicht überein',
  UserNotFoundError: 'Benutzer nicht gefunden',
  DuplicateEmailError: 'E-Mail existiert bereits',
  RequestInputValidationError: 'Bitte überprüfen Sie die Anforderungsdaten',
  ResponseInputValidationError: 'Validierung der Antwort fehlgeschlagen. Bitte beziehen Sie sich auf das JSON-Schema der Antwort',
  SocketRequestInputValidationError: 'Bitte überprüfen Sie die Anforderungsdaten für die Socket-Kommunikation',
  SocketResponseValidationError: 'Validierung der Socket-Kommunikationsantwort fehlgeschlagen. Bitte beziehen Sie sich auf das JSON-Schema der Antwort',
  InternalServerError: 'Interner Serverfehler',
  InvalidSocketArgumentError: 'Bitte geben Sie ordnungsgemäße Argumente an: Ereignisname, [Payload-Objekt] und [Callback]',
  InvalidCredentials: 'Anmeldeinformationen stimmen nicht überein',
  InvalidToken: 'Entweder wurde kein Zugriffstoken übergeben oder es ist abgelaufen',
  InvalidSession: 'Benutzersitzung ist ungültig. Bitte erneut anmelden',
  InvalidAccess: 'Zugriff verweigert',
  NonOperationalError: 'Ein Fehler ist auf dem Server aufgetreten',
  UserNotExists: 'Benutzer existiert nicht',
  InvalidAction: 'Ungültige Aktion',
  SessionAlreadyStarted: 'Sitzung bereits gestartet',
  SessionNotStarted: 'Sitzung nicht gestartet',
  EmailNotVerified: 'E-Mail nicht verifiziert',
  InvalidGameTypeError: 'Keine Einstellungen für den angegebenen Spieltyp gefunden',
  InvalidGameRoundError: 'Kein Spiel für die angegebenen Spielinformationen gefunden',
  NoRoundRunningError: 'Derzeit läuft keine Runde',
  NoPlacedBetFoundError: 'Keine platzierte Wette gefunden',
  NoWalletFoundError: 'Wallet mit den angegebenen Daten nicht gefunden',
  NotEnoughBalanceError: 'Das Guthaben reicht nicht aus, um den Vorgang durchzuführen',
  AutoRateIsInvalidError: 'Die automatische Rate liegt nicht im gültigen Bereich',
  BetAmountIsNotInLimitError: 'Der Wetteinsatz liegt nicht innerhalb der gültigen Grenzen',
  EmailNotFound: 'E-Mail nicht gefunden',
  UserNotAbove18YearsError: 'Benutzer ist nicht älter als 18 Jahre',
  InvalidBlockchainAddress: 'Ungültige Blockchain-Adresse',
  AddressMismatch: 'Fehlerhafte Adresse und wiederhergestellte Adresse stimmen nicht überein',
  NonceLifetimeExpired: 'Lebensdauer des Nonce abgelaufen',
  SomethingWentWrong: 'Etwas ist schiefgelaufen',
  AccountNotActive: 'Konto ist nicht aktiv',
  LoginTokenRequire: 'OTP (One-Time Password) ist erforderlich',
  UserAlreadyExists: 'Benutzer existiert bereits',
  InvalidVerificationToken: 'Ungültiger Verifizierungstoken',
  UserNotActive: 'Benutzer ist blockiert, bitte kontaktieren Sie den Administrator',
  FileUploadFailed: 'Bildupload fehlgeschlagen',
  EmailAlreadyVerified: 'E-Mail ist bereits verifiziert',
  InvalidReferralCode: 'Ungültiger Empfehlungscode',
  InvalidAffiliateCode: 'Ungültiger Partnercode',
  RecordNotFound: 'Datensatz nicht gefunden',
  WithdrawalRequestAlreadyPending: 'Abhebeantrag ist bereits ausstehend',
  WalletDoesNotBelongToUser: 'Wallet gehört nicht zu diesem Benutzer',
  BetAlreadyInProgress: 'Wette läuft bereits',
  ServerSeedNotFoundErrorType: 'Server-Samen nicht gefunden, bitte erneut anmelden',
  InvalidTileErrorType: 'Kachel muss im Bereich von 1 bis 25 liegen',
  NoOpenedTileFoundErrorType: 'Keine geöffnete Kachel für diese Wette gefunden',
  FavoriteGameExistsErrorType: 'Das Lieblingsspiel existiert bereits',
  FavoriteGameNotFoundErrorType: 'Das Lieblingsspiel wurde nicht gefunden',
  TransactionAlreadyPendingErrorType: 'Transaktion ist bereits ausstehend',
  UserHasNoActiveBonusErrorType: 'Der Benutzer hat keinen aktiven Bonus',
  BonusExpiredErrorType: 'Der Bonus ist abgelaufen',
  UserHasAlreadyActivatedBonusWageringErrorType: 'Der Benutzer hat bereits die Bonuswette aktiviert',
  InvalidRoundHashErrorType: 'Falscher Rundenhash',
  InvalidSignatureErrorType: 'Falsche Signatur',
  UserHasNoBonusAmountToClaimErrorType: 'Der Benutzer hat keinen Bonusbetrag, der beansprucht werden kann',
  BonusNotExistsErrorType: 'Bonus nicht gefunden',
  BlockedTransactionErrorType: 'Transaktionsblockfehler'
}

// NOTE: PLEASE ADD YOUR OTHER KEY TRANSLATIONS HERE ONLY
const otherTranslationKeys = {
  betAmount: 'Wetteinsatz',
  betHistory: 'Wettverlauf',
  provablyFair: 'Nachweislich fair',
  minimumBet: 'Mindesteinsatz',
  maximumBet: 'Höchsteinsatz',
  selectYourBetAmount: 'Wählen Sie Ihren Wetteinsatz',
  buyInAmount: 'Buy-In-Betrag',
  selectYourBuyInAmountFromGivenRange: 'Wählen Sie Ihren Buy-In-Betrag aus dem angegebenen Bereich'
}

/**
 * NOTE:
 * =================================================================================================
 * Please go through this spreading objects first before adding new translation key. So that the
 * duplicate translations will not be created and help us to maintain the consitency of code.
 * If you didn't find any of translation as per your requirement in reserve or api success & error
 * msg translation object, Please add new translation with unique key name in otherTranslationKeys
 * Object. Unique key names help us to avoid overriding of translations.
 * =================================================================================================
 */
export default {
  ...reservedTranslationsWithUniqueKeyNames,
  ...apiSuccessMessagesTranslations,
  ...apiErrorMessagesTranslations,
  ...otherTranslationKeys
}
