import Routes from 'routes/index'
import React, { Suspense, useEffect } from 'react'
import Loader from 'components/ui-kit/Loader/index'
import { removeAuthTokenFromCookie } from 'helpers/cookie.helpers'
import { ThemeProvider } from 'ThemeContext'
import ThemeSwitcher from 'components/ui-kit/ThemeSwitcher/ThemeSwitcher'

function App () {
  useEffect(() => {
    removeAuthTokenFromCookie()
  }, [])

  /**
   * ThemeSwitcher component is placed here temporarily,
   * Only uncomment it when you manually want to switch between different themes.
   * ONLY USE FOR TESTING PURPOSE, Also checkout ThemeContext.js file
   */
  return (
    <ThemeProvider>
      {/* <ThemeSwitcher /> */}
    <Suspense fallback={<Loader />}>
      <Routes />
    </Suspense>
    </ThemeProvider>
  )
}

export default App
