import React from 'react';
import { useTheme } from '../../../ThemeContext';


const ThemeSwitcher = () => {
  const { theme, toggleTheme } = useTheme();

  const handleThemeChange = (event) => {
      toggleTheme(event.target.value);
  };

  return (
      <div>
          <select onChange={handleThemeChange} value={theme}>
              <option value="default">Default</option>
              <option value="">TG Casino</option>
              <option value="dark-theme">Lucky Block</option>
              <option value="light-theme">WSM</option>
          </select>
      </div>
  );
};

export default ThemeSwitcher;
