export const RequestInputValidationErrorType = {
  name: 'RequestInputValidationError',
  description: 'Please check the request data',
}

export const ResponseValidationErrorType = {
  name: 'ResponseInputValidationError',
  description: 'Response validation failed please refer json schema of response',
}

export const SocketRequestInputValidationErrorType = {
  name: 'SocketRequestInputValidationError',
  description: 'Please check the request data',
}

export const SocketResponseValidationErrorType = {
  name: 'SocketResponseValidationError',
  description: 'Response validation of socket failed please refer json schema of response',
}

export const InternalServerErrorType = {
  name: 'InternalServerError',
  description: 'Internal Server Error',
}

export const InvalidSocketArgumentErrorType = {
  name: 'InvalidSocketArgumentError',
  description: 'Please provide, proper arguments eventName, [payloadObject], and [callback]',
}

export const InvalidCredentialsErrorType = {
  name: 'InvalidCredentials',
  description: 'Credentials does not match',
}

export const InvalidTokenErrorType = {
  name: 'InvalidToken',
  description: 'Either access token not passed or it is expired',
}

export const UserNotExistsErrorType = {
  name: 'UserNotExists',
  description: 'User does not exists',
}

export const InvalidCurrencyCodeErrorType = {
  name: 'InvalidCurrencyCodeError',
  description: 'Currency code is not valid or it is not supported please check it',
}

export const OperatorPlayerUnAuthenticatedErrorType = {
  name: 'OperatorPlayerUnAuthenticated',
  description: 'Operator player Unauthenticated',
}

export const InvalidGameTypeErrorType = {
  name: 'InvalidGameTypeError',
  description: 'No settings found for the provided game type',
}

export const InvalidGameRoundErrorType = {
  name: 'InvalidGameRoundError',
  description: 'No game found for the provided game details',
}

export const NoRoundRunningErrorType = {
  name: 'NoRoundRunningError',
  description: 'No round is running as of now',
}

export const NoPlacedBetFoundErrorType = {
  name: 'NoPlacedBetFoundError',
  description: 'No placed bet found',
}

export const NoUserFoundErrorType = {
  name: 'NoUserFoundError',
  description: 'User not found with the specified data',
}

export const NotEnoughBalanceErrorType = {
  name: 'NotEnoughBalanceError',
  description: 'Balance is not to perform the process',
}

export const AutoRateIsInvalidErrorType = {
  name: 'AutoRateIsInvalidError',
  description: 'Auto Rate is not in the limit',
}

export const BetAmountIsNotInLimitErrorType = {
  name: 'BetAmountIsNotInLimitError',
  description: 'Bet Amount is not in the limits',
}

export const UserNotAbove18YearsErrorType = {
  name: 'UserNotAbove18YearsError',
  description: 'UserNotAbove18YearsError',
}

export const SomethingWentWrongErrorType = {
  name: 'SomethingWentWrong',
  description: 'Something Went Wrong',
}

export const InvalidCredentialErrorType = {
  name: 'InvalidCredentialError',
  description: 'Please provide valid credentials',
}

export const InvalidAmountErrorType = {
  name: 'InvalidAmountError',
  description: 'Amount is less than 0',
}

export const InvalidDebitTransactionErrorType = {
  name: 'InvalidDebitTransactionError',
  description: 'Debit Transaction not found',
}

export const InvalidTransactionErrorType = {
  name: 'InvalidTransactionError',
  description: 'Transaction already processed',
}

export const InsufficientFundsErrorType = {
  name: 'InsufficientFundsError',
  description: 'Insufficient funds',
}

export const BlockedTransactionErrorType = {
  name: 'BlockedTransactionError',
  description: 'Please contact website operator',
}

export const InvalidOperatorIdErrorType = {
  name: 'InvalidOperatorIdError',
  description: 'Operator Id is invalid',
}

export const InvalidOperatorResponseErrorType = {
  name: 'InvalidOperatorResponseError',
  description: 'Invalid Operator Response Error',
  status: 4
}

export const TransactionAlreadyProcessedErrorType = {
  name: 'TransactionAlreadyProcessedError',
  description: 'Transaction already processed',
}

export const BetLimitReachedErrorType = {
  name: 'BetLimitReachedError',
  description: 'Bet Limit Reached',
}

export const LossLimitReachedErrorType = {
  name: 'LossLimitReachedError',
  description: 'Loss Limit Reached',
}

export const ProfitLimitReachedErrorType = {
  name: 'ProfitLimitReachedError',
  description: 'Profit Limit Reached',
}

export const SessionLimitReachedErrorType = {
  name: 'SessionLimitReachedError',
  description: 'Session Limit Reached',
}

export const TotalBetAmountInvalidErrorType = {
  name: 'TotalBetAmountInvalidError',
  description: 'Invalid bet amount and total bet amount',
}

export const InvalidNumberOfBallsErrorType = {
  name: 'InvalidNumberOfBallsError',
  description: 'Invalid number of balls, should be in [1, 10]',
}
