import * as PIXI from "pixi.js"
import { config } from "./settings";

export const app = new PIXI.Application({
  width: config.originalWidth,
  height: config.originalHeight,
  backgroundColor: 0xFFFFFF,
  antialias: true,
  resolution: 2, //window.devicePixelRatio,
  backgroundAlpha: 0,
});
// console.log(PIXI.utils.sayHello)
// console.log(PIXI.utils.skipHello)
// console.log(PIXI.settings.RENDER_OPTIONS.hello)
PIXI.settings.RENDER_OPTIONS.hello = false

// globalThis.__PIXI_APP__ = app;

function appTransform () {
  // app.view.style.width = x + "px";
  // app.view.style.height = y + "px";


  // app.screen.width = gameContainer.offsetWidth
  // app.screen.height = gameContainer.offsetHeight
}

// progressBar.position.set(
//   app.screen.width / 2,
//   app.screen.height / 2
// )
// app.stage.addChild(progressBar)
appTransform();
