import { createSlice } from '@reduxjs/toolkit'
import {
  escapeCrashBet, getAllRoundHistory, getCrashCurrentPlacedBet, getMyBets, getMyBetsPaginated, getPreviousRoundBets, getTopBets,
  placedBetCrashGame, verifyProvableFairCrash, getCrashAllPlacedBetInRound
} from 'games/CrashGame/slice-thunk/crashGame.thunk'
import { APPEND_TYPES, DEFAULT_MY_BETS_LIMIT } from '../crashgame.constants'

const initialState = {
  roundBetResult: null,
  betHistoryData: [],
  resultData: [],
  currentRoundId: '',
  placingOrCashOutLoading: [false, false],
  placedCrashBetId: [null, null],
  currentPlacedBet: [null, null],
  allRoundHistoryData: [],
  allRoundLoading: false,
  myBetsData: {
    count: 0,
    rows: []
  },
  myBetsDataPaginated: {
    count: 0,
    rows: []
  },
  wins: 0,
  losses: 0,
  profit: 0,
  wagered: 0,
  myBetsDataPaginatedLoading: false,
  myBetsLoading: false,
  topBetsData: [],
  topBetsLoading: false,
  playerStats: null,
  chooseCharacterOpen: true,
  provableFairData: null,
  previousBetsData: [],
  liveStatsData: {
    wins: 0,
    wagered: 0.00,
    profit: 0.00,
    losses: 0
  },
  /**
   * This field is being used to store all placed bets in the current round, after user refreshes the page
   * so that it can be displayed in the LeaderBoards section, below the BetSection
   */
  allPlacedBetsInCurrentRound: null
}

const {
  actions: {
    setPlacedBetsData,
    setResultData,
    setCurrentRoundId,
    setPlacedCrashBetId,
    setChooseCharacterOpen,
    setProvableFairData,
    appendCurrentBetsToMyBets,
    setPlacingOrCashoutLoading,
    setCurrentPlacedBet,
    setCrashLossCount,
    setCrashWinsCount,
    setCrashProfit,
    setCrashWagered,
    resetLiveStatsData,
    setLiveStatsData
  },
  reducer
} = createSlice({
  name: 'crashGame',
  initialState,
  reducers: {
    setCrashWinsCount: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          wins: action.payload.reset
        }
      }
      return {
        ...state,
        wins: state.wins + 1
      }
    },
    setCrashLossCount: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          losses: action.payload.reset
        }
      }
      return {
        ...state,
        losses: state.losses + 1
      }
    },
    setCrashProfit: (state, action) => {
      return {
        ...state,
        profit: action.payload
      }
    },
    setCrashWagered: (state, action) => {
      return {
        ...state,
        wagered: action.payload
      }
    },
    resetLiveStatsData: (state, action) => {
      return {
        ...state,
        liveStatsData: {
          ...state.liveStatsData,
          wins: 0,
          wagered: 0.00,
          profit: 0.00,
          losses: 0
        }
      }
    },
    setLiveStatsData: (state, action) => {
      const liveData = { ...state.liveStatsData }
      const { betAmount, winningAmount, result } = action.payload || {}
      if (!result || (result !== 'won' && result !== 'lost')) {
        return // Handle invalid result values
      }

      if (result === 'won') {
        liveData.wins = (liveData.wins || 0) + 1
      } else if (result === 'lost') {
        liveData.losses = (liveData.losses || 0) + 1
      }

      if (!isNaN(parseFloat(betAmount))) {
        liveData.wagered = (parseFloat(liveData.wagered || '0') + parseFloat(betAmount)).toFixed(2)
      }

      if (!isNaN(parseFloat(winningAmount)) && !isNaN(parseFloat(betAmount)) && result !== 'push') {
        const profitDelta = (parseFloat(winningAmount) - parseFloat(betAmount)).toFixed(2)
        liveData.profit = (parseFloat(liveData.profit || '0') + parseFloat(profitDelta)).toFixed(2)
      }
      return ({
        ...state,
        liveStatsData: liveData
      })
    },
    setPlacingOrCashoutLoading: (state, action) => {
      if (action.payload?.index !== undefined && action.payload?.value !== undefined) {
        const index = action.payload?.index ?? 0
        const _loading = [...state.placingOrCashOutLoading]
        _loading[index] = action.payload.value
        return {
          ...state,
          placingOrCashOutLoading: _loading
        }
      }
      return state
    },
    setPlacedBetsData: (state, action) => {
      return {
        ...state,
        betHistoryData: action.payload
      }
    },
    setResultData: (state, action) => {
      return {
        ...state,
        resultData: action.payload
      }
    },
    setCurrentRoundId: (state, action) => {
      return {
        ...state,
        currentRoundId: action.payload
      }
    },
    setChooseCharacterOpen: (state, action) => {
      return {
        ...state,
        chooseCharacterOpen: action.payload
      }
    },
    setProvableFairData: (state, action) => {
      return {
        ...state,
        provableFairData: action.payload
      }
    },
    setCurrentPlacedBet: (state, action) => {
      if (!action.payload?.reset) {
        const { id, betDetails } = action.payload
        const newPlacedCrashBetId = [...state.currentPlacedBet]
        newPlacedCrashBetId[id] = betDetails
        return {
          ...state,
          currentPlacedBet: newPlacedCrashBetId
        }
      } else {
        return {
          ...state,
          currentPlacedBet: initialState.currentPlacedBet
        }
      }
    },
    setPlacedCrashBetId: (state, action) => {
      if (!action.payload?.reset) {
        const { id, betId } = action.payload
        const newPlacedCrashBetId = [...state.placedCrashBetId]
        newPlacedCrashBetId[id] = betId
        return {
          ...state,
          placedCrashBetId: newPlacedCrashBetId
        }
      } else {
        return {
          ...state,
          placedCrashBetId: initialState.placedCrashBetId,
          allPlacedBetsInCurrentRound: null,
        }
      }
    },
    appendCurrentBetsToMyBets: (state, action) => {
      if (action.payload?.type === APPEND_TYPES.ESCAPE) {
        const escapedBet = action.payload.data
        const betExist = state.myBetsData?.rows?.some(bet => bet?.id === escapedBet?.[0]?.id)
        if (!betExist) {
          return {
            ...state,
            myBetsData: {
              count: state.myBetsData.count + (escapedBet?.length ?? 0),
              rows: [...escapedBet, ...state.myBetsData?.rows].slice(0, DEFAULT_MY_BETS_LIMIT)
            }
          }
        }
      } else if (action.payload?.type === APPEND_TYPES.ROUND_STOPPED) {
        const userId = action.payload?.userId
        if (userId) {
          const alreadyPresentBetIds = state.myBetsData?.rows.map(bet => +bet.id)
          const currentPlacedBets = [...state.betHistoryData].filter(bet => (+bet?.userId === +userId && !alreadyPresentBetIds.includes(+bet.id)))
          return {
            ...state,
            myBetsData: {
              count: state.myBetsData.count + (currentPlacedBets?.length ?? 0),
              rows: [...currentPlacedBets, ...state.myBetsData?.rows].slice(0, DEFAULT_MY_BETS_LIMIT)
            }
          }
        }
      }
      return state
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCrashCurrentPlacedBet.pending, (state, action) => {
        return {
          ...state
        }
      })
      .addCase(getCrashCurrentPlacedBet.rejected, (state, action) => {
        return {
          ...state
        }
      })
      .addCase(getCrashCurrentPlacedBet.fulfilled, (state, action) => {

        return {
          ...state
        }
      })
      .addCase(getCrashAllPlacedBetInRound.pending, (state, action) => {
        return {
          ...state
        }
      })
      .addCase(getCrashAllPlacedBetInRound.rejected, (state, action) => {
        return {
          ...state,
          allPlacedBetsInCurrentRound: null
        }
      })
      .addCase(getCrashAllPlacedBetInRound.fulfilled, (state, action) => {
        return {
          ...state,
          allPlacedBetsInCurrentRound: action.payload?.bets || null
        }
      })
      .addCase(placedBetCrashGame.pending, (state, action) => {
        return {
          ...state
        }
      })
      .addCase(placedBetCrashGame.rejected, (state, action) => {
        return {
          ...state
        }
      })
      .addCase(escapeCrashBet.rejected, (state, action) => ({
        ...state,
        roundBetResult: null
      }))
      .addCase(escapeCrashBet.pending, (state, action) => ({
        ...state
      }))
      .addCase(getAllRoundHistory.fulfilled, (state, action) => {
        return {
          ...state,
          allRoundHistoryData: action.payload?.history,
          allRoundLoading: false
        }
      })
      .addCase(getAllRoundHistory.rejected, (state, action) => {
        return {
          ...state,
          allRoundHistoryData: [],
          allRoundLoading: false
        }
      })

      .addCase(getAllRoundHistory.pending, (state, action) => {
        return {
          ...state,
          allRoundHistoryData: action.payload,
          allRoundLoading: true
        }
      })
      .addCase(getMyBets.pending, (state, action) => {
        return {
          ...state,
          myBetsLoading: true

        }
      })
      .addCase(getMyBets.rejected, (state, action) => {
        return {
          ...state,
          myBetsData: initialState.myBetsData,
          myBetsLoading: false

        }
      })
      .addCase(getMyBets.fulfilled, (state, action) => {
        return {
          ...state,
          myBetsData: {
            count: action.payload?.count,
            rows: action.payload.crashGameBets.map(betDetail => ({ ...betDetail, roundId: betDetail.roundDetail.roundId }))
          },
          myBetsLoading: false
        }
      })
      .addCase(getMyBetsPaginated.pending, (state, action) => {
        return {
          ...state,
          myBetsDataPaginatedLoading: true

        }
      })
      .addCase(getMyBetsPaginated.rejected, (state, action) => {
        return {
          ...state,
          myBetsDataPaginated: initialState.myBetsDataPaginated,
          myBetsDataPaginatedLoading: false

        }
      })
      .addCase(getMyBetsPaginated.fulfilled, (state, action) => {
        return {
          ...state,
          myBetsDataPaginated: action.payload,
          myBetsDataPaginatedLoading: false

        }
      })
      .addCase(getPreviousRoundBets.pending, (state, action) => {
        return {
          ...state
        }
      })
      .addCase(getPreviousRoundBets.rejected, (state, action) => {
        return {
          ...state,
          previousBetsData: []

        }
      })
      .addCase(getPreviousRoundBets.fulfilled, (state, action) => {
        return {
          ...state,
          previousBetsData: action.payload
        }
      })
      .addCase(getTopBets.pending, (state, action) => {
        return {
          ...state,
          topBetsLoading: true
        }
      })
      .addCase(getTopBets.fulfilled, (state, action) => {
        return {
          ...state,
          topBetsData: action.payload,
          topBetsLoading: false
        }
      })
      .addCase(getTopBets.rejected, (state, action) => {
        return {
          ...state,
          topBetsData: [],
          topBetsLoading: false
        }
      })
      .addCase(verifyProvableFairCrash.pending, (state, payload) => ({
        ...state,
        loading: true
      }))
      .addCase(verifyProvableFairCrash.fulfilled, (state, { payload }) => ({
        ...state,
        provableFairData: payload,
        loading: false
      }))
      .addCase(verifyProvableFairCrash.rejected, (state, payload) => ({
        ...state,
        loading: false
      }))
  }
})

export {
  setPlacedBetsData,
  setResultData,
  setCurrentRoundId,
  setPlacedCrashBetId,
  setChooseCharacterOpen,
  setProvableFairData,
  appendCurrentBetsToMyBets,
  setPlacingOrCashoutLoading,
  setCurrentPlacedBet,
  setCrashLossCount,
  setCrashWinsCount,
  setCrashProfit,
  setCrashWagered,
  resetLiveStatsData,
  setLiveStatsData
}
export default reducer
