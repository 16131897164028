import { createAsyncThunk } from '@reduxjs/toolkit'
import { cashOutBetHiLoService, myBetsHiLoService, getUnfinishedBetService, openCardHiLoService, placeBetHiLoService, demoPlaceBetHiLoService, openDemoCardHiLoService, demoCashOutBetHiLoService } from '../hiloGame.service'
import { BET_RESULT } from 'constants/index'
import { setServerSeedHash } from 'redux-store/redux/slices/gameSettings.slice'
import { openErrorToaster, openSuccessToaster } from 'helpers/toaster.helpers'
import { resetCalculatedProfits, setCalculatedProfits } from './hiLoGame.slice'
import { updateDemoWalletBalance } from 'redux-store/redux/slices/auth.slice'

export const placeBetHiLo = createAsyncThunk('hi-lo-game/place-bet', async ({ payload, buttonClickAudio }, thunkApi) => {
  try {
    const res = await placeBetHiLoService(payload)
    const { gameSound } = thunkApi?.getState()?.gameSetting
    gameSound && buttonClickAudio?.play()
    openSuccessToaster({ message: 'Bet Placed Successfully' })
    return res
  } catch (error) {
    openErrorToaster({ message: 'Place Bet Failed' })
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const demoPlaceBetHiLo = createAsyncThunk('hi-lo-game/demo-place-bet', async ({ payload, buttonClickAudio }, thunkApi) => {
  try {
    const res = await demoPlaceBetHiLoService(payload)
    if (res) {
      thunkApi.dispatch(updateDemoWalletBalance(res.demoAmount))
    }
    const { gameSound } = thunkApi?.getState()?.gameSetting
    gameSound && buttonClickAudio?.play()
    openSuccessToaster({ message: 'Bet Placed Successfully' })
    return res
  } catch (error) {
    openErrorToaster({ message: 'Place Bet Failed' })
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const openCardHiLo = createAsyncThunk('hi-lo-game/open-card', async ({ data, setBetAmount, minBetData, betAmount, handleChangeMainCard }, thunkApi) => {
  try {
    const res = await openCardHiLoService(data)
    const card = document.querySelector('.card-center')

    card.classList.toggle('flipped')
    const openedCard = res.betStates[res.betStates.length - 1].openedCard
    if (res?.result === BET_RESULT.LOST) {
      thunkApi.dispatch(setServerSeedHash(res?.serverSeed))
      thunkApi.dispatch(resetCalculatedProfits({
        currentCardIndex: openedCard
      }))
      setBetAmount(Number(minBetData))
    } else {
      thunkApi.dispatch(setCalculatedProfits({
        currentCardIndex: openedCard,
        coefficient: res.betStates[res.betStates.length - 1].coefficient,
        betAmount
      }))
    }
    handleChangeMainCard(openedCard)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const openDemoCardHiLo = createAsyncThunk('hi-lo-game/open-demo-card', async ({ data, setBetAmount, minBetData, betAmount, handleChangeMainCard }, thunkApi) => {
  try {
    const res = await openDemoCardHiLoService(data)
    const card = document.querySelector('.card-center')

    card.classList.toggle('flipped')
    const openedCard = res.betStates[res.betStates.length - 1].openedCard
    if (res?.result === BET_RESULT.LOST) {
      thunkApi.dispatch(setServerSeedHash(res?.serverSeed))
      thunkApi.dispatch(resetCalculatedProfits({
        currentCardIndex: openedCard
      }))
      setBetAmount(Number(minBetData))
    } else {
      thunkApi.dispatch(setCalculatedProfits({
        currentCardIndex: openedCard,
        coefficient: res.betStates[res.betStates.length - 1].coefficient,
        betAmount
      }))
    }
    handleChangeMainCard(openedCard)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const getMyBetsHiLo = createAsyncThunk('hi-lo-game/my-bets', async (data, thunkApi) => {
  try {
    const res = await myBetsHiLoService(data)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const cashOutHiLo = createAsyncThunk('hi-lo-game/cash-out-bet', async ({ data, cashoutAudio }, thunkApi) => {
  try {
    const res = await cashOutBetHiLoService(data)
    thunkApi.dispatch(setServerSeedHash(res?.serverSeed))
    const { gameSound } = thunkApi?.getState()?.gameSetting
    gameSound && cashoutAudio?.play()
    return res
  } catch (error) {
    openErrorToaster({ message: 'Cash Out Failed' })
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const demoCashOutHiLo = createAsyncThunk('hi-lo-game/demo-cash-out-bet', async ({ data, cashoutAudio }, thunkApi) => {
  try {
    const res = await demoCashOutBetHiLoService(data)
    thunkApi.dispatch(setServerSeedHash(res?.serverSeed))
    const { gameSound } = thunkApi?.getState()?.gameSetting
    gameSound && cashoutAudio?.play()
    thunkApi.dispatch(updateDemoWalletBalance(res.demoAmount))
    return res
  } catch (error) {
    openErrorToaster({ message: 'Cash Out Failed' })
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const getUnfinishedBet = createAsyncThunk('hi-lo-game/get-unfinished-bet', async (_, thunkApi) => {
  try {
    const res = await getUnfinishedBetService()
    if (res?.hasUnfinishedBet) {
      let coefficient = 1
      let currentCardIndex
      const betStates = res.unfinishedBet.betStates
      const betAmount = res.unfinishedBet.betAmount
      if (betStates.length) {
        coefficient = betStates[betStates.length - 1].coefficient
        currentCardIndex = betStates[betStates.length - 1].openedCard
      } else {
        currentCardIndex = res.unfinishedBet.initialCard
      }
      thunkApi.dispatch(setCalculatedProfits({
        currentCardIndex,
        betAmount,
        coefficient
      }))
    }
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})
