import { THEMES_CONFIG } from "constants/index";

export const basePath = "/assets/pixi/crash/";

export const defaultTheme = THEMES_CONFIG[0].slug

export const path = {
  imagePath: basePath + "images/",
  fontPath: basePath + "fonts/",
  spriteSheetsPath: basePath + "spriteSheets/",
};

export const settings = {
  originalWidth: 1920,
  originalHeight: 1080,
  axisGapX: 0.075,
  axisGapY: 0.9, //0.96
  speed: 0.1,

  monstLight: {
    fontFamily: "Proxima-Nova",
    fontSize: 40,
    fontStyle: "",
    fontWeight: "bold",
    fill: ["#FFFFFF"],
    wordWrap: true,
    wordWrapWidth: 440,
  },

  monstRegular: {
    fontFamily: "Proxima-Nova",
    fontSize: 150,
    fontStyle: "",
    fontWeight: "bold",
    fill: ["#FFFFFF"],
    strokeThickness: 0,
    wordWrap: true,
    wordWrapWidth: 800,
  },

  monstRegularSmall: {
    fontFamily: "Monsterrat-Regular",
    fontSize: 50,
    fontStyle: "",
    fontWeight: "bold",
    fill: ["#FFFFFF"],
    strokeThickness: 0,
    wordWrap: true,
    wordWrapWidth: 200,
  },

  monstSemiBold: {
    fontFamily: "Monsterrat-SemiBold",
    fontSize: 40,
    fontStyle: "",
    fontWeight: "bold",
    fill: ["#FFFFFF"],
    strokeThickness: 0,
    wordWrap: true,
    wordWrapWidth: 400,
  },

  topGraphColor: 0xffffff,
  bottomGraphColor: 0xffffff,

  crashBottomGraphColor: 0xed310c,
  crashTopGraphColor: 0xed310c,

  green: 0x3dd179,
  yellow: 0xffe500,
  blue: 0x4185f0,
  grey: 0x8E8E8E,

  themeColors: {
    default:{
      loadingTint: '0xffffff',
      loadingText: '0xffffff',
      topGraph: '0xffffff',
      bgFill: '0xffffff',
      crashTop: '0xB52C2C',
      crashBgFill: '0xB52C2C',
      axis: '0x5F647C',
      bgAlpha: 0
    },
    tgc: {
      loadingTint: '0xffffff',
      loadingText: '0xffffff',
      topGraph: '0xffffff',
      bgFill: '0xffffff',
      crashTop: '0xed310c',
      crashBgFill: '0xed310c',
      axis: '0xAFAFAF',
      bgAlpha: 1
    },
    lb: {
      loadingTint: '0xffffff',
      loadingText: '0xffffff',
      topGraph: '0xffffff',
      bgFill: '0xffffff',
      crashTop: '0xB52C2C',
      crashBgFill: '0xB52C2C',
      axis: '0x8E8E8E',
      bgAlpha: 1
    },
    wsm: {
      loadingTint: '0x8E8E8E',
      loadingText: '0x000000',
      topGraph: '0x8E8E8E',
      bgFill: '0x8E8E8E',
      crashTop: '0xB52C2C',
      crashBgFill: '0xB52C2C',
      axis: '0x8E8E8E',
      bgAlpha: 1
    }
  }
};
