export const S3_IMAGES_URL = Object.freeze({
  IMAGE_URL: 'https://metaversity-dev-storage.s3.amazonaws.com'
})


export const americanFootball = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/american-football.webp`
export const clutchGameLogo = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/clutch-game-logo.webp`
export const provider1 = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/provider-1.webp`
export const goldICon = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/coin.webp`
export const goldCoin25x25Icon = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/gold-coin-25x25.webp`
export const teamIcon1 = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/table-icon.webp`
export const AuthBacheartkgroundPng = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/heart.webp`
export const Fullscreen = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/zoom.webp`
export const casino = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/casino.webp`
export const AuthBackgroundPng = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/auth-bg.webp`
export const RewardBox = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/reward-box.webp`
export const earthIcon = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/earth.webp`
export const eth = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/eth.webp`
export const Bonus = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/bonus.webp`
export const Cashback = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/cashback.webp`
export const Mini = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/Mini.webp`
export const PokerGames = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/poker-games.webp`
export const Promotion = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/promotion.webp`
export const Community = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/Community.webp`
export const ecoSports = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/clutch-1.webp`
export const ecoCasino = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/clutch-2.webp`
export const ecoFutures = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/clutch-3.webp`
export const hotGames = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/home-promotion-1.webp`
export const clutchWhitepaper = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/home-promotion-2.webp`
export const vipRanked = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/home-promotion-3.webp`
export const mine = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/mine.webp`
export const hilo = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/hilo.webp`
export const coinFlip = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/coin-flip.webp`
export const crash = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/crash.webp`
export const casinoHeading = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/casino-heading-ico.webp`
export const defaultGameImage = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/defaultGameImage.webp`
export const tennis = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/tennis.webp`
export const baseball = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/baseball.webp`
export const iceHockey = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/ice-hockey.webp`
export const premierLeague = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/premier-league.webp`
export const leagueOfLegends = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/league-of-legends.webp`
export const formulaOne = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/formulaOne.webp`
export const ClutchGameOn = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/clutch-game-on.webp`
export const ProfileImg = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/profileImg.webp`
export const plinko = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/plinko.webp`
export const fifa = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/fifa.webp`
export const mma = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/mma.webp`
export const boxing = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/boxing.webp`
export const counterStrike = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/counter-strike.webp`
export const cricket = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/cricket.webp`
export const dota = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/dota.webp`
export const rugbyLeague = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/rugby-league.webp`
export const rugbyUnion = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/rugby-union.webp`
export const valorant = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/valorant.webp`
export const golf = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/golf.webp`
export const clutchWalletIcon = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/clutch-wallet-icon.webp`
export const peopleIcon = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/age-icon.webp`
export const crashHistoryIcon = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/you-icon.webp`
export const mixCoin = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/mix-coin.webp`
export const modalCashout = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/modal-cashout.webp`
export const modalDown = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/modal-down.webp`
export const modalInfo = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/modal-info.webp`
export const modalMultiplier = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/modal-multiplier.webp`
export const modalUp = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/modal-up.webp`
export const coinFlipHeads = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/heads.webp`
export const coinFlipTails = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/tails.webp`
export const rollerCoaster = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/vector.webp`
export const sideFox = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/side-fox.webp`
export const googleCut = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/google-cut.webp`
export const AffiliateCardOne = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/affiliate-card-1.webp`
export const AffiliateCardTwleagueOfLegendso = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/affiliate-card-2.webp`
export const AffiliateCardThree = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/affiliate-card-3.webp`
export const AffiliateCardFour = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/affiliate-card-4.webp`
export const AffiliateCardFive = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/affiliate-card-5.webp`
export const rewardCards = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/rewards-cards.webp`
export const rewardCoins = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/rewards-coins.webp`
export const rewardStone = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/rewards-stone.webp`
export const rewardBadge = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/rewards-badge.webp`
export const rewardGiftBox = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/rewards-giftbox.webp`
export const twitchCut = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/twitch-cut.webp`
export const phantomCut = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/phantom-cut.webp`
export const rewardsVisa = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/rewards-visa.webp`
export const usdtIcon = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/usdt.webp`
export const balanceCoins = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/balance-coins.webp`
export const heart = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/heart.webp`
export const walletBalanceCoins = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/wallet-balance-coins.webp`
export const dailybonus = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/daily-bonus.webp`
export const weeklybonus = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/weekly-bonus.webp`
export const dailyreward = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/dailyreward.webp`
export const weeklyreward = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/weeklyreward.webp`
export const monthlyreward = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/monthlyreward.webp`
export const rakebackreward = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/rakebackreward.webp`
export const otherreward = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/otherreward.webp`
export const totalreward = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/totalreward.webp`
export const gamingCuracao = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/gaming-curacao.webp`
export const emailVerifyImage = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/email-verify-img.webp`
export const updatePasswordImg = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/update-pass-img.webp`
export const withdrawRequestImg = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/withdrawal-req-img.webp`
export const providersImg = `${S3_IMAGES_URL.IMAGE_URL}/assets/Icons/png/Providers.png`
