import { Howler } from 'howler'
import bgmMusic from '../audios/BGM.mp3'
import leftDropMusic from '../audios/Left-Drop.wav'
import rightDropMusic from '../audios/Right-Drop.wav'
import orangeBoxMusic from '../audios/Orange-Box.wav'
import redBoxMusic from '../audios/Red-Box.wav'
import yellowBoxMusic from '../audios/Yellow-Box.wav'
import multiplierMusic from '../audios/multiplier.mp3'
import plinkoBallMusic from '../audios/plinkoball.mp3'
import betPlaceMusic from '../audios/betPlace.mp3'

const BGMSound = new Howl({
  src: [bgmMusic],
  loop: true
})

const leftDropSound = new Howl({
  src: [leftDropMusic],
  loop: false
})

const rightDropSound = new Howl({
  src: [rightDropMusic],
  loop: false
})

const orangeBoxSound = new Howl({
  src: [orangeBoxMusic],
  loop: false
})

const redBoxSound = new Howl({
  src: [redBoxMusic],
  loop: false
})

const yellowBoxSound = new Howl({
  src: [yellowBoxMusic],
  loop: false
})

const multiplierSound = new Howl({
  src: [multiplierMusic],
  loop: false
})

const plinkoBallSound = new Howl({
  src: [plinkoBallMusic],
  volume: 0.2,
  loop: false
})

const betPlaceSound = new Howl({
  src: [betPlaceMusic],
  loop: false
})

export function playBGMSound () {
  BGMSound?.play();
}
export function playRightDropSound () {
  // rightDropSound?.play();
  plinkoBallSound?.play();
}
export function playLeftDropSound () {
  // leftDropSound?.play();
  plinkoBallSound?.play();
}
export function playYellowBoxSound () {
  multiplierSound?.play();
}
export function playOrangeBoxSound () {
  multiplierSound?.play();
}
export function playRedBoxSound () {
  multiplierSound?.play();
}
export function playBetPlaceSound () {
  betPlaceSound?.play();
}

export function togglePlinkoSound (enable) {
  BGMSound.mute(enable)
  leftDropSound.mute(enable)
  rightDropSound.mute(enable)
  orangeBoxSound.mute(enable)
  redBoxSound.mute(enable)
  yellowBoxSound.mute(enable)
  multiplierSound.mute(enable)
  plinkoBallSound.mute(enable)
  betPlaceSound.mute(enable)
}
