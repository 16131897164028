import { METHOD_TYPES } from 'constants/index'
import axiosInstanceService from 'network/apis/index'
import { microServices } from 'network/apis/microservice/index'

export const getPreviousRoundState = () => {
  return axiosInstanceService(METHOD_TYPES.get, 'game/fast-game/mine/get-unfinished-bet-state', {}, {
    server: microServices.SERVICE_URL_1
  })
}

export const getLiveStats = () => {
  return axiosInstanceService(METHOD_TYPES.get, 'game/fast-game/mine/live-stats', {}, {
    server: microServices.SERVICE_URL_1
  })
}

export const getBetHistory = (params) => {
  return axiosInstanceService(METHOD_TYPES.get, 'game/fast-game/mine/get-bets', {}, {
    server: microServices.SERVICE_URL_1,
    params
  })
}

export const placeBet = (data) => {
  return axiosInstanceService(METHOD_TYPES.post, 'game/fast-game/mine/place-bet', data, {
    server: microServices.SERVICE_URL_1
  })
}

export const autoBet = (data) => {
  return axiosInstanceService(METHOD_TYPES.post, 'game/fast-game/mine/auto-bet', data, {
    server: microServices.SERVICE_URL_1
  })
}

export const openTile = (data) => {
  return axiosInstanceService(METHOD_TYPES.post, 'game/fast-game/mine/open-tile', data, {
    server: microServices.SERVICE_URL_1
  })
}

export const cashOutBet = () => {
  return axiosInstanceService(METHOD_TYPES.post, 'game/fast-game/mine/cash-out-bet', {}, {
    server: microServices.SERVICE_URL_1
  })
}

export const verifyProvableFairMine = (data) => {
  return axiosInstanceService(METHOD_TYPES.post, 'game/fast-game/mine/check-provable-fair', data, {
    server: microServices.SERVICE_URL_1
  })
}

export const getTopBets = (params) => {
  return axiosInstanceService(METHOD_TYPES.get, 'game/fast-game/mine/top-bets', {}, {
    params,
    server: microServices.SERVICE_URL_1
  })
}
