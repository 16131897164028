import { openErrorToaster } from 'helpers/toaster.helpers'
import { getTranslatedErrorMessage, getTranslation } from 'helpers/translations.helpers'
import errorMessages from 'network/messages/errorMessages'
import store from 'index'
import { stopLoader } from 'redux-store/redux/slices/loader.slice'
import { formatString } from 'helpers/common.helpers'

export const errorHandler = (error) => {
  if (error.response.status === 500) {
    // Snackbar Internal Server Error
    openErrorToaster({ message: getTranslation(errorMessages.internalServerError) })
    store.dispatch(stopLoader(error.response.config.loader))
    return Promise.reject(error.response.data.errors)
  } else if (error.response.status === 401) {
    // Snackbar UnAuthenticated
    openErrorToaster({ message: errorMessages.unAuthorized })
    store.dispatch(stopLoader(error.response.config.loader))
    return Promise.reject(error.response.data.errors)
  } else if (error.response.config?.loader) {
    // Other errors
    store.dispatch(stopLoader(error.response.config.loader))
  }
  // Open Error Toaster
  const apiErrorCode = error.response.data.errors[0].errorCode
  const errorMessage = error.response.data.errors[0].description
  errorMessage
    ? openErrorToaster({ message: formatString(errorMessage) || errorMessage })
    : openErrorToaster({ message: getTranslatedErrorMessage(apiErrorCode) })
  return Promise.reject(error.response.data.errors)
}
