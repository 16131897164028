import { lazy } from 'react'
import { ROUTE_PATHS } from 'constants/index'

const Home = lazy(() => import('pages/Homepage/index'))
const CrashGame = lazy(() => import('games/CrashGame/index'))
const ErrorPage = lazy(() => import('pages/ErrorPage/index'))

const routesList = [
  {
    path: ROUTE_PATHS.homepage,
    component: Home,
    isPrivate: false,
    isHeader: true,
    isFooter: false
  },
  {
    path: ROUTE_PATHS.crashGame,
    component: CrashGame,
    isPrivate: false,
    isHeader: true,
    isFooter: false
  },
  {
    path: ROUTE_PATHS.error,
    component: ErrorPage,
    isPrivate: false,
    isHeader: true,
    isFooter: false
  },
]

export default routesList
