import React, { memo } from 'react'
import { LOADER } from 'constants/index'
const Loader = ({ component }) => {
  switch (component) {
    case LOADER.CONTENT:
      return (
        <div className='d-flex mt-2 mb-2 h-100 justify-content-center align-items-center'>
          <div className='spinner-border text-light mt-2 mb-2' role='status'>
            <span className='sr-only'>Loading...</span>
          </div>
        </div>
      )

    case LOADER.TABLE_CELL:
      return <span className='spinner-border spinner-border-sm text-light' role='status' aria-hidden='true' />

    case LOADER.BUTTON:
      return <span className='spinner-border spinner-border-sm text-light' role='status' aria-hidden='true' />

    default:
      return (
        <div className='d-flex mt-2 mb-2 h-100 justify-content-center align-items-center'>
          <div className='d-flex justify-content-center spinner-border show-loader' role='status' />
        </div>
      )
  }
}

export default memo(Loader)
