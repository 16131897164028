import { TOKEN } from 'constants/index'
import { decrypt, encrypt } from './crytpo.helper'

/* ==========================================================================
  Auth Token
========================================================================== */
export const getAuthTokenFromCookie = () => {
  const encyptedToken = window.sessionStorage.getItem(TOKEN)
  const decryptedToken = decrypt(encyptedToken)
  return decryptedToken
}

export const setAuthTokenInCookie = (authAccessToken) => {
  const encryptedToken = encrypt(authAccessToken)
  window.sessionStorage.setItem(TOKEN, encryptedToken)
}

export const removeAuthTokenFromCookie = () => {
  window.sessionStorage.removeItem(TOKEN)
}

/* ==========================================================================
  SignIn Action
========================================================================== */
export const signIn = ({ token }) => {
  setAuthTokenInCookie(token)
}

/* ==========================================================================
  SignOut Action
========================================================================== */
export const signOut = () => {
  removeAuthTokenFromCookie()
}
