import ErrorMessage from './errorMessages'

const errorCodeToMessage = new Map([
  [600, ErrorMessage.internalServerError],
  [601, ErrorMessage.loginFailedError],
  [602, ErrorMessage.invalidCredentialsError],
  [603, ErrorMessage.invalidTokenError],
  [604, ErrorMessage.invalidSessionError],
  [605, ErrorMessage.invalidAccessError],
  [2001, ErrorMessage.createUserError],
  [2004, ErrorMessage.dataMismatchError],
  [2005, ErrorMessage.userNotFoundError],
  [2006, ErrorMessage.duplicateEmailError],
  [3001, ErrorMessage.requestInputValidationError],
  [3002, ErrorMessage.responseInputValidationError],
  [3003, ErrorMessage.socketRequestInputValidationError],
  [3004, ErrorMessage.socketResponseValidationError],
  [3005, ErrorMessage.internalServerError],
  [3006, ErrorMessage.invalidSocketArgumentError],
  [3007, ErrorMessage.invalidCredentials],
  [3008, ErrorMessage.invalidToken],
  [3009, ErrorMessage.invalidSession],
  [3010, ErrorMessage.invalidAccess],
  [3011, ErrorMessage.nonOperationalError],
  [3012, ErrorMessage.userNotExists],
  [3013, ErrorMessage.invalidAction],
  [3014, ErrorMessage.sessionAlreadyStarted],
  [3015, ErrorMessage.sessionNotStarted],
  [3016, ErrorMessage.emailNotVerified],
  [3017, ErrorMessage.invalidGameTypeError],
  [3018, ErrorMessage.invalidGameRoundError],
  [3019, ErrorMessage.noRoundRunningError],
  [3020, ErrorMessage.noPlacedBetFoundError],
  [3021, ErrorMessage.noWalletFoundErrorr],
  [3022, ErrorMessage.notEnoughBalanceError],
  [3023, ErrorMessage.autoRateIsInvalidError],
  [3024, ErrorMessage.betAmountIsNotInLimitError],
  [3025, ErrorMessage.emailNotFound],
  [3026, ErrorMessage.userNotAbove18YearsError],
  [3027, ErrorMessage.invalidBlockchainAddress],
  [3028, ErrorMessage.addressMismatch],
  [3029, ErrorMessage.nonceLifetimeExpired],
  [3030, ErrorMessage.somethingWentWrong],
  [3031, ErrorMessage.accountNotActive],
  [3032, ErrorMessage.loginTokenRequire],
  [3033, ErrorMessage.userAlreadyExists],
  [3034, ErrorMessage.invalidVerificationToken],
  [3035, ErrorMessage.userNotActive],
  [3036, ErrorMessage.fileUploadFailed],
  [3037, ErrorMessage.emailAlreadyVerified],
  [3038, ErrorMessage.invalidReferralCode],
  [3039, ErrorMessage.invalidAffiliateCode],
  [3040, ErrorMessage.recordNotFound],
  [3041, ErrorMessage.withdrawalRequestAlreadyPending],
  [3042, ErrorMessage.walletDoesNotBelongToUser],
  [3043, ErrorMessage.betAlreadyInProgress],
  [3044, ErrorMessage.serverSeedNotFoundErrorType],
  [3053, ErrorMessage.favoriteGameExistsErrorType],
  [3054, ErrorMessage.favoriteGameNotFoundErrorType],
  [3055, ErrorMessage.transactionAlreadyPendingErrorType],
  [3057, ErrorMessage.userHasNoActiveBonusErrorType],
  [3058, ErrorMessage.bonusExpiredErrorType],
  [3059, ErrorMessage.userHasAlreadyActivatedBonusWageringErrorType],
  [3060, ErrorMessage.invalidRoundHashErrorType],
  [3061, ErrorMessage.invalidSignatureErrorType],
  [3062, ErrorMessage.userHasNoBonusAmountToClaimErrorType],
  [3063, ErrorMessage.bonusNotExistsErrorType],
  [3072, ErrorMessage.blockedTransactionErrorType]
])

export default errorCodeToMessage
