import { createSlice } from '@reduxjs/toolkit'
import { BET_TYPE, CONDITIONS } from '../../../games/DiceGame/components/BetConditionModal/BetConditionModal'

export const swapElements = (array, index1, index2) => {
  [array[index1], array[index2]] = [array[index2], array[index1]]
  return array
}
const initialState = {
  strategies: [],
  selectedStrategy: 'Martingale',
  strategyId: null,
  strategyBetCount: 0,
  strategyWinCount: 0,
  strategyLoseCount: 0,
  isStrategyMode: false,
  winStreakCount: 0,
  lossStreakCount: 0,
  strategyResultArray: []
}

const {
  actions: {
    setStrategy,
    setSelectedStrategy,
    setStrategyId,
    setOn,
    setDo,
    setStrategyMode,
    setStrategyWinCount,
    setStrategyLoseCount,
    setStrategyBetCount,
    setWinStreakCount,
    setLoseStreakCount,
    setDiceStrategyResultArray,
    setShouldDo,
    setType,
    setStrategyCondition,
    setResetShouldDo,
    setBlockOrder,
    removeBlock,
    createStrategy,
    removeStrategy,
    setInitialStrategies
  },
  reducer
} = createSlice({
  name: 'strategy',
  initialState: initialState,
  reducers: {
    setInitialStrategies: (state, action) => ({
      ...state,
      strategies: action.payload
    }),
    setStrategy: (state, action) => ({
      ...state,
      strategies: state?.strategies?.map(strategy => {
        if (strategy.label === state.selectedStrategy) {
          return {
            ...strategy,
            blocks: [...strategy.blocks, action.payload]
          }
        }
        return strategy
      })
    }),
    setSelectedStrategy: (state, action) => ({
      ...state,
      selectedStrategy: action.payload
    }),
    setStrategyId: (state, action) => ({
      ...state,
      strategyId: action.payload
    }),
    setStrategyMode: (state, action) => ({
      ...state,
      isStrategyMode: action.payload
    }),
    setStrategyWinCount: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          strategyWinCount: action.payload.reset
        }
      }
      return {
        ...state,
        strategyWinCount: state.strategyWinCount + 1
      }
    },
    setStrategyLoseCount: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          strategyLoseCount: action.payload.reset
        }
      }
      return {
        ...state,
        strategyLoseCount: state.strategyLoseCount + 1
      }
    },
    setStrategyBetCount: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          strategyBetCount: action.payload.reset
        }
      }
      return {
        ...state,
        strategyBetCount: state.strategyBetCount + 1
      }
    },
    setWinStreakCount: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          winStreakCount: action.payload.reset
        }
      }
      return {
        ...state,
        winStreakCount: state.winStreakCount + 1
      }
    },
    setLoseStreakCount: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          lossStreakCount: action.payload.reset
        }
      }
      return {
        ...state,
        lossStreakCount: state.lossStreakCount + 1
      }
    },
    setDiceStrategyResultArray: (state, action) => {
      return {
        ...state,
        strategyResultArray: action.payload
      }
    },
    setBlockOrder: (state, action) => {
      const { idx1, idx2 } = action.payload
      return {
        ...state,
        strategies: state.strategies.map(strategy => {
          if (strategy?.label === state?.selectedStrategy) {
            const newBlocks = strategy?.blocks?.map(block => ({ ...block, on: { ...block.on }, do: { ...block.do } }));
            [newBlocks[idx1], newBlocks[idx2]] = [newBlocks[idx2], newBlocks[idx1]]
            return {
              ...strategy,
              blocks: newBlocks
            }
          }
          return strategy
        })
      }
    },
    setOn: (state, action) => {
      const key = Object.keys(action.payload)
      return {
        ...state,
        strategies: state.strategies.map(strategy => {
          if (strategy?.label === state?.selectedStrategy) {
            return {
              ...strategy,
              blocks: strategy?.blocks.map(block => {
                if (block.id === state.strategyId) {
                  return {
                    ...block,
                    on: { ...block.on, [key[0]]: action.payload[key] }
                  }
                }
                return block
              })
            }
          }
          return strategy
        })
      }
    },
    setDo: (state, action) => {
      const key = Object.keys(action.payload)
      return {
        ...state,
        strategies: state?.strategies?.map(strategy => {
          if (strategy?.label === state?.selectedStrategy) {
            return {
              ...strategy,
              blocks: strategy?.blocks.map(block => {
                if (block.id === state.strategyId) {
                  return {
                    ...block,
                    do: { ...block.do, [key[0]]: action.payload[key] }
                  }
                }
                return block
              })
            }
          }
          return strategy
        })
      }
    },
    setShouldDo: (state, action) => {
      const { blockId, label } = action.payload
      return {
        ...state,
        strategies: state?.strategies?.map(strategy => {
          if (strategy?.label === label) {
            return {
              ...strategy,
              blocks: strategy?.blocks.map(block => {
                if (block.id === blockId) {
                  return {
                    ...block,
                    do: { ...block.do, shouldNotExecute: true }
                  }
                }
                return block
              })
            }
          }
          return strategy
        })
      }
    },
    setResetShouldDo: (state, action) => {
      return {
        ...state,
        strategies: state?.strategies?.map(strategy => {
          if (strategy?.label === state.selectedStrategy) {
            return {
              ...strategy,
              blocks: strategy?.blocks.map(block => {
                return {
                  ...block,
                  do: { ...block.do, shouldNotExecute: false }
                }
              })
            }
          }
          return strategy
        })
      }
    },
    setType: (state, action) => {
      const strategyCondition = action.payload
      return {
        ...state,
        strategies: state?.strategies?.map(strategy => {
          if (strategy.label === state.selectedStrategy) {
            return {
              ...strategy,
              blocks: strategy?.blocks.map(block => {
                if (block?.id === state?.strategyId) {
                  return {
                    ...block,
                    type: (strategyCondition === BET_TYPE.BETS_CONDITION) ? BET_TYPE.BETS_CONDITION : BET_TYPE.PROFIT_CONDITION,
                    on: {
                      ...block?.on,
                      profitType: block?.profitType || CONDITIONS.BALANCE,
                      type: (strategyCondition === BET_TYPE.BETS_CONDITION) ? CONDITIONS.EVERY : CONDITIONS.GREATER_THAN
                    }
                  }
                }
                return block
              })
            }
          }
          return strategy
        })
      }
    },
    removeBlock: (state, action) => {
      const blockId = action.payload
      return {
        ...state,
        strategies: state?.strategies?.map(strategy => {
          if (strategy?.label === state.selectedStrategy) {
            return {
              ...strategy,
              blocks: strategy?.blocks.filter(block => (block?.id !== blockId))
            }
          }
          return strategy
        })
      }
    },
    createStrategy: (state, action) => ({
      ...state,
      strategies: [...state?.strategies, action?.payload]
    }),
    removeStrategy: (state, action) => {
      return {
        ...state,
        selectedStrategy: state?.strategies?.filter(strategy => strategy?.label !== state?.selectedStrategy)?.[0]?.label,
        strategies: state?.strategies?.filter(strategy => strategy?.label !== state?.selectedStrategy)
      }
    }
  }

})

export default reducer
export {
  setStrategy,
  setSelectedStrategy,
  setStrategyId,
  setOn,
  setDo,
  setStrategyMode,
  setStrategyWinCount,
  setStrategyLoseCount,
  setStrategyBetCount,
  setWinStreakCount,
  setLoseStreakCount,
  setDiceStrategyResultArray,
  setShouldDo,
  setType,
  setStrategyCondition,
  setResetShouldDo,
  setBlockOrder,
  removeBlock,
  createStrategy,
  removeStrategy,
  setInitialStrategies
}
