
export const APPEND_TYPES = {
  ROUND_STOPPED: 'ROUND_STOPPED',
  ESCAPE: 'ESCAPE'
}

export const PAGINATION_LIMIT = 20

export const DEFAULT_MY_BETS_LIMIT = 20
export const DEFAULT_CRASH_AUTO_BETS = 3
