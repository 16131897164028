import { Container, Graphics, Text, TextStyle } from "pixi.js"
import { app } from "./appPixi"
import { config } from "./settings"

class ProgressBar extends Container {
  constructor({ ...config }) {
    super()

    this.bg = config.bg
    this.filler = config.fill
    this.startFrom = config.progress

    this.init()
  }

  init() {
    this.fillMask = new Graphics()
    this.fillMask.beginFill(0xFF3300);
    this.fillMask.drawRect(
      this.bg.x,
      this.bg.y,
      this.filler.width * (this.startFrom / 100),
      this.filler.height
    );
    this.fillMask.endFill();
    this.filler.mask = this.fillMask

    this.addChild(
      this.bg, this.filler
    )
  }

  validate(progress) {
    progress = Math.round(progress);
    if (progress < 0) {
      return 0;
    }

    if (progress > 100) {
      return 100;
    }

    return progress;
  }

  set progress(progress) {
    if (this.fillMask) {
      this.filler.mask = null;
      this.fillMask.destroy();
    }

    this.prog = this.validate(this.startFrom + progress)

    this.fillMask = new Graphics()
    this.fillMask.beginFill(0xFF3300);
    this.fillMask.drawRect(
      this.bg.x,
      this.bg.y,
      this.filler.width * (this.progress / 100),
      this.filler.height
    );
    this.fillMask.endFill();
    this.filler.mask = this.fillMask
    this.addChild(this.fillMask)
  }

  get progress() {
    return this.prog
  }
}

class AssetsProgressBar extends Container {
  constructor() {
    super()
    this.defaultArgs = {
      animate: true,
      value: 0,
      vertical: window.matchMedia("(orientation: portrait)").matches
    }

    this.options = { ...this.defaultArgs, ...config.progress.bar }

    const background = new Graphics()
      .beginFill(this.options.borderColor)
      .drawRoundedRect(0, 0, this.options.width, this.options.height, this.options.radius)
      .beginFill(this.options.backgroundColor)
      .drawRoundedRect(this.options.border, this.options.border, this.options.width - (this.options.border * 2), this.options.height - (this.options.border * 2), this.options.radius)

    const filler = new Graphics()
      .beginFill(this.options.borderColor)
      .drawRoundedRect(0, 0, this.options.width, this.options.height, this.options.radius)
      .beginFill(this.options.fillColor)
      .drawRoundedRect(this.options.border, this.options.border, this.options.width - (this.options.border * 2), this.options.height - (this.options.border * 2), this.options.radius)

    this.progressBar = new ProgressBar({
      bg: background,
      fill: filler,
      progress: this.options.value
    })

    const loadingTxtStyle = new TextStyle({ ...config.progress.text, fontWeight: 'bold' })

    this.progressText = new Text(`0%`, loadingTxtStyle)
    this.progressText.visible = config.progress.text.visible
    this.progressText.position.set(
      this.progressBar.width / 2,
      this.progressBar.height
    )

    if (this.options.vertical) {
      this.rotation = Math.PI / 2;
      this.position.set(this.y, this.x)
    }

    this.addChild(this.progressBar, this.progressText)
    this.pivot.set(
      this.width / 2,
      this.height / 2
    )
  }

  setProgress(progress) {
    this.progressBar.progress = Math.floor(progress * 100)
    this.progressText.text = `${this.progressBar.progress}%`
    this.progressText.x = this.progressBar.width / 2 - this.progressText.width / 2
    if (Number(progress) === 1) this.visible = false
  }
}

export const progressBar = new AssetsProgressBar()
