import CryptoJS from 'crypto-js'

const SECRET_KEY = 'PF*xiT)X0ZSN<p#^'

export const encrypt = (item) => {
  const encryptedToken = CryptoJS.AES.encrypt(item, SECRET_KEY).toString()
  return encryptedToken
}

export const decrypt = (item) => {
  // Retrieve the encrypted token
  if (!item) {
    return null
  }
  try {
    // Decrypt the token
    const bytes = CryptoJS.AES.decrypt(item, SECRET_KEY)
    const decryptedItem = bytes.toString(CryptoJS.enc.Utf8)
    return decryptedItem
  } catch (error) {
    console.error('Decryption failed:', error)
    return null
  }
}
