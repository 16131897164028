import React, { createContext, useState, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { THEMES_CONFIG } from "constants/index";;
import { getGameSetting } from 'redux-store/thunk/gameSettings.thunk'
import { setThemeLoaded } from 'redux-store/redux/slices/gameSettings.slice';

const ThemeContext = createContext();
export const useTheme = () => useContext(ThemeContext);

export const ThemeProvider = ({ children }) => {
  const dispatch = useDispatch();
  const search = window.location.search;
  const gameId = new URLSearchParams(search).get('gameId');

  const [theme, setTheme] = useState('default')
  const { isLoggedIn } = useSelector((state) => state.auth)
  const { systemGameData, themeLoaded } = useSelector(state => state.gameSetting)
  const gamesSettings = systemGameData?.find(ele => ele.gameId == gameId)
  

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getGameSetting())
    }
  }, [isLoggedIn])

  useEffect(() => {
    if(gamesSettings){

      const gameTheme = THEMES_CONFIG.find(t => t.themeId == gamesSettings.themeId)
      if(gameTheme){
        const bodyClassName = gameTheme.themeClassName
        document.body.className = bodyClassName;
        setTheme(bodyClassName);
        dispatch(setThemeLoaded(true))
      } else{
        const bodyClassName = THEMES_CONFIG[0].themeClassName
        document.body.className =bodyClassName;
        setTheme(bodyClassName)
        dispatch(setThemeLoaded(true))
      }
    }
  }, [gamesSettings, systemGameData]);


  /**
   * ThemeSwitcher component is placed here temporarily,
   * Only uncomment it when you manually want to switch between different themes.
   * ONLY USE FOR TESTING PURPOSE, Also checkout App.js file
   */
  /**
    useEffect(() => {
      document.body.className = theme;
    }, [theme]);
   */
  
  const toggleTheme = (newTheme) => {
    setTheme(newTheme);
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
        {children}
    </ThemeContext.Provider>
  );
};
