import { THEMES_CONFIG } from "constants/index";

export const isPixiStandAlone = false;
// import appConfig from "config/app.config"

// export const basePath = appConfig.PIXI_ASSETS_BASE_URL + "plinko-game/";

export const defaultTheme = THEMES_CONFIG[0].slug // tgc

export const basePath = "/assets/pixi/plinko/"

export const path = {
  imagePath: basePath + "images/",
  spriteSheetsPath: basePath + "spriteSheets/",
  fontPath: basePath + "fonts/",
};

export const config = {
  maxLines: 16,
  originalWidth: 1080,
  originalHeight: 1080,
  topMargin: 580,
  rows: 8,
  pegs: {
    spacing: {
      width: 80,
      height: 80,
    },
    pegsWidth: 24,
    shine: {
      animationSpeed: 1.3,
    },
  },
  ball: {
    path: [0, 1, 0, 1, 0, 1, 0, 1],
    currentRow: 0,
    animationSpeed: 0.8, // 0.9,
    scaleFactor:1.4,
    scaleForAnimation: [1.9, 1.7, 1.52, 1.376, 1.26, 1.16, 1.054, 1, 0.93],
    bounceEffect: {
      //                 8    9    10     11     12     13     14    15    16
      scaleFactorScale: [1, 0.99, 0.89, 0.8, 0.79, 0.76, 0.74, 0.72, 0.56],
      scaleFactorX: [1, 0.888, 0.78, 0.73, 0.667, 0.65, 0.59, 0.542, 0.52],
      scaleFactorY: [1.01, 0.888, 0.796, 0.72, 0.655, 0.6, 0.555, 0.517, 0.48],
    },
  },
  box: {
    boxSpeed: 2,
    height: 65,
    width: 140,
    heightScale: 0, //75
    widthScale: 5.2,
  },
  pinInFirstLine: 3,
  bgAssets: {
    speed: 0.06,
  },
  progress: {
    bar: {
      backgroundColor: '#e55039',
      border: 4,
      borderColor: '#FFFFFF',
      fillColor: '#4cd137',
      height: 30,
      radius: 25,
      width: 550,
    },
    text: {
      visible: true,
      fill: 0xffffff,
      fontSize: 30,
    }
  },
  multiplierBoxesTints: [
    [2, 2, 1, 2, 2], // 8 lines
    [2, 2, 2, 2, 2], // 9
    [3, 2, 1, 2, 3], // 10
    [3, 2, 2, 2, 3], // 11
    [4, 2, 1, 2, 4], // 12
    [4, 2, 2, 2, 4], // 13
    [4, 3, 1, 3, 4], // 14
    [4, 3, 2, 3, 4], // 15
    [5, 3, 1, 3, 5], // 16
  ],
  colors:{
    default:{
      light: 0x1E212F,
      dark: 0x0D0F15,
      whiteFill: "#FFFFFF",
      pinsColor: 0xFFFFFF,
      pinShineScale: 3.5,
      boxTint: [
        '0x04B2AC',
        '0x0385FF',
        '0x9603FA',
        '0x8953FC',
        '0xFF0083',
        '0xFE2813',
        '0xFF8800',
        '0xFF9C2B',
        '0xFFB74C',
        '0xFFB74C',
        '0xFF9C2B',
        '0xFF8800',
        '0xFE2813',
        '0xFF0083',
        '0x8953FC',
        '0x9603FA',
        '0x0385FF',
        '0x04B2AC'
      ]
    },
    tgc:{
      light: 0x246D5A,
      dark: 0x10473A,
      whiteFill: "#FFFFFF",
      pinsColor: 0xFFFFFF,
      pinShineScale: 1.34,
      boxTint: [
        '0x04B2AC',
        '0x0385FF',
        '0x9603FA',
        '0x8953FC',
        '0xFF0083',
        '0xFE2813',
        '0xFF8800',
        '0xFF9C2B',
        '0xFFB74C',
        '0xFFB74C',
        '0xFF9C2B',
        '0xFF8800',
        '0xFE2813',
        '0xFF0083',
        '0x8953FC',
        '0x9603FA',
        '0x0385FF',
        '0x04B2AC'
      ]
    },
    lb:{
      light: 0x414144,
      dark: 0x171717,
      whiteFill: "#FFFFFF",
      pinsColor: 0xFEEBB9,
      pinShineScale: 1.34,
      boxTint: [
        '0xAA4324',
        '0x872300',
        '0x5E1F00',
        '0x472C00',
        '0x674101',
        '0x7A4E00',
        '0x8C6A26',
        '0xDDAF3D',
        '0xFED569',
        '0xFED569',
        '0xDDAF3D',
        '0x8C6A26',
        '0x7A4E00',
        '0x674101',
        '0x472C00',
        '0x5E1F00',
        '0x872300',
        '0xAA4324',
      ]
    },
    wsm:{
      light: 0x3D3D3D,
      dark: 0x000000,
      whiteFill: "#FFFFFF",
      pinsColor: 0x000000,
      pinShineScale: 1.34,
      boxTint: [
        '0x251670',
        '0x4C33A8',
        '0x855BFC',
        '0x002D2B',
        '0x025450',
        '0x006D67',
        '0x269B98',
        '0x5DC1C1',
        '0x92DEE0',
        '0x92DEE0',
        '0x5DC1C1',
        '0x269B98',
        '0x006D67',
        '0x025450',
        '0x002D2B',
        '0x855BFC',
        '0x4C33A8',
        '0x251670',
      ]
    }
  }
};

export const gameDisplaySettings = {
  size: 1200,
  x: config.originalWidth / 2,
  y: config.originalHeight / 2 - 20,
};
