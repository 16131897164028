import { METHOD_TYPES } from 'constants/index'
import axiosInstanceService from 'network/apis/index'
import { microServices } from 'network/apis/microservice/index'

const gameName = 'game/crash/crash-game'

export const getCrashCurrentPlacedBetService = () => {
  return axiosInstanceService(METHOD_TYPES.get,  `${gameName}/current-bet`, {}, {
    server: microServices.SERVICE_URL_1
  })
}

export const placeBetCrashGameService = (data) => {
  return axiosInstanceService(METHOD_TYPES.post, `${gameName}/place-bet`, data, {
    server: microServices.SERVICE_URL_1
  })
}

export const allPlacedBetsInRoundCrashGameService = (params) => {
  return axiosInstanceService(METHOD_TYPES.get, `${gameName}/all-placed-bets`, {}, {
    params,
    server: microServices.SERVICE_URL_1
  })
}

export const escapeCrashBetService = (data) => {
  return axiosInstanceService(METHOD_TYPES.post, `${gameName}/player-escape`, data, {
    server: microServices.SERVICE_URL_1
  })
}

export const cancelCrashBetService = (data) => {
  return axiosInstanceService(METHOD_TYPES.post, `${gameName}/cancel-bet`, data, {
    server: microServices.SERVICE_URL_1
  })
}
export const getAllRoundHistoryService = () => {
  return axiosInstanceService(METHOD_TYPES.get, `${gameName}/get-history`, {
    server: microServices.SERVICE_URL_1
  })
}

export const getMyBetsService = (params) => {
  return axiosInstanceService(METHOD_TYPES.get, `${gameName}/my-bets`, {}, {
    params,
    server: microServices.SERVICE_URL_1
  })
}
export const getPreviousRoundBetsService = (params) => {
  return axiosInstanceService(METHOD_TYPES.get, `${gameName}/previous-round-placed-bets`, {}, {
    params,
    server: microServices.SERVICE_URL_1
  })
}

export const getTopBetsService = (params) => {
  return axiosInstanceService(METHOD_TYPES.get, `${gameName}/top-bets`, {}, {
    params,
    server: microServices.SERVICE_URL_1
  })
}

export const verifyProvableFairCrashService = (data) => {
  return axiosInstanceService(METHOD_TYPES.post, `${gameName}/check-fairness`, data, {
    server: microServices.SERVICE_URL_1
  })
}
