import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAllCurrencyService, getGameSettingService, getGameDetailsService } from 'network/services/gameSettings.service'
import { setAllCurrency } from 'redux-store/redux/slices/gameSettings.slice'

export const getAllCurrency = createAsyncThunk('user/getAllCurrency', async (_, thunkApi) => {
  try {
    const res = await getAllCurrencyService()
    thunkApi.dispatch(setAllCurrency(res))
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const getGameSetting = createAsyncThunk('system/get-game-settings', async (_, thunkApi) => {
  try {
    const res = await getGameSettingService()
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const getGameDetails = createAsyncThunk('system/get-game-details', async (_, thunkApi) => {
  try {
    const res = await getGameDetailsService()
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})
