import { TOKEN } from 'constants/index'

/* ==========================================================================
  Auth Token
========================================================================== */
export const getAuthTokenFromCookie = () => {
  return window.sessionStorage.getItem(TOKEN)
}

export const setAuthTokenInCookie = (authAccessToken) => {
  window.sessionStorage.setItem(TOKEN, authAccessToken)
}

export const removeAuthTokenFromCookie = () => {
  window.sessionStorage.removeItem(TOKEN)
}

/* ==========================================================================
  SignIn Action
========================================================================== */
export const signIn = ({ token }) => {
  setAuthTokenInCookie(token)
}

/* ==========================================================================
  SignOut Action
========================================================================== */
export const signOut = () => {
  removeAuthTokenFromCookie()
}
