import { createSlice } from '@reduxjs/toolkit'
import { getGameSetting } from 'redux-store/thunk/gameSettings.thunk'
import { generateServerSeed } from 'redux-store/thunk/user.thunk'
import { generateClientSeed } from 'helpers/common.helpers'
import { DEFAULT_GAME_MUSIC, DEFAULT_GAME_SOUND } from 'constants/index'
import { getItem, setItem } from 'helpers/localstorage.helpers'

const initialState = {
  systemGameData: null,
  allCurrencies: [],
  gameSettings: [],
  gameSettingLoader: false,
  clientSeed: generateClientSeed(),
  serverSeedHash: '',
  gameMusic: DEFAULT_GAME_MUSIC,
  gameSound: DEFAULT_GAME_SOUND,
  cryptoCurrencyCode: 'USDT',
  conversionRate: {
    EUR: 1.08,
    USD: 1
  },
  showCryptoAcknowledgement: getItem('showCryptoAcknowledgement') ?? true,
  themeLoaded: null,
}

const {
  actions: {
    setAllCurrency,
    setGameSettings,
    setGameSettingLoader,
    setNewClientSeed,
    setServerSeedHash,
    setGameMusic,
    updateGameSetting,
    setGameSound,
    setThemeLoaded,
    setShowCryptoAcknowledgementPopup
  },
  reducer
} = createSlice({
  name: 'gameSetting',
  initialState,
  reducers: {
    setAllCurrency: (state, action) => ({
      ...state,
      allCurrencies: action.payload
    }),
    setThemeLoaded: (state, action) => ({
      ...state,
      themeLoaded: action.payload
    }),
    setGameSettings: (state, action) => ({
      ...state,
      gameSettings: action.payload
    }),
    setGameSettingLoader: (state, action) => ({
      ...state,
      setGameSettingLoader: action.payload
    }),
    setNewClientSeed: (state, action) => ({
      ...state,
      clientSeed: action.payload
    }),
    setServerSeedHash: (state, action) => ({
      ...state,
      serverSeedHash: action.payload
    }),
    setGameMusic: (state, action) => ({
      ...state,
      gameMusic: !state.gameMusic
    }),
    setGameSound: (state, action) => ({
      ...state,
      gameSound: action?.payload ?? !state.gameSound
    }),
    updateGameSetting: (state, action) => {
      return {
        ...state,
        systemGameData: action.payload
      }
    },
    setShowCryptoAcknowledgementPopup: (state, action) => {
      setItem('showCryptoAcknowledgement', action.payload)
      return {
        ...state,
        showCryptoAcknowledgementPopup: action.payload,
        showCryptoAcknowledgement: action.payload
      }
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(getGameSetting.fulfilled, (state, action) => {
        return {
          ...state,
          systemGameData: action.payload,
          gameSettingLoader: false
        }
      })
      .addCase(generateServerSeed.fulfilled, (state, action) => {
        return {
          ...state,
          serverSeedHash: action.payload?.serverSeedHash ?? ''
        }
      })
      .addCase(generateServerSeed.rejected, (state, action) => {
        return {
          ...state
        }
      })
  }
})

export default reducer
export {
  setAllCurrency,
  setGameSettings,
  setGameSettingLoader,
  setNewClientSeed,
  setGameMusic,
  setServerSeedHash,
  updateGameSetting,
  setGameSound,
  setShowCryptoAcknowledgementPopup,
  setThemeLoaded
}
