import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'constants/index'
import axiosInstanceService from 'network/apis/index'
import { microServices } from 'network/apis/microservice/index'

export const getAllCurrencyService = () => {
  return axiosInstanceService(METHOD_TYPES.get, 'system/get-all-currency', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.table
  })
}
export const getGameSettingService = () => {
  return axiosInstanceService(METHOD_TYPES.get, 'system/get-game-settings', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.table
  })
}

export const getGameDetailsService = () => {
  return axiosInstanceService(METHOD_TYPES.get, 'system/get-game-settings', {}, {
    server: microServices.SERVICE_URL_1,
    loader: LOADER_HANDLER_TYPES.table
  })
}
