const successMessages = {
  loginSuccessfully: 'loginSuccessfully',
  logoutSuccessfully: 'logoutSuccessfully',
  passwordUpdateSuccessfully: 'passwordUpdateSuccessfully',
  updatePlayerStatusSuccessfully: 'updatePlayerStatusSuccessfully',
  resetPasswordSuccessfully: 'resetPasswordSuccessfully',
  signupSuccessfully: 'signupSuccessfully',
  betPlacedSuccessfully: 'betPlacedSuccessfully',
  betCancelledSuccessfully: 'betCancelledSuccessfully'
}

export default successMessages
