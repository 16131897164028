import { combineReducers } from '@reduxjs/toolkit'
import gameSetting from './slices/gameSettings.slice'
import authSlice from './slices/auth.slice'
import crashGame from 'games/CrashGame/slice-thunk/crashGame.slice'
import plinkoGame from 'games/PlinkoGame/slice-thunk/plinkoGame.slice'
import diceGame from 'games/DiceGame/slice-thunk/diceGame.slice'
import diceStrategy from 'games/DiceGame/slice-thunk//diceStrategy.slice'
import mineGame from 'games/MineGame/slice-thunk/mineGame.slice'
import hiLoGame from 'games/HiLoGame/slice-thunk/hiLoGame.slice'
import cryptoGame from 'games/CryptoGame/slice-thunk/cryptoGame.slice'
import cryptoCoin from 'games/CryptoGame/slice-thunk/cryptoCoin.slice'
import loader from './slices/loader.slice'

const rootReducer = combineReducers({
  gameSetting,
  crashGame,
  plinkoGame,
  mineGame,
  hiLoGame,
  diceGame,
  cryptoGame,
  cryptoCoin,
  diceStrategy,
  auth: authSlice,
  loader
})

export default rootReducer
