import { createSlice } from '@reduxjs/toolkit'
import { transactionType } from 'constants/index'
import { cryptoBetPlace, getBetPlaceTransaction, getBetTransactionDetail, getCryptoCoins, updateBetDetail } from './cryptoGame.thunk'

const defaultCryptoGameState = {
  cryptoData: null,
  betLoader: false,
  betPlaceTransaction: [],
  activePlaceTransaction: [],
  closedPlaceTransaction: [],
  publicBetTransaction: [],
  betTransactionDetail: '',
  updateBet: '',
  cryptoCoinsList: null,
  showHowItWorksPopup: false,
  showRoiCalculator: false,
  showLimitModal: false
}

const cryptoGameSlice = createSlice({
  name: 'cryptoGameSlice',
  initialState: defaultCryptoGameState,
  reducers: {
    setShowHowItWorksPopup: (state, action) => ({
      ...state,
      showHowItWorksPopup: action.payload
    }),
    setShowRoiCalculatorPopup: (state, action) => ({
      ...state,
      showRoiCalculator: action.payload
    }),
    setShowLimitPopup: (state, action) => ({
      ...state,
      showLimitModal: action.payload
    }),
    setPublicBetRemove: (state, action) => ({
      ...state,
      publicBetTransaction: action.payload
    }),
    setActiveBetRemove: (state, action) => ({
      ...state,
      activePlaceTransaction: action.payload
    })
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCryptoCoins.fulfilled, (state, action) => {
        state.cryptoCoinsList = action?.payload
      })
      .addCase(cryptoBetPlace.fulfilled, (state, action) => {
        return {
          ...state,
          cryptoData: action.payload
        }
      })
      .addCase(cryptoBetPlace.pending, (state, action) => {
        const { password, userNameOrEmail } = action.meta.arg
        return {
          ...state,
          cryptoData: { password, userNameOrEmail }
        }
      })
      .addCase(updateBetDetail.fulfilled, (state, action) => {
        return {
          ...state,
          updateBet: action.payload
        }
      })
      .addCase(getBetPlaceTransaction.pending, (state, action) => {
        return {
          ...state,
          betLoader: true
        }
      })
      .addCase(getBetPlaceTransaction.fulfilled, (state, action) => {
        if (action.payload.name === transactionType.PUBLIC_BETS) {
          return {
            ...state,
            publicBetTransaction: action?.payload?.publicBets,
            betLoader: false
          }
        } else if (action.payload.name === transactionType.ACTIVE_BETS) {
          return {
            ...state,
            activePlaceTransaction: action?.payload?.activeBets,
            betLoader: false
          }
        } else if (action.payload.name === transactionType.CLOSED_BETS) {
          return {
            ...state,
            closedPlaceTransaction: action?.payload?.closedBets,
            betLoader: false
          }
        } else {
          return {
            ...state,
            betPlaceTransaction: action?.payload?.leaderboard,
            betLoader: false
          }
        }
      })
      .addCase(getBetTransactionDetail.fulfilled, (state, action) => {
        return {
          ...state,
          betTransactionDetail: action.payload
        }
      })
  }
})

export const {
  setShowHowItWorksPopup,
  setShowRoiCalculatorPopup,
  setShowLimitPopup,
  setPublicBetRemove,
  setActiveBetRemove
} = cryptoGameSlice.actions

export default cryptoGameSlice.reducer
