import { createAsyncThunk } from '@reduxjs/toolkit'
import { gameCloseService, loginService } from 'network/services/auth.service'
import { setServerSeedHash } from 'redux-store/redux/slices/gameSettings.slice'
import { signIn, signOut } from 'helpers/cookie.helpers'
import { ROUTE_PATHS } from 'constants/index'

import { OperatorPlayerUnAuthenticatedErrorType } from 'utils/errorTypes'
import { delay } from 'lodash'

export const userLogin = createAsyncThunk('user/login', async ({ payload, history }, thunkApi) => {
  try {
    const res = await loginService(payload)
    if (res) {
      thunkApi.dispatch(setServerSeedHash(res?.serverSeedHash))
      signIn({ token: res?.accessToken })
    }
    return res
  } catch (error) {
    console.log("ERROR IN LOGIN", error)
    if (error[0].name === OperatorPlayerUnAuthenticatedErrorType.name) {
      history.push(ROUTE_PATHS.sessionExpired)
    } else {
      history.push(ROUTE_PATHS.homepage)
    }
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const gameClose = createAsyncThunk('user/game-close', async (data, thunkApi) => {
  try {
    const res = await gameCloseService()
    if (res) {
      signOut()
    }
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})
