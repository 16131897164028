import { createAsyncThunk } from '@reduxjs/toolkit'
import { generateServerSeedService, getTransactions, getUserInfoService } from 'network/services/users.service'

export const fetchUserInformation = createAsyncThunk('user/user-detail', async (_, thunkApi) => {
  try {
    const res = await getUserInfoService()
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const generateServerSeed = createAsyncThunk('user/generate-server-seed', async (_, thunkApi) => {
  try {
    const res = await generateServerSeedService()
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const getUserTransactions = createAsyncThunk('user/withdraw', async (params, thunkApi) => {
  let baseUrl
  const { offset, transactionType, limit } = params
  if (transactionType === 'wallet') {
    baseUrl = 'user/wallet'
  } else if (transactionType === 'cashout') {
    baseUrl = 'user/cashout'
  } else if (transactionType === 'deposit') {
    baseUrl = 'user/deposit'
  } else if (transactionType === 'jackpot') {
    baseUrl = 'user/jackpot'
  } else if (transactionType === 'mybets') {
    params = {
      limit,
      offset
    }
    baseUrl = 'common-game/my-bets'
  } else if (transactionType === 'topbets') {
    params = {
      limit,
      offset
    }
    baseUrl = 'common-game/top-bets'
  }
  try {
    const res = await getTransactions(params, baseUrl)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})
