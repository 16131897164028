import { LANGUAGE } from 'constants/index'

export const setLocalLanguage = (lang) => {
  localStorage.setItem(LANGUAGE, lang)
}

export const getLocalLanguage = () => {
  return localStorage.getItem(LANGUAGE)
}

export const getItem = (key) => {
  const value = localStorage.getItem(key)
  return value ? JSON.parse(value) : null
}

export const setItem = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value))
}
