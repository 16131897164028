export const DEFAULT_PLINKO_PINS = 8

export const DEFAULT_MIN_PLINKO_ROWS = 8
export const DEFAULT_MAX_PLINKO_ROWS = 16

export const DEFAULT_PLINKO_RISK_LEVEL = 1

export const DEFAULT_PLINKO_AUTO_BETS = '∞'

export const DEFAULT_NUMBER_OF_BALLS = 5

export const PLINKO_FIXED_ODDS = {
  8: [[5.6, 2.1, 1.1, 1, 0.5, 1, 1.1, 2.1, 5.6], [13, 3, 1.3, 0.7, 0.4, 0.7, 1.3, 3, 13], [29, 4, 1.5, 0.3, 0.2, 0.3, 1.5, 4, 29]],
  9: [[5.6, 2, 1.6, 1, 0.7, 0.7, 1, 1.6, 2, 5.6], [18, 4, 1.7, 0.9, 0.5, 0.5, 0.9, 1.7, 4, 18], [43, 7, 2, 0.6, 0.2, 0.2, 0.6, 2, 7, 43]],
  10: [[8.9, 3, 1.4, 1.1, 1, 0.5, 1, 1.1, 1.4, 3, 8.9], [22, 5, 2, 1.4, 0.6, 0.4, 0.6, 1.4, 2, 5, 22], [76, 10, 3, 0.9, 0.3, 0.2, 0.3, 0.9, 3, 10, 76]],
  11: [[8.4, 3, 1.9, 1.3, 1, 0.7, 0.7, 1, 1.3, 1.9, 3, 8.4], [24, 6, 3, 1.8, 0.7, 0.5, 0.5, 0.7, 1.8, 3, 6, 24], [120, 14, 5.2, 1.4, 0.4, 0.2, 0.2, 0.4, 1.4, 5.2, 14, 120]],
  12: [[10, 3, 1.6, 1.4, 1.1, 1, 0.5, 1, 1.1, 1.4, 1.6, 3, 10], [33, 11, 4, 2, 1.1, 0.6, 0.3, 0.6, 1.1, 2, 4, 11, 33], [170, 24, 8.1, 2, 0.7, 0.2, 0.2, 0.2, 0.7, 2, 8.1, 24, 170]],
  13: [[8.1, 4, 3, 1.9, 1.2, 0.9, 0.7, 0.7, 0.9, 1.2, 1.9, 3, 4, 8.1], [43, 13, 6, 3, 1.3, 0.7, 0.4, 0.4, 0.7, 1.3, 3, 6, 13, 43], [260, 37, 11, 4, 1, 0.2, 0.2, 0.2, 0.2, 1, 4, 11, 37, 260]],
  14: [[7.1, 4, 1.9, 1.4, 1.3, 1.1, 1, 0.5, 1, 1.1, 1.3, 1.4, 1.9, 4, 7.1], [58, 15, 7, 4, 1.9, 1, 0.5, 0.2, 0.5, 1, 1.9, 4, 7, 15, 58], [420, 56, 18, 5, 1.9, 0.3, 0.2, 0.2, 0.2, 0.3, 1.9, 5, 18, 56, 420]],
  15: [[15, 8, 3, 2, 1.5, 1.1, 1, 0.7, 0.7, 1, 1.1, 1.5, 2, 3, 8, 15], [88, 18, 11, 5, 3, 1.3, 0.5, 0.3, 0.3, 0.5, 1.3, 3, 5, 11, 18, 88], [620, 83, 27, 8, 3, 0.5, 0.2, 0.2, 0.2, 0.2, 0.5, 3, 8, 27, 83, 620]],
  16: [[16, 9, 2, 1.4, 1.4, 1.2, 1.1, 1, 0.5, 1, 1.1, 1.2, 1.4, 1.4, 2, 9, 16], [110, 41, 10, 5, 3, 1.5, 1, 0.5, 0.3, 0.5, 1, 1.5, 3, 5, 10, 41, 110], [1000, 130, 26, 9, 4, 2, 0.2, 0.2, 0.2, 0.2, 0.2, 2, 4, 9, 26, 130, 1000]]
}

export const DEFAULT_CHANCE_ROWS = {
  8: [0.3906, 3.125, 10.9375, 21.875, 27.3437, 21.875, 10.9375, 3.125, 0.3906],
  9: [0.1953, 1.7578, 7.0312, 16.4062, 24.6093, 24.6093, 16.4062, 7.0312, 1.7578, 0.1953],
  10: [0.0976, 0.9765, 4.3945, 11.7187, 20.5078, 24.6093, 20.5078, 11.7187, 4.3945, 0.9765, 0.0976],
  11: [0.0488, 0.5371, 2.6855, 8.0566, 16.1132, 22.5585, 22.5585, 16.1132, 8.0566, 2.6855, 0.5371, 0.0488],
  12: [0.0244, 0.2929, 1.6113, 5.371, 12.0849, 19.3359, 22.5585, 19.3359, 12.0849, 5.371, 1.6113, 0.2929, 0.0244],
  13: [0.0122, 0.1586, 0.9521, 3.4912, 8.728, 15.7104, 20.9472, 20.9472, 15.7104, 8.728, 3.4912, 0.9521, 0.1586, 0.0122],
  14: [0.0061, 0.0854, 0.5554, 2.2216, 6.1096, 12.2192, 18.3288, 20.9472, 18.3288, 12.2192, 6.1096, 2.2216, 0.5554, 0.0854, 0.0061],
  15: [0.003, 0.0457, 0.3204, 1.3885, 4.1656, 9.1644, 15.274, 19.638, 19.638, 15.274, 9.1644, 4.1656, 1.3885, 0.3204, 0.0457, 0.003],
  16: [0.0015, 0.0244, 0.1831, 0.8544, 2.777, 6.665, 12.2192, 17.456, 19.638, 17.456, 12.2192, 6.665, 2.777, 0.8544, 0.1831, 0.0244, 0.0015]
}

export const DEFAULT_PLINKO_LIGHTNING_MODE_BOARD = {
  betMultipliers: [{ position: [7, 8], multiplier: '2x' }, { position: [13, 10], multiplier: '15x' }, { position: [2, 2], multiplier: '40x' }],
  payouts: [1000, 155, 6.28, 1.09, 0.35, 0.16, 0, 0.02, 0, 0.01, 0, 0.07, 0.3, 1.4, 29.3, 157, 1000]
}

export const PLINKO_LIGHTNING_MODE_VARIABLE_ODDS = {
  1: [500, 125, 30, 5, 0.9, 0.3, 0, 0.1, 0, 0.1, 0, 0.3, 0.9, 5, 30, 125, 500],
  2: [1000, 155, 15, 3.59, 1.4, 0.69, 0, 0.07, 0, 0.04, 0, 0.17, 0.25, 0.46, 1.27, 8.43, 1000],
  3: [1000, 155, 6.28, 1.09, 0.35, 0.16, 0, 0.02, 0, 0.01, 0, 0.07, 0.3, 1.4, 29.3, 157, 1000],
  4: [1000, 6.39, 0.96, 0.32, 0.16, 0.09, 0, 0.02, 0, 0.03, 0, 0.61, 2.03, 7.42, 28.7, 152, 1000]
}

export const WALLET_EMIT_TYPES = {
  CREDIT: 'CREDIT',
  DEBIT: 'DEBIT',
  RESOLVE: 'RESOLVE',
  DEFAULT: 'DEFAULT',
  BET: 'BET',
  WIN: 'WIN'
}

export const DEFAULT_PAGE_CALLS = 20
