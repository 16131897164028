import * as PIXI from "pixi.js";
import { gameManager } from "./gameManager";
import { basePath, defaultTheme, path } from "./settings";
import config from "config/app.config";
import { THEMES_CONFIG } from "constants/index";

export let assetsLoaded = false;
export let promiseAssets;

export const themes = {
  _currentTheme: defaultTheme,

  get currentTheme() {
    return this._currentTheme;
  },

  set currentTheme(theme) {
    if(THEMES_CONFIG.find(t => t.slug == theme)){
      this._currentTheme = theme;
    } else{
      this._currentTheme = defaultTheme;
      console.error(`Invalid theme: ${theme}. Loading default theme.`);
    }

    // if (theme === THEMES.TGC || theme === THEMES.LB) {
    //   this._currentTheme = theme;
    // } else {
    //   this._currentTheme = defaultTheme;
    //   console.error(`Invalid theme: ${theme}. Loading default theme.`);
    // }
  }
};


export function startLoadingAssets(themeSlug) {
  // const values = Object.values(THEMES);
  // themes.currentTheme = values[themeSlug];

  themes.currentTheme = themeSlug;

  assetsLoaded = false;

  loadAssets()
    .then(function () {
      onAssetsLoaded();
    })
    .catch(function (e) {
      console.error(e);
    });
}

async function loadAssets() {
  const themePath = `${basePath}themes/${themes.currentTheme}/`

  PIXI.Assets.addBundle("Fonts", {
    completeAssets: themePath + "crash-assets.json",
    rocket: themePath + "rocket.json",
    intermed: path.fontPath + "Inter-Medium.ttf",
    interbold: path.fontPath + "Inter-Bold.ttf",
    intersemibold: path.fontPath + "Inter-SemiBold.ttf",
    mulishreg: path.fontPath + "Mulish-Regular.ttf",
    interRegular: path.fontPath + "Inter-Regular.ttf",
    stars: themePath + "bg.png",
    usd: path.imagePath + 'USD.png',
    eur: path.imagePath + 'EUR.png',

  });

  promiseAssets = await PIXI.Assets.loadBundle("Fonts");
}

function onAssetsLoaded() {
  assetsLoaded = true;
  // console.log("assetsLoaded");

  gameManager();
}
