import { ReactComponent as AnimationIcon } from './animation.svg'
import { ReactComponent as CrossIcon } from './cross-icon.svg'
import { ReactComponent as MusicIcon } from './music.svg'
import { ReactComponent as RefreshIcon } from './refresh-icon.svg'
import { ReactComponent as RightArrowIcon } from './right-arrow.svg'
import { ReactComponent as DownArrowIcon } from './down-arrow.svg'
import { ReactComponent as SoundIcon } from './sound.svg'
import { ReactComponent as TogglerIcon } from './toggler-icon.svg'
import { ReactComponent as DollarCurrencyIcon } from './dollar-currency.svg'
import { ReactComponent as InfinityIcon } from './ph_infinity.svg'
import { ReactComponent as InfinityBoldIcon } from './infinity_bold.svg'
import { ReactComponent as StandIcon } from './stand-icon.svg'
import { ReactComponent as InfoIcon } from './info.svg'
import { ReactComponent as VolumeIcon } from './volume.svg'
import { ReactComponent as UpDownArrowIcon } from './up-down-arrow.svg'
import { ReactComponent as SwapArrowIcon } from './arrow-swap-horizontal.svg'
import { ReactComponent as KeyboardIcon } from './keyboard.svg'
import { ReactComponent as RocketIcon } from './rocket.svg'
import { ReactComponent as DiceStandIcon } from './stats.svg'
import { ReactComponent as VolumeMuteIcon } from './volume-mute.svg'
import { ReactComponent as VolumeUnMuteIcon } from './volume-unmute.svg'
import { ReactComponent as StateRefreshIcon } from './icon-refresh.svg'
import { ReactComponent as WarningIcon } from './icon-warning.svg'
import { ReactComponent as XCloseIcon } from './icon-x-close.svg'
import { ReactComponent as PrivateIcon } from './private-icon.svg'
import { ReactComponent as GrowUpArrowIcon } from './grow-up-arrow.svg'
import { ReactComponent as GreenCircleIcon } from './green-circle.svg'
import { ReactComponent as ArrowFilledLeft } from './arrow-filled-left.svg'
import { ReactComponent as ArrowFilledRight } from './arrow-filled-right.svg'
import { ReactComponent as ChartIcon } from './chart.svg'
import { ReactComponent as HiloUpIcon } from './hilo-up-arrow.svg'
import { ReactComponent as HiloDownIcon } from './hilo-down-arrow.svg'
import { ReactComponent as HiloSameIcon } from './hilo-same-icons.svg'
import { ReactComponent as HiloSkipIcon } from './hilo-skip-arrow.svg'
import { ReactComponent as HiloUpFillIcon } from './hilo-up-fill-arrow.svg'
import { ReactComponent as HiloDownFillIcon } from './hilo-down-fill-arrow.svg'

// icons export
export {
  AnimationIcon,
  CrossIcon,
  MusicIcon,
  RefreshIcon,
  RightArrowIcon,
  SoundIcon,
  TogglerIcon,
  DollarCurrencyIcon,
  InfinityIcon,
  InfinityBoldIcon,
  DownArrowIcon,
  StandIcon,
  InfoIcon,
  VolumeIcon,
  UpDownArrowIcon,
  SwapArrowIcon,
  KeyboardIcon,
  RocketIcon,
  DiceStandIcon,
  VolumeMuteIcon,
  VolumeUnMuteIcon,
  StateRefreshIcon,
  WarningIcon,
  XCloseIcon,
  PrivateIcon,
  GrowUpArrowIcon,
  GreenCircleIcon,
  ArrowFilledLeft,
  ArrowFilledRight,
  ChartIcon,
  HiloUpIcon,
  HiloDownIcon,
  HiloSameIcon,
  HiloSkipIcon,
  HiloUpFillIcon,
  HiloDownFillIcon
}
