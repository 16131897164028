import { METHOD_TYPES } from 'constants/index'
import axiosInstanceService from 'network/apis/index'
import { microServices } from 'network/apis/microservice/index'

export const getDiceGameBetHistory = (params) => {
  return axiosInstanceService(METHOD_TYPES.get, 'game/fast-game/dice/get-bets', {}, {
    server: microServices.SERVICE_URL_1,
    params
  })
}

export const diceGamePlaceBet = ({ data, isDemoGame }) => {
  return axiosInstanceService(METHOD_TYPES.post, 'game/fast-game/dice/place-bet', data, {
    server: microServices.SERVICE_URL_1,
    headers: { 'demo-game': isDemoGame }
  })
}

export const diceGameDemoPlaceBet = ({ data }) => {
  return axiosInstanceService(METHOD_TYPES.post, 'game/fast-game/dice/demo-place-bet', data, {
    server: microServices.SERVICE_URL_1
  })
}
