import { createSlice } from '@reduxjs/toolkit'
import { cashOutHiLo, getUnfinishedBet, getMyBetsHiLo, openCardHiLo, placeBetHiLo, demoPlaceBetHiLo, openDemoCardHiLo, demoCashOutHiLo } from 'games/HiLoGame/slice-thunk/hiLoGame.thunk'
import { BET_RESULT, DEFAULT_PAGE_CALLS, HI_LO_CARD_DECK } from 'constants/index'
import { randomCardGenerate } from 'games/HiLoGame/game-helper/index'
import { plus, minus } from 'number-precision'

const initialState = {
  initialCard: randomCardGenerate(),
  betStates: null,
  betData: null,
  betLock: false,
  disablePlaceBet: false,
  betHistory: {
    count: 0,
    rows: []
  },
  previousSelectedMultiplier: null,
  stateLoaded: false,
  betHistoryData: null,
  betResult: null,
  disableCheckout: null,
  disableOpenCardButton: false,
  profitHigher: 0.00,
  profitHigherMultiplier: 0.00,
  profitLower: 0.00,
  profitLowerMultiplier: 0.00,
  totalProfit: 0.00,
  myInitialCard: null,
  currentOpenedCard: null,
  disableCardFlipping: false,
  liveStates: {
    wins: 0,
    losses: 0,
    profit: 0,
    wagered: 0
  },
  demoSessionId: null,
}

const {
  actions: {
    setInitialCard,
    setMyInitialCard,
    setStateLoaded,
    setBetHistoryData,
    setCalculatedProfits,
    resetCalculatedProfits,
    resetHiloGameResult,
    setInitialBetStates,
    resetLiveStates,
    setMainCard
  },
  reducer
} = createSlice({
  name: 'hiloGame',
  initialState,
  reducers: {
    setInitialCard: (state, action) => {
      return {
        ...state,
        initialCard: action.payload,
        betResult: null,
        betStates: [],
        currentOpenedCard: action.payload
      }
    },
    setInitialBetStates: (state, action) => {
      return {
        ...state,
        betStates: [{
          openedCard: action.payload,
          id: '001',
          coefficient: 'Start Card'
        }]
      }
    },
    resetHiloGameResult: (state, action) => {
      return { ...state, betData: null }
    },
    setBetLock: (state, action) => {
      return {
        ...state,
        betLock: action.payload
      }
    },
    setMyInitialCard: (state, action) => {
      return {
        ...state,
        myInitialCard: action.payload,
        betStates: [{
          openedCard: action.payload,
          id: '001',
          coefficient: 'Start Card'
        }],
        currentOpenedCard: action.payload,
        disableCheckout: true
      }
    },
    setMainCard: (state, action) => {
      return {
        ...state,
        currentOpenedCard: action.payload
      }
    },
    setBetHistoryData: (state, action) => ({
      ...state,
      betHistoryData: action.payload
    }),
    setStateLoaded: (state, action) => {
      return {
        ...state,
        stateLoaded: action.payload
      }
    },

    resetCalculatedProfits: (state, action) => {
      const { currentCardIndex } = action.payload
      return {
        ...state,
        profitHigherMultiplier: 0.00,
        profitLowerMultiplier: 0.00,
        profitHigher: 0.00,
        profitLower: 0.00,
        totalProfit: 0.00,
        currentOpenedCard: currentCardIndex
      }
    },
    setCalculatedProfits: (state, action) => {
      const { currentCardIndex, betAmount, coefficient } = action.payload
      const totalCards = 52
      const totalRanks = 13
      const cardSuitsCount = 4

      // Get the rank of the current card
      const currentCard = HI_LO_CARD_DECK[currentCardIndex]

      const currentCardValue = currentCard[0]

      // Calculate the number of higher or same and lower or same cards
      const higherOrSameCards = totalRanks - currentCardValue + 1
      const lowerOrSameCards = currentCardValue

      // Calculate probabilities
      const probabilityHigherOrSame = (higherOrSameCards * cardSuitsCount) / totalCards
      const probabilityLowerOrSame = (lowerOrSameCards * cardSuitsCount) / totalCards

      // Assign multipliers (example: inverse of probability)
      const multiplierHigherOrSame = 1 / probabilityHigherOrSame
      const multiplierLowerOrSame = 1 / probabilityLowerOrSame

      // Calculate profits
      const profitHigherOrSame = betAmount * multiplierHigherOrSame
      const profitLowerOrSame = betAmount * multiplierLowerOrSame
      const totalProfit = betAmount * coefficient - betAmount

      // Return results
      const profits = {
        profitHigherMultiplier: multiplierHigherOrSame.toFixed(2),
        profitLowerMultiplier: multiplierLowerOrSame.toFixed(2),
        profitHigher: profitHigherOrSame.toFixed(2),
        profitLower: profitLowerOrSame.toFixed(2),
        totalProfit: totalProfit.toFixed(2)
      }

      return {
        ...state,
        ...profits,
        currentOpenedCard: currentCardIndex
      }
    },
    resetLiveStates: (state, action) => {
      return {
        ...state,
        liveStates: {
          wins: 0,
          losses: 0,
          profit: 0,
          wagered: 0
        }
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(placeBetHiLo.fulfilled, (state, action) => {
        // TODO
        let newBetStates = []
        // 1, Check if betState is filled with previous cards
        const previousBetState = JSON.parse(JSON.stringify(state.betStates))

        // 2. If yes then keep the last card & remove all other cards
        const lastOpenedCard = previousBetState.at(-1)
        lastOpenedCard.coefficient = 'Start Card'

        // 3. Update the new betState
        if (lastOpenedCard) {
          newBetStates = [lastOpenedCard]
        }

        const newLiveStats = { ...state.liveStates }
        newLiveStats.wagered = plus(newLiveStats.wagered, action.payload?.betAmount ?? 0)

        return {
          ...state,
          betLock: true,
          disablePlaceBet: false,
          betData: action.payload,
          betStates: newBetStates,
          betResult: null,
          profitHigher: 0.00,
          profitHigherMultiplier: 0.00,
          profitLower: 0.00,
          profitLowerMultiplier: 0.00,
          totalProfit: 0.00,
          disableCheckout: true,
          liveStates: newLiveStats
        }
      })
      .addCase(placeBetHiLo.pending, (state, action) => {
        return {
          ...state,
          disablePlaceBet: true,
        }
      })
      .addCase(placeBetHiLo.rejected, (state, action) => {
        return {
          ...state,
          disablePlaceBet: false,
        }
      })
      .addCase(demoPlaceBetHiLo.fulfilled, (state, action) => {
        // TODO
        let newBetStates = []
        // 1, Check if betState is filled with previous cards
        const previousBetState = JSON.parse(JSON.stringify(state.betStates))

        // 2. If yes then keep the last card & remove all other cards
        const lastOpenedCard = previousBetState.at(-1)
        lastOpenedCard.coefficient = 'Start Card'

        // 3. Update the new betState
        if (lastOpenedCard) {
          newBetStates = [lastOpenedCard]
        }

        const newLiveStats = { ...state.liveStates }
        newLiveStats.wagered = plus(newLiveStats.wagered, action.payload?.betAmount ?? 0)

        return {
          ...state,
          betLock: true,
          disablePlaceBet: false,
          betData: action.payload,
          betStates: newBetStates,
          betResult: null,
          profitHigher: 0.00,
          profitHigherMultiplier: 0.00,
          profitLower: 0.00,
          profitLowerMultiplier: 0.00,
          totalProfit: 0.00,
          disableCheckout: true,
          liveStates: newLiveStats,
          demoSessionId: action.payload.demoHiLo
        }
      })
      .addCase(demoPlaceBetHiLo.pending, (state, action) => {
        return {
          ...state,
          disablePlaceBet: true,
        }
      })
      .addCase(demoPlaceBetHiLo.rejected, (state, action) => {
        return {
          ...state,
          disablePlaceBet: false,
        }
      })
      .addCase(openCardHiLo.pending, (state, action) => {
        return {
          ...state,
          disableOpenCardButton: true,
          disableCardFlipping: true
        }
      })
      .addCase(openCardHiLo.rejected, (state, action) => {
        return {
          ...state,
          disableOpenCardButton: false,
          disableCardFlipping: false
        }
      })
      .addCase(openCardHiLo.fulfilled, (state, action) => {
        let betStates = null
        let initialCard = null
        let betResult = null
        let betLock = state.betLock
        let betData = { ...state.betData }
        let newBetStates
        let disableCheckout
        // const myInitialCard = action?.payload?.initialCard
        const normalizedBetState = JSON.parse(JSON.stringify(state.betStates))
        const initialCardBetState = normalizedBetState[0]

        if (action.payload?.betStates && action.payload.betStates.length > 0) {
          betStates = (action.payload.betStates)
          initialCard = betStates?.[betStates.length - 1]?.openedCard
          newBetStates = [initialCardBetState, ...betStates]
          disableCheckout = false
        }

        let betHistory = state.betHistory
        const newLiveStats = { ...state.liveStates }

        if (action.payload?.result && action.payload.result === BET_RESULT.LOST) {
          betLock = false
          betResult = BET_RESULT.LOST
          newBetStates = [initialCardBetState, ...betStates]
          disableCheckout = false
          betData.result = betResult

          // Assign last opened card as initial Card for new Game
          initialCard = newBetStates.at(-1).openedCard
          betHistory = state.betHistory.rows ? { count: state.betHistory.count + 1, rows: [action.payload, ...state.betHistory.rows] } : { count: 1, rows: [action.payload] }
          if (betHistory.rows?.length > DEFAULT_PAGE_CALLS) {
            betHistory?.rows?.pop()
          }

          newLiveStats.profit = minus(newLiveStats.profit, action.payload?.betAmount ?? 0)
          newLiveStats.losses += 1
        }

        return {
          ...state,
          betLock,
          betData,
          initialCard,
          betStates: newBetStates,
          myInitialCard: initialCard,
          betHistory,
          betResult,
          disableCheckout,
          disableOpenCardButton: false,
          liveStates: newLiveStats,
          disableCardFlipping: false
        }
      })
      .addCase(openDemoCardHiLo.pending, (state, action) => {
        return {
          ...state,
          disableOpenCardButton: true,
          disableCardFlipping: true
        }
      })
      .addCase(openDemoCardHiLo.rejected, (state, action) => {
        return {
          ...state,
          disableOpenCardButton: false,
          disableCardFlipping: false
        }
      })
      .addCase(openDemoCardHiLo.fulfilled, (state, action) => {
        let betStates = null
        let initialCard = null
        let betResult = null
        let betLock = state.betLock
        let newBetStates
        let disableCheckout
        let betData = { ...state.betData }
        // const myInitialCard = action?.payload?.initialCard
        const normalizedBetState = JSON.parse(JSON.stringify(state.betStates))
        const initialCardBetState = normalizedBetState[0]

        if (action.payload?.betStates && action.payload.betStates.length > 0) {
          betStates = (action.payload.betStates)
          initialCard = betStates?.[betStates.length - 1]?.openedCard
          newBetStates = [initialCardBetState, ...betStates]
          disableCheckout = false
        }

        let betHistory = state.betHistory
        const newLiveStats = { ...state.liveStates }

        if (action.payload?.result && action.payload.result === BET_RESULT.LOST) {
          betLock = false
          betResult = BET_RESULT.LOST
          newBetStates = [initialCardBetState, ...betStates]
          disableCheckout = false
          betData.result = BET_RESULT.LOST
          // Assign last opened card as initial Card for new Game
          initialCard = newBetStates.at(-1).openedCard
          betHistory = state.betHistory.rows ? { count: state.betHistory.count + 1, rows: [action.payload, ...state.betHistory.rows] } : { count: 1, rows: [action.payload] }
          if (betHistory.rows?.length > DEFAULT_PAGE_CALLS) {
            betHistory?.rows?.pop()
          }

          newLiveStats.profit = minus(newLiveStats.profit, action.payload?.betAmount ?? 0)
          newLiveStats.losses += 1
        }

        return {
          ...state,
          betLock,
          betData,
          initialCard,
          betStates: newBetStates,
          myInitialCard: initialCard,
          betHistory,
          betResult,
          disableCheckout,
          disableOpenCardButton: false,
          liveStates: newLiveStats,
          disableCardFlipping: false
        }
      })
      .addCase(getUnfinishedBet.pending, (state, action) => {
        return {
          ...state
        }
      })
      .addCase(getUnfinishedBet.fulfilled, (state, action) => {
        if (action.payload?.hasUnfinishedBet) {
          const { unfinishedBet } = action.payload
          let betStates = null
          let initialCard = null
          let currentOpenedCard = null
          const betLock = true
          const betAmount = unfinishedBet?.betAmount
          const myInitialCard = unfinishedBet?.initialCard
          if (unfinishedBet?.betStates && unfinishedBet.betStates.length > 0) {
            betStates = (unfinishedBet.betStates)
            initialCard = betStates?.[betStates.length - 1]?.openedCard
            currentOpenedCard = initialCard
          } else if (unfinishedBet?.betStates && unfinishedBet.betStates.length === 0) {
            initialCard = unfinishedBet?.initialCard
            currentOpenedCard = initialCard
          }

          let newBetStates
          const startingCard = {
            openedCard: unfinishedBet?.initialCard,
            id: '001',
            coefficient: 'Start Card'
          }
          if (betStates) {
            newBetStates = [startingCard, ...betStates]
          } else {
            newBetStates = null
          }

          return {
            ...state,
            betLock,
            initialCard,
            betStates: newBetStates,
            disableCheckout: newBetStates?.length === 1,
            betAmount,
            myInitialCard,
            stateLoaded: true,
            currentOpenedCard
          }
        }
        return {
          ...state,
          stateLoaded: true
        }
      })
      .addCase(getUnfinishedBet.rejected, (state, action) => {
        return {
          ...state,
          stateLoaded: true
        }
      })
      .addCase(cashOutHiLo.fulfilled, (state, action) => {
        // TODO: If user cash out, then keep the last card as start card for the next game.
        // 1. separate the last card
        // 2. assign it inside the betStates array as an object,

        const lastOpenedCardProxy = state.betStates[state.betStates.length - 1]

        const lastOpenedCard = JSON.parse(JSON.stringify(lastOpenedCardProxy))
        const lastOpenedCardIndex = lastOpenedCard.openedCard

        const betHistory = state.betHistory.rows ? { count: state.betHistory.count + 1, rows: [action.payload, ...state.betHistory.rows] } : { count: 1, rows: [action.payload] }

        const newLiveStats = { ...state.liveStates }
        newLiveStats.profit = plus(newLiveStats.profit, minus(action.payload?.winningAmount ?? 0, action.payload?.betAmount ?? 0))
        newLiveStats.wins += 1

        return {
          ...state,
          betLock: false,
          betData: action.payload,
          initialState: lastOpenedCardIndex,
          currentOpenedCard: lastOpenedCardIndex,
          betAmount: '',
          betHistory,
          betResult: BET_RESULT.WON,
          liveStates: newLiveStats
        }
      })
      .addCase(demoCashOutHiLo.fulfilled, (state, action) => {
        const lastOpenedCardProxy = state.betStates[state.betStates.length - 1]
        const lastOpenedCard = JSON.parse(JSON.stringify(lastOpenedCardProxy))

        const betDataProxy = state.betData
        const betData = JSON.parse(JSON.stringify(betDataProxy))

        // const newBetData = { ...betData, result: BET_RESULT.WON, winningAmount: betData.betAmount * lastOpenedCard.coefficient }
        const lastOpenedCardIndex = lastOpenedCard.openedCard

        const betHistory = state.betHistory.rows ? { count: state.betHistory.count + 1, rows: [action.payload, ...state.betHistory.rows] } : { count: 1, rows: [action.payload] }

        const newLiveStats = { ...state.liveStates }
        newLiveStats.profit = plus(newLiveStats.profit, minus(action.payload?.winningAmount ?? 0, action.payload?.betAmount ?? 0))
        newLiveStats.wins += 1

        return {
          ...state,
          betLock: false,
          betData: action.payload,
          initialState: lastOpenedCardIndex,
          currentOpenedCard: lastOpenedCardIndex,
          betAmount: '',
          betHistory,
          betResult: BET_RESULT.WON,
          liveStates: newLiveStats
        }
      })
      .addCase(getMyBetsHiLo.fulfilled, (state, action) => {
        return {
          ...state,
          betHistory: action.payload
        }
      })
  }
})

export {
  setInitialCard,
  setMyInitialCard,
  setStateLoaded,
  setBetHistoryData,
  setCalculatedProfits,
  resetCalculatedProfits,
  resetHiloGameResult,
  setInitialBetStates,
  resetLiveStates,
  setMainCard
}

export default reducer
